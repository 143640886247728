import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/info/Home";
import Account from "./pages/Account";
import Market from "./pages/Market";

import store from "./api/store"; 

//import NotImplemented from "./pages/NotImplemented";
import PoliticaDePrivacidadScreen from "./pages/legal/PoliticaDePrivacidadScreen";
import TerminosYCondicionesScreen from "./pages/legal/TerminosYCondicionesScreen";
import ProteccionDeDatosScreen from "./pages/legal/ProteccionDeDatosScreen";
import CookiesScreen from "./pages/legal/CookiesScreen";
import KiriCoinAndCryptoScreen from "./pages/info/KiriCoinAndCryptoScreen";
import InfoComercialesScreen from "./pages/info/InfoComercialesScreen";
import PreguntasRespuestasScreen from "./pages/info/PreguntasRespuestasScreen";
import WorkWithUsScreen from "./pages/info/WorkWithUsScreen";
import CalculadoraInversionScreen from "./pages/calculadora/CalculadoraInversionScreen";
import CalculadorasScreen from "./pages/calculadora/CalculadorasScreen";

import LoginScreen from "./pages/user/LoginScreen";
import RegisterScreen from "./pages/user/RegisterScreen";
import ConfirmSignupScreen from "./pages/user/ConfirmSignupScreen";

import AdvisorDashboardScreen from "./pages/advisor/AdvisorDashboardScreen";

import DashboardScreen from "./pages/user/DashboardScreen";
import ContratarScreen from "./pages/inversor/ContratarScreen";
import ContratoDetalleScreen from "./pages/inversor/ContratoDetalleScreen";

//import history from "./util/history";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { setI18nConfig } from "./nclab-react-core";

import * as Actions from "./api/actions";

function App() {
  const [loading, setLoading] = React.useState(true);

  const [language, setLanguage] = React.useState(
    localStorage.getItem('language') || 'es'
  );

  React.useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const [cookiesAccepted, setCookiesAccepted] = React.useState(
    localStorage.getItem('cookiesAccepted') || 'false'
  );

  React.useEffect(() => {
    localStorage.setItem('cookiesAccepted', cookiesAccepted);
  }, [cookiesAccepted]);

  const [principalData, setPrincipalData] = React.useState(
    Actions.getPrincipalData() || {}
  );

  React.useEffect(() => {
    //localStorage.setItem('cookiesAccepted', cookiesAccepted);
    console.log("setting the principal data", principalData);
    //Actions.saveDataToStorage(principalData);
  }, [principalData]);

  React.useEffect(() => {
    if (cookiesAccepted !== 'true') {
      window.$('#cookiesModalDialog').modal({});
    }
  });

  /*
  const onChangeLanguage = lng => {
    setI18nConfig(lng);
    setLanguage(lng);
  }*/

  const getAppTanslationMap = {
    // lazy requires (metro bundler does not support symlinks)
    es: () => require("./translations/es.json"), 
    en: () => require("./translations/en.json") 
  };

  const onChangePrincipalData = principalData => {
    setPrincipalData(principalData);
  }

  if (loading) {
    console.log("Using language", language);
    setI18nConfig(language, getAppTanslationMap);
    setLanguage(language);
    setLoading(false);
    setPrincipalData(Actions.restoreDataFromStorage());

    console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL);
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);
    console.log("process.env", process.env);

    if (window.$ != null) {
      window.$('#cookiesModalDialog').on('hidden.bs.modal', function (e) {
        setCookiesAccepted('true');
      });
    }
  }

  /*
  const onClickVerPoliticaDeCookies = (history) => {
    history.push('/home/cookies');
    window.$('#cookiesModalDialog').modal('hide');
  }

  const onClickContinuar = () => {
    window.$('#cookiesModalDialog').modal('hide');
    setCookiesAccepted('true');
  }*/

  return (
    <Fragment>
      <Provider store={store}>
        <Router basename="/web" /*history={history}*/>
          <Header /*history={history}*/ principalData={principalData} onChangePrincipalData={onChangePrincipalData}/>
          <Routes>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/home/privacy-policy" component={PoliticaDePrivacidadScreen} />
            <Route exact path="/home/terms-and-conditions" component={TerminosYCondicionesScreen} />
            <Route exact path="/home/data-privacy" component={ProteccionDeDatosScreen} />
            <Route exact path="/home/cookies" component={CookiesScreen} />
            <Route exact path="/home/info/kiricoin-and-finance" component={KiriCoinAndCryptoScreen} />
            <Route path="/home/calculadoras/:id" component={CalculadoraInversionScreen} />
            <Route exact path="/home/calculadoras" component={CalculadorasScreen} />
            
            <Route exact path="/home/info/question-and-answers" component={PreguntasRespuestasScreen} />
            <Route exact path="/home/info/work-with-us" component={WorkWithUsScreen} />
            <Route exact path="/home/info/comerciales" component={InfoComercialesScreen} />

            <Route exact path="/user/login" render={(props) => <LoginScreen {...props} onChangePrincipalData={onChangePrincipalData} />} />
            <Route exact path="/user/sign-up" render={(props) => <RegisterScreen {...props} title="Registro de inversor" />} />
            <Route exact path="/user/sign-up-comercial" render={(props) => <RegisterScreen {...props} title="Registro de comercial" />} />
            <Route exact path="/user/sign-up-minero" render={(props) => <RegisterScreen {...props} title="Registro de minero" />} />
            <Route exact path="/user/sign-up-propietario" render={(props) => <RegisterScreen {...props} title="Registro de propietario" />} />
            <Route exact path="/user/confirm-sign-up" render={(props) => <ConfirmSignupScreen {...props} />} />

            <Route exact path="/dashboard" component={DashboardScreen} />
            <Route exact path="/inversor/contratar" render={(props) => <ContratarScreen {...props} />} />
            <Route path="/inversor/contratos/:id" render={(props) => <ContratoDetalleScreen {...props} />} />

            <Route exact path="/market" component={Market} />
            <Route exact path="/account" component={Account} />

            <Route exact path="/advisor/dashboard" component={AdvisorDashboardScreen} />

            <Route exact path="/authorization" component={Market} />
          </Routes>
          
          <Footer />
        </Router>
      </Provider>
    </Fragment>
  );
}

export default App;
