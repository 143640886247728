import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, change } from 'redux-form';
import * as Actions from "../../api/actions";
import store, {isLocalDevelopment} from "../../api/store"; 
import { _t, ErrorManagement, AfeFields, LOADSTATE, SUBMITSTATE } from "../../nclab-react-core";

class LoginScreen extends Component {
  state = {
    loadState: LOADSTATE.LOADING,
    submitState: SUBMITSTATE.NONE,
    isSubmiting: false,
    isLoading: false,
    sendOk: false,
    demoUsers: [
      {id: 1, username: "admin@abigblue.org", password: "secret"},
      {id: 2, username: "inversor1@abigblue.org", password: "secret"},
      {id: 3, username: "inversor2@abigblue.org", password: "secret"},
      {id: 4, username: "inversor3@abigblue.org", password: "secret"},
      {id: 5, username: "advisor1@abigblue.org", password: "secret"},
      {id: 6, username: "advisor2@abigblue.org", password: "secret"},
      {id: 7, username: "advisor3@abigblue.org", password: "secret"},
      {id: 8, username: "supplier1@abigblue.org", password: "secret"},
      {id: 9, username: "supplier2@abigblue.org", password: "secret"},
      {id: 10, username: "supplier3@abigblue.org", password: "secret"},
      {id: 12, username: "team.manager.bcn@abigblue.org", password: "123456"},
      {id: 13, username: "group.manager.bcn@abigblue.org", password: "123456"},
      {id: 14, username: "company.manager@abigblue.org", password: "123456"},
    ]
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChangeTestUser = (value) => {
    const foundElement = this.state.demoUsers.filter( x => x.username === value);
    if (foundElement.length > 0) {
      const selection = foundElement[0];
      console.log("Se selecciono:", selection);
      this.props.dispatch(change('LoginScreen', 'email', selection.username));
      this.props.dispatch(change('LoginScreen', 'password', selection.password));
    }
  }

  onSubmit = async (formValues) => {
    try {
      this.setState({ isSubmiting: true });

      await store.dispatch(Actions.login(formValues.email, formValues.password));
      
      this.setState({ isSubmiting: false });

      this.props.onChangePrincipalData(Actions.getPrincipalData());

      this.props.history.push('/dashboard');

    } catch (error) {     
      this.setState({ isSubmiting: false });

      throw new SubmissionError(ErrorManagement.getAllErrors(error));
    }
  }

  render() {

    const { handleSubmit, error } = this.props;

    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="h3 card-title">Iniciar sesión en Kiri Coin</div>
                  {error && <div className="alert alert-danger" role="alert">{error}</div>}
                  <form onSubmit={handleSubmit(this.onSubmit)}>
                   
                    <div className="row">
                      <div className="col-md-12">
                        {isLocalDevelopment() && 
                        <Field name="testUser" type="text" component={AfeFields.Select} label="Seleccione un usuario de pruebas"
                            onChange={ ($) =>  this.onChangeTestUser($.target.value)}>
                            <option value=""></option>
                            {this.state.demoUsers != null && this.state.demoUsers.map(
                            data => <option key={data.id} value={data.username}>{data.username}</option>)}
                        </Field>}
                        <Field label="Correo" name="email" type="text" 
                          component={AfeFields.Input} placeholder="escriba el correo electrónico con que se registró" />
                        <Field label="Clave" name="password" type="password"
                          component={AfeFields.Input} placeholder="escriba su clave" />
                      </div>
                    </div>

                    <div className="row pt-2">
                      <div className="col-md-12">
                        <div className="col-md-12 mb-2">
                          <button type="submit" className="btn btn-primary font-default-normal" 
                            disabled={this.state.isSubmiting}>Entrar</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values, props) => {
  //console.log("Properties", props);

  const errors = {}
  if (!values.password) {
    errors.password = _t("errors.required");
  } 
  if (!values.email) {
    errors.email = _t("errors.required");
  }
  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: 'LoginScreen', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(LoginScreen);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {}, 
    enableReinitialize: true
  };
};

export default connect(
  mapStateToProps,
  {
    
  }
)(form);
