import * as ActionType from '../actions/sales';

export const salesCompanyGroups = (state = [], action) => {
    if (action.type == ActionType.GET_SALES_COMPANY_GROUPS)
        return action.data;
    else
        return state;
};

export const salesGroupTeams = (state = [], action) => {
    if (action.type == ActionType.GET_SALES_GROUP_TEAMS)
        return action.data;
    else
        return state;
};

export const salesTeamAgents = (state = [], action) => {
    if (action.type == ActionType.GET_SALES_TEAM_AGENTS)
        return action.data;
    else
        return state;
};

export const salesPendingAssignments = (state, action) => {
    if (action.type == ActionType.GET_SALES_PENDING_ASSIGNATION_SEARCH)
        return action.data;
    else
        return !state ? [] : state;
};

export const salesClients = (state, action) => {
    if (action.type == ActionType.GET_SALES_CLIENT_SEARCH)
        return action.data;
    else
        return !state ? [] : state;
};

export const salesClientDetails = (state, action) => {
    if (action.type == ActionType.GET_SALES_CLIENT_DETAILS)
        return action.data;
    else
        return !state ? {} : state;
};
