import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm, getFormValues, stopAsyncValidation} from 'redux-form';
import { Link } from "react-router-dom";
import * as Actions from "../../api/actions";
import store from "../../api/store"; 
import { _t, AfeFormatter, ErrorManagement, LOADSTATE, SUBMITSTATE } from "../../nclab-react-core";
import classNames from "classnames";

class MenuGroupButtons extends Component {

  render() {

    const { options, value, onClick } = this.props;

    return (
      <div className="btn-group mb-2" role="group">
        {options.map(data => 
          <button key={data.id} type="button" className={classNames(
            "btn",
            {
              "btn-secondary": data.id !== value,
              "btn-success": data.id === value
            })} onClick={ () => onClick(data.id, value) } >{data.label}</button>
        )}
      </div>
    );
  }

};

class ContratoDetalleScreen extends Component {
  state = {
    loadState: LOADSTATE.LOADING,
    submitState: SUBMITSTATE.NONE,
    contractState: 'NEW'
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.onLoad();
  }

  onLoad = async() => {
    try {
      this.setState({ loadState: LOADSTATE.LOADING });

      await store.dispatch(Actions.postUserContractsSearch(null, 0, 100, null, this.state.contractState));
   
      this.setState({ loadState: LOADSTATE.LOADED_OK });
    } catch (error) {
      this.setState({ loadState: LOADSTATE.LOADED_KO });
      store.dispatch(stopAsyncValidation(ContratoDetalleScreen.name, { _error: ErrorManagement.getMessage(error) }));
    }
  }

  onSubmit = (formValues) => {
    //store.dispatch(Actions.login(formValues.email, formValues.password));
  }

  toNumber = (value) => {
    if (value === null || value.length === 0)
      return null;
    return parseFloat(value);
  }

  fijaCapitalInicial = false;
  fijaAportacionMensual = false;

  onStateFilterClick = async (id, value) => {
    this.setState({contractState: id}, () => {
      this.onLoad();
    });
  }

  render() {

    const { userContracts } = this.props;

    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="h3 card-title">Tablero del inversor</div>

                  {userContracts == null &&
                  <div className="h114">No ha contratado ningun producto</div>}

                  {userContracts != null &&
                  <Fragment>
                    <MenuGroupButtons options={[
                      {id:"NEW", label:"Nuevos"}, 
                      {id:"ACTIVE", label:"Activos"}, 
                      {id:"ARCHIVED", label:"Archivados"}, 
                      {id:"DELETED", label:"Eliminados"}, 
                      {id:"", label:"Todos"}]} 
                      value={this.state.contractState}
                      onClick = {this.onStateFilterClick} />

                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th align="center" scope="col">Acciones</th>
                          <th align="center" scope="col">Estado</th>
                          <th align="right" scope="col">#</th>
                          <th align="left" scope="col">Producto</th>
                          <th align="right" scope="col">Cantidad inicial</th>
                          <th align="right" scope="col">Recurrente</th>
                          <th align="center" scope="col">Inicio de contrato</th>
                          <th align="center" scope="col">Fin de contrato</th>
                          <th align="center" scope="col">Periodicidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userContracts != null && userContracts.map(data => 
                        <tr key={data.id}>
                          <td align="center">
                            <Link to={"/inversor/contratos/" + data.id}>Detalles</Link>
                          </td>
                          <td align="center">
                            {_t("user.contractState." + data.userContractState)}
                          </td>
                          <td align="right">{data.id}</td>
                          <td align="left">{data.productKey}</td>
                          <td align="right">{AfeFormatter.moneyToStr(data.initialInvestment, 2)}</td>
                          <td align="right">{AfeFormatter.moneyToStr(data.recurringAmmount, 2)}</td>
                          <td align="center">{AfeFormatter.dateToStr(data.submissionDate)}</td>
                          <td align="center">{AfeFormatter.dateToStr(data.expiringDate)}</td>
                          <td align="center">{AfeFormatter.periodToStr(data.contractPeriodType)}</td>
                        </tr>
                        )}
                        
                      </tbody>
                    </table>
                  </Fragment>
                  }

                  <div className="h4 pt-2">
                    <Link to="/home/calculadoras" className="btn btn-primary">Consultar nuestros productos</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.edadActual) {
    errors.edadActual = 'Requerido';
  }
  if (!values.edadJubilacion) {
    errors.edadActual = 'Requerido';
  }
  
  /*
  if (recaptchaValue != null && recaptchaValue != "") {
    //console.log(formValues);
  } else {
    errors.recaptcha = 'Make sure you are not a robot';
  }*/
  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: ContratoDetalleScreen.name, // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(ContratoDetalleScreen);

const mapStateToProps = (state, ownProps) => {
  //console.log("InversorDashboardScreen", state.jobDetail);
  
  const initialValues = { 
    edadActual: 40, 
    edadJubilacion: 67, 
    numAnyos: 67-40,
    anyoJubilacion: 2047,
    aportacionMensual: 50, 
    capitalInicial: 0,
    bancoInteres: 3,
    fondoInversionInteres: 6,
    kcInteres: 10,
    disponibleAlFinal: 0,
  };
  
  return {
    initialValues: initialValues, 
    enableReinitialize: true,
    userContracts: state.userContracts,
    formValues: getFormValues(ContratoDetalleScreen.name)(state)
  };
};

export default connect(mapStateToProps, {})(form);

