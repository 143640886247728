import { PRODUCT_GET_PRODUCTS, PRODUCT_GET_PRODUCT_DETAIL } from '../actions/product';

export const productList = (state = [], action) => {
  switch (action.type) {
    case PRODUCT_GET_PRODUCTS: 
      return action.data;
    default:
      return state;
  }
};

export const productDetail = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_GET_PRODUCT_DETAIL: 
      return action.data;
    default:
      return state;
  }
};

