import axios from "axios";

const backendApi = axios.create({
    baseURL: global.window === undefined || global.window.location.hostname.endsWith('thenclab.com') || global.window.location.hostname.endsWith('localhost')? 
        "http://localhost:1002/kiricoin-backend-ms" : 
        "https://www.abigblue.org/kiricoin-backend-ms",
    headers: {
        "company-id": "abigblue",
        "Accept-Language": "es"
    }
});

export default backendApi;