import React, { Component, Fragment } from "react";
import SiteSection from "../../components/SiteSection";
import { Link } from "react-router-dom";

class WorkWithUsScreen extends Component {
  state = {};
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <Fragment>

        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pb-1">
                <div className="h2 text-success">Trabaja con nosotros</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pb-1">
                <p>
                  Estamos contratando en diferentes áreas
                </p>
              </div>
            </div>
          </div>
        </div>

        <SiteSection
          title="Comerciales"
          image={process.env.PUBLIC_URL + "/assets/calculadoras/calculadora-12.jpg"}
          layout="text-left"
        >
          ¿Tienes experiencia como comercial?<br/>
          ¿Has trabajado de cara al publico?<br/>
          ¿Has trabajado en el sector bancario o de seguros?<br/>
          <br/>
          <Link className="btn btn-primary" to="/user/sign-up-comercial">
            Registrate como comercial
          </Link>
        </SiteSection>

        <SiteSection
          title="Mineros"
          image={process.env.PUBLIC_URL + "/assets/calculadoras/calculadora-13.jpg"}
          layout="text-right"
        >
          ¿Eres ingeniero agrónomo?<br/>
          ¿Eres ingeniero forestal?<br/>
          ¿Eres veterinario?<br/>
          ¿Estas estudiando algo relacionado con la agronomía o la veterinaria?<br/>
          <br/>
          <Link className="btn btn-primary" to="/user/sign-up-minero">
            Registrate como minero
          </Link>
        </SiteSection>

        <SiteSection
          title="Propietarios"
          image={process.env.PUBLIC_URL + "/assets/calculadoras/calculadora-14.jpg"}
          layout="text-left"
        >
          ¿Te apasiona la vida en el campo?<br/>
          ¿Ya tienes tierras a las que quieres sacar rendimiento?<br/>
          <br/>
          <Link className="btn btn-primary" to="/user/sign-up-propietario">
            Registrate como propietario
          </Link>
        </SiteSection>

      </Fragment>
    );
  }
}

export default WorkWithUsScreen;
