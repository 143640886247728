/* Core */
import { configureStore } from '@reduxjs/toolkit'
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux'

/* Instruments */
import reducers from "./reducers"
import { middleware } from './middleware'

export const reduxStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(middleware)
  },
})
export const useDispatch = () => useReduxDispatch()
export const useSelector  = useReduxSelector

export default reduxStore;

export const isLocalDevelopment = () => {
  return global.window === undefined || global.window.location.hostname.endsWith('thenclab.com') || global.window.location.hostname.endsWith('localhost');
};

export const isTestEnvironment = () => {
  return typeof jest !== 'undefined';
};