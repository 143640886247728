import * as ActionType from '../actions/customer';

export const customerContracts = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_CONTRACT_SEARCH)
        return action.data;
    else
        return state;
};

export const customerPayments = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_PAYMENT_SEARCH)
        return action.data;
    else
        return state;
};

export const customerIncomes = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_INCOME_SEARCH)
        return action.data;
    else
        return state;
};

export const customerDocuments = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_DOCUMENT_SEARCH)
        return action.data;
    else
        return state;
};

export const customerContractDetails = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_CONTRACT_DETAILS)
        return action.data;
    else
        return state;
};

export const customerDetails = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_DETAILS)
        return action.data;
    else
        return state;
};

export const customerAgentAssignment = (state = [], action) => {
    if (action.type == ActionType.GET_CUSTOMER_AGENT_ASSIGNMENT)
        return action.data;
    else
        return state;
};
