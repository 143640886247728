import AuhtorizationApi from '../authorization-api';
import BackendApi from '../backend-api';
import * as StaticResponses from '../responses';

export const AUTHENTICATE = 'AUTHENTICATE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const LOGOUT = 'LOGOUT';
export const REGISTER_INVERSOR = 'REGISTER_INVERSOR';
export const REGISTER_ABIGBLUE_INVERSOR = 'REGISTER_ABIGBLUE_INVERSOR';
export const PRODUCTO_INVERSION = 'PRODUCTO_INVERSION';
export const USER_PAYMENT_PREFERENCES = 'USER_PAYMENT_PREFERENCES';
export const GET_USER_CONTRACTS = 'GET_USER_CONTRACTS';
export const GET_USER_DASHBOARD = 'GET_USER_DASHBOARD';
export const GET_USER_SEARCH = 'GET_USER_SEARCH';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';

export const USERDATA = 'USERDATA';

let timer;

let principalData = {};

export const getPrincipalData = () => { 
  return principalData;
}

export const autoLogin = (userData) => {
  return async dispatch => {

    console.log("Using token:", userData);

    BackendApi.defaults.headers["Authorization"] = 'Bearer ' + userData.token;

    //to get Last Login date with token auto-login
    const identityResponse = await BackendApi.get("/api/users/identity", {
      params: {
        //platformOS: Platform.OS,
        //platformVersion: Platform.Version,
        //clientVersion: AppConstants.expo.version
      }
    });

    principalData.identity = identityResponse.data;

    dispatch({ type: AUTHENTICATE, payload: principalData });
    
    userData = localStorage.getItem(USERDATA);

    if (userData !== null && userData !== undefined) { 
      saveDataToStorage(principalData);
      console.log("Saving local user OK");
    }

    return principalData;
  };
};

export const login = (email, password, keepmeLogedIn) => {
  return async dispatch => {
    let formBody = [];

    formBody.push(encodeURIComponent('client_id') + "=" + encodeURIComponent('MOBILE'));
    formBody.push(encodeURIComponent('client_secret') + "=" + encodeURIComponent('81a3d05e-3251-40ac-855f-c5f73491d94e'));
    formBody.push(encodeURIComponent('username') + "=" + encodeURIComponent(email));
    formBody.push(encodeURIComponent('password') + "=" + encodeURIComponent(password));
    formBody.push(encodeURIComponent('grant_type') + "=" + encodeURIComponent('password'));

    formBody = formBody.join("&");

    let authorizationResponse = await AuhtorizationApi.post('/oauth/token', formBody);

    BackendApi.defaults.headers["Authorization"] = 'Bearer ' + authorizationResponse.data.access_token;

    console.log("Authorization OK");

    const expirationDate = new Date(
      new Date().getTime() + parseInt(authorizationResponse.data.expires_in) * 1000
    );

    let identityResponse = await BackendApi.get("/api/users/identity", {
      params: {
        //platformOS: Platform.OS,
        //platformVersion: Platform.Version,
        //clientVersion: AppConstants.expo.version
      }
    });

    console.log("Identity info", identityResponse.data);

    let payload = {
      userId: identityResponse.data.id,
      token: authorizationResponse.data.access_token,
      identity: identityResponse.data,
      roles: identityResponse.data.roles,
      expiryTime: parseInt(authorizationResponse.data.expires_in) * 1000,
      expiryDate: expirationDate
    };

    dispatch({ type: AUTHENTICATE, payload: payload });
    principalData = payload;

    if (keepmeLogedIn) {
      saveDataToStorage(payload);
      console.log("Saving local user OK");
    }

    return payload;
  };
};

export const registerUser = (user) => {
  return async (dispatch) => {
    await BackendApi.post("/api/users/register", user);
    return dispatch(login(user.email, user.password));
  };
};

export const registerInversor = (formData) => {
  return async (dispatch) => {
    await BackendApi.post("/api/users/register-inversor", formData);
    return dispatch({ type: REGISTER_INVERSOR, payload: formData });
  };
};

export const registerABigBlueMember = (formData) => {
  return async (dispatch) => {
    await BackendApi.post("/api/users/register-big-blue", formData);
    return dispatch({ type: REGISTER_ABIGBLUE_INVERSOR, payload: formData });
  };
};

export const recoverPassword = (user) => {
  return async (dispatch) => {
    return await BackendApi.post("/api/users/forgot-password", user);
  };
};

export const updateUserProfile = (user) => {
  return async (dispatch) => {
    return await BackendApi.post("/api/users/profile", user);
  };
};

export const changeMyPassword = (user) => {
  return async (dispatch) => {
    return await BackendApi.post("/api/users/change-my-password", user);
  };
};

export const confirmSignUp = (userId, role, token) => {
  return async (dispatch) => {
    return await BackendApi.post("/api/users/confirm-sign-up/" + userId + "/" + role + "/" + token, {});
  };
};

export const getUserProfile = () => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/users/profile");
    dispatch({ type: GET_USER_PROFILE, payload: response.data });
    return response;
  };
};

export const sendContactForm = (contactForm) => {
  return async (dispatch) => {
    return await BackendApi.post("/api/users/contactForm", contactForm);
  };
};

export const logout = () => {
  clearLogoutTimer();
  //AsyncStorage.removeItem('userData');
  
  localStorage.removeItem(USERDATA);
  principalData = {};

  return { type: LOGOUT };
};

const clearLogoutTimer = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

/*
const setLogoutTimer = expirationTime => {
  return dispatch => {
    timer = setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};*/

export const restoreDataFromStorage = () => {
  let userData = localStorage.getItem(USERDATA);

  if (userData !== null && userData !== undefined) {
    console.log("Restoring from local data");
    principalData = JSON.parse(userData);
    //console.log("Incializando", principalData);

    BackendApi.defaults.headers["Authorization"] = 'Bearer ' + principalData.token;

    return principalData; 
  }

  return {};
};

export const saveDataToStorage = (userData) => {
  localStorage.setItem(USERDATA, JSON.stringify(userData));

  /*if (AsyncStorage != null) {
    await AsyncStorage.setItem(
      'userData',
      JSON.stringify(userData)
    );
  }*/
};

export const getBackendInfo = () => {
  return async (dispatch) => {
    let response = await BackendApi.get("/actuator/info", null, {
    });
    return response.data;
  };
};

export const seleccionaProductoInversion = (data) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTO_INVERSION, data: data });
    return data;
  };
};

export const getUserPaymentPreferences = () => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/users/payment-preferences", null, {
    });
    dispatch({ type: USER_PAYMENT_PREFERENCES, data: response.data });
    return response.data;
  };
};

export const contratar = (userId, data) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + userId + "/contracts", {
      productType: data.productType,
      productKey: data.productKey,
      productId: data.productId,
      recurringAmount: data.aportacionMensual,
      initialAmount: data.capitalInicial,
      submissionDate: data.fechaInicio,
      expiringDate: data.fechaFin, 
      paymentPreferenceId: data.formaDePago
    }, {
    });
    return response.data;
  };
};

export const postUserContractsSearch = (userId, page, size, sort, contractState) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/users/" + userId + "/contracts/search", {
      filters: null
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null,
        contractState: contractState
      }
    });
    return dispatch({ type: GET_USER_CONTRACTS, data: response.data, page: page, size: size });
  };
};

export const getDashboard = (userId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/dashboard/" + userId, {
      params: null
    });
    return dispatch({ type: GET_USER_DASHBOARD, data: response.data });
  };
};

export const postUserSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/users/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_USER_SEARCH, data: response.data });
  };
};

export const archive = (id) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/users/archive/" + id);
    return response.data;
  };
}

export const recover = (id) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/users/recover/" + id);
    return response.data;
  };
}

export const initUserDetail = (user) => {
  return async (dispatch) => {
    return dispatch({ type: GET_USER_DETAIL, data: user });
  };
};

export const getUserDetail = (userId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/users/" + userId);
    return dispatch({ type: GET_USER_DETAIL, data: response.data });
  };
};

export const postUserDetail = (user, file) => {
  return async (dispatch) => {
    const formData = new FormData(); 
     
    // Update the formData object 
    /*formData.append( 
      "myFile", 
      this.state.selectedFile, 
      this.state.selectedFile.name 
    ); */

    /*for ( var key in user ) {
      formData.append(key, user[key]);
    }*/
    formData.append("form", new Blob([JSON.stringify(user)], {
      type: "application/json"
    }));
    formData.append("file", file);
  
    await BackendApi.post("/api/users/update", formData);
    return dispatch({ type: "NOP" });
  };
};

export const postCustomerSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    /*let response = await BackendApi.post("/api/users/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_USER_SEARCH, data: response.data });*/
    return dispatch({ type: GET_USER_SEARCH, data: StaticResponses.customersList });
  };
};

export const postProjectSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    /*let response = await BackendApi.post("/api/users/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });*/
    return dispatch({ type: GET_USER_SEARCH, data: StaticResponses.projectsList });
  };
};

export const postTeamSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    /*let response = await BackendApi.post("/api/users/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });*/
    return dispatch({ type: GET_USER_SEARCH, data: StaticResponses.teamsList });
  };
};

export const postGroupSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    /*let response = await BackendApi.post("/api/users/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });*/
    return dispatch({ type: GET_USER_SEARCH, data: StaticResponses.customersList });
  };
};

export const postNotificationsSearch = (userId, filters, page, size, sort) => {

  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + userId + "/notifications/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    /*if (!principalData)
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: [] });
    if (principalData.roles && principalData.roles.includes(AppConstants.ROLE_ASOCIADO))
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: StaticResponses.investorNotificationsList });
    if (principalData.roles && principalData.roles.includes(AppConstants.ROLE_ADMIN))
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: StaticResponses.adminNotificationsList });
    if (principalData.roles && principalData.roles.includes(AppConstants.ROLE_AGENT))
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: StaticResponses.advisorNotificationsList });
    if (principalData.roles && principalData.roles.includes(AppConstants.ROLE_INVESTOR))
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: StaticResponses.investorNotificationsList });
    if (principalData.roles && principalData.roles.includes(AppConstants.ROLE_SUPPLIER))
      return dispatch({ type: GET_USER_NOTIFICATIONS, data: [] });*/
    return dispatch({ type: GET_USER_NOTIFICATIONS, data: response.data });   
  };
};


