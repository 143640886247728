import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "../api/actions";

class Header extends Component {
  state = {
    images: [],
    imagenes: [],
    searchText: ''
  };

  componentDidMount() {
  }

  onUpdateText = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onLogout = async (e) => {
    e.preventDefault();
    Actions.logout(); 
    this.props.onChangePrincipalData(Actions.getPrincipalData());
    this.props.history.push('/home');  
  }  

  onSearchSubmit = async (e) => {
    e.preventDefault();
    
    /*const response = await unsplash.get('/search/photos', {
      params: {
        query: this.state.searchText,
        page: 1
      }
    });*/
      
    //this.setState({ images: response.data.results });

  }

  render() {
    const { principalData } = this.props;

    return (
      <Fragment>
        <header className="site-navbar site-navbar-target" role="banner">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              { principalData && principalData.userId != null ?
              <Link to="/dashboard" className="nv-linavbar-brandnk">
                <img className="site-logo" src={process.env.PUBLIC_URL + "/assets/logos/apple-icon-180x180.png"} alt="" />
              </Link> 
              :
              <Link to="/home" className="nv-linavbar-brandnk">
                <img className="site-logo" src={process.env.PUBLIC_URL + "/assets/logos/apple-icon-180x180.png"} alt="" />
              </Link> 
              }
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarColor02"
                aria-controls="navbarColor02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarColor02">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/home" className="nav-link">
                      Inicio
                    </Link>
                  </li>
                  { principalData && principalData.userId != null ?
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                      Tablero
                    </Link>
                  </li> : null
                  }
                  {/* 
                  <li className="nav-item">
                    <Link to="/market" className="nav-link">
                      Mercado
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/account" className="nav-link">
                      Cuenta
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/notifications" className="nav-link">
                        Notificaciones
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/configuration" className="nav-link">
                        Configuración
                    </Link>
                  </li>
                  */}
                </ul>
                { principalData === undefined || principalData.userId == null ?
                <form className="form-inline my-2 my-lg-0" onSubmit={this.onSearchSubmit}>
                  <Link to="/user/login" className="btn btn-primary mr-4">
                    Iniciar sesión
                  </Link>
                  <Link to="/user/sign-up" className="btn btn-primary mr-4">
                    Registrarse
                  </Link>
                </form> : ""}
                { principalData && principalData.userId != null ?
                <form className="form-inline my-2 my-lg-0" onSubmit={this.onSearchSubmit}>
                  <div className="col-md-4">
                    <button
                      className="btn btn-primary mr-4"
                      type="button"
                      onClick={ (e) => this.onLogout(e) }
                    >
                      Salir
                    </button>
                  </div>
                  <div className="col-md-8 color-primary">
                    {principalData.identity ? principalData.identity.username : ''}
                  </div>
                </form> : ""}
              </div>
            </nav>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { /*principalData: state.principalData*/ }
};

export default connect(mapStateToProps, {
  
}) (Header);
