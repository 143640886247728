import React, { Component } from "react";

class TerminosYCondicionesScreen extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const companyNameShort = 'KiriCoin';
    /*const companyNameLong = 'KiriCoin Investments S.L.';
    const companyAddress = 'Barcelona, España, Plaza de Cataluña #1. 08014.';
    const companyAdminEmail = 'admin@kiri-coin.com';
    const companyLOPDEmail = 'lopd@kiri-coin.com';
    const companyRegistro = 'Barcelona, Tomo XX.XXX, Folio XXX, Hoja X-XXXXXX';*/
    const companyWebSite = 'www.kiri-coin.com';

    return (
      <div className="site-section">
        <div className="container col-md-12">
          <div className="col-md-12 h2">
            Términos y Condiciones
          </div>
          <div className="col-md-12">
            <h4>Información Legal</h4>
            <p>
              <strong>{companyNameShort}</strong> se reserva la posibilidad de modificar sin previo aviso, el diseño, la presentación y/o la configuración de esta WEB, así como modificar, eliminar, o añadir servicios.
            </p>
            <p>
              <strong>{companyNameShort}</strong> entiende por <strong>USUARIO</strong> a la persona física, que utilice o quiera invertir en productos y/o servicios financieros, propuestos por <strong>{companyNameShort}</strong> a través de su acceso
              a la web <strong>{companyWebSite}</strong> de la cual <strong>{companyNameShort}</strong> es la propietaria.
            </p>
            <p>
              <strong>{companyNameShort}</strong> entiende por Empresa y/o <strong>CLIENTE</strong>, en adelante <strong>CLIENTE</strong>,
              aquellas entidades que utilicen los servicios de <strong>{companyNameShort}</strong> y
              accedan la WEB de los que es titular <strong>{companyNameShort}</strong>.
              <strong>{companyNameShort}</strong> entiende por <strong>CLIENTE</strong> a cualquier entidad Jurídica, que quiere invertir en productos y/o servicios financieros,
              propuestos por <strong>{companyNameShort}</strong> a través de su acceso a
              la web <strong>{companyWebSite}</strong> de la cual <strong>{companyNameShort}</strong> es la propietaria.
            </p>

            <h4>Objeto</h4>
            <p>
              Las presentes Condiciones Legales, regulan el acceso y el uso por parte del <strong>USUARIO</strong> y/o <strong>CLIENTE</strong>, sobre los servicios que <strong>{companyNameShort}</strong> ofrece
              en su web <strong>{companyWebSite}</strong>. La finalidad de <strong>{companyNameShort}</strong>, es la de proporcionar la máxima información y
              las condiciones sobre los servicios, que ofrece <strong>{companyNameShort}</strong>, a los diferentes <strong>USUARIOS</strong> y/o <strong>CLIENTES</strong>.
            </p>
            <p>
              El uso de la WEB implica al <strong>USUARIO</strong> y/o <strong>CLIENTE</strong> la plena
              aceptación de las disposiciones incluidas en las Condiciones
              Legales en la versión publicada por {companyNameShort} en el momento en que
              el <strong>USUARIO</strong> y/o <strong>CLIENTE</strong> accede a la WEB <strong>{companyWebSite}</strong>.
            </p>
            <p>
              <strong>{companyWebSite}</strong> recomienda leer periódicamente las presentes
              Condiciones.
            </p>
            <p>
              <strong>{companyWebSite}</strong> podrá eliminar de la WEB contenidos sin previo aviso.
            </p>
            <p>
              El <strong>USUARIO</strong> y/o <strong>CLIENTE</strong>, reconoce que humana y técnicamente, no es posible lograr una actualización “IPSO FACTO” de su WEB.
              Sin embargo, <strong>{companyWebSite}</strong> se compromete a mantener actualizada su web <strong>{companyWebSite}</strong>, lo mas rápidamente posible.
              Las causas serán siempre y especialmente, por razones de mantenimiento, seguridad o capacidad, así como, por causas y
              acontecimientos extraordinarios en los que <strong>{companyWebSite}</strong>, no se puede hacer responsable,
              (por ejemplo, anomalías de redes públicas de comunicación, cortes de electricidad, etc.), pueden producirse
              breves anomalías o la suspensión pasajera de los servicios de <strong>{companyWebSite}</strong>.
            </p>
            <h4>Obligaciones del <strong>USUARIO</strong> y/o <strong>CLIENTE</strong></h4>
            <p>
              El <strong>USUARIO</strong> y/o <strong>CLIENTE</strong>, se compromete a hacer un uso diligente de la WEB y de los servicios accesibles desde la misma, con total sujeción a la Ley, básicamente basado en el principio de las buenas costumbres, y según las Condiciones Legales, y/o en su caso, las condiciones particulares.
              </p>
            <p>
            <strong>{companyNameShort}</strong> condiciona la utilización de la mayoría de sus servicios, a la previa aceptación por parte del  <strong>USUARIO</strong> y/o <strong>CLIENTE</strong>, al tratamiento de sus datos por parte de <strong>{companyNameShort}</strong>, y todo conforme a lo establecido en la Ley 15/1999 de Protección de Datos y en el Nuevo Reglamento Europeo de Protección de Datos.
              </p>
            <p>El <strong>USUARIO</strong> y/o <strong>CLIENTE</strong>, deberá aceptar que está informado, y que, de forma libre, específica, e inequívoca, acepta el tratamiento de sus datos personales por parte de <strong>{companyNameShort}</strong>, debiendo para ello aceptar el aviso legal al respecto en cada una de las ofertas de empleo a las que desee inscribirse o enviar su CV. Este aviso se mostrará como paso previo y necesario a la inscripción en cada una de las ofertas.
            </p>
            <p>Toda la información que facilite el <strong>USUARIO</strong> y/o <strong>CLIENTE</strong> a través de la WEB deberá ser veraz y fehaciente. A estos efectos, el <strong>USUARIO</strong> garantiza la autenticidad de todos aquellos datos que comunique como consecuencia de la inscripción a las ofertas de empleo publicadas en la WEB. Así mismo, el <strong>USUARIO</strong> garantiza que es mayor de 18 años.
            </p>
            <p>En todo caso, el <strong>USUARIO</strong> será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a <strong>{companyNameShort}</strong> o a terceros por la información que facilite.
            </p>
            <p>El <strong>USUARIO</strong> y/o <strong>CLIENTE</strong> se obligan a respetar las leyes aplicables y los derechos de terceros al utilizar los contenidos y servicios de la WEB. Así mismo queda prohibida la reproducción, distribución, transmisión, adaptación o modificación, por cualquier medio y en cualquier forma, de los contenidos de la WEB (textos, diseños, gráficos, informaciones, bases de datos, archivos de sonido y/o imagen, logos, etc.) y demás elementos de esta WEB, salvo autorización previa de sus legítimos titulares o cuando así resulte permitido por la ley.
            </p>
            <p>De modo ilustrativo y no limitativo, está prohibido: utilizar contenidos injuriosos o calumniosos, con independencia de que esos contenidos afecten a otros <strong>USUARIOS</strong>, a otras personas o <strong>CLIENTES</strong>, utilizar contenidos pornográficos o que vulneren las leyes de protección de menores, hacer publicidad, ofrecer o distribuir productos pornográficos o que vulneren las leyes de protección de menores, utilizar contenidos protegidos legalmente (p. ej. por la legislación relativa a la propiedad intelectual, a marcas, a patentes, a modelos de utilidad o a modelos estéticos) sin tener derecho a ello, o hacer publicidad, ofrecer o distribuir bienes o servicios protegidos legalmente, así como realizar o fomentar acciones contrarias a la libre competencia.</p>

            <h4>Responsabilidad</h4>

            <p>El <strong>USUARIO</strong> y/o <strong>CLIENTE</strong> de la WEB será responsable de los daños y perjuicios que <strong>{companyNameShort}</strong> pueda sufrir directa o indirectamente, como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas establecidas en los presentes Términos y Condiciones.</p>

            <h4>Límite de Responsabilidad</h4>
            
            <p>En ningún caso <strong>{companyNameShort}</strong> será responsable de algún daño, incluyendo sin límite daños, pérdidas o gastos directos, indirectos inherentes o consecuentes que surjan en relación con la utilización de la WEB, de los enlaces con otras páginas aquí recogidas o por cualquier actuación realizada sobre la base de la información que en ella se facilita.
            </p>
            <p>En todo caso, de ser requerido por orden judicial, <strong>{companyNameShort}</strong> colaborará con las autoridades pertinentes en la identificación de las personas responsables de aquellos contenidos que puedan violar la ley.
            </p>
            <p>De igual forma, <strong>{companyNameShort}</strong> elude toda responsabilidad en los supuestos de fallo en el rendimiento, error, omisión, interrupción, defecto de demora en la operación de transmisión, virus informático, fallo del sistema o línea, así como en el contenido, exactitud y opiniones expresadas y otras conexiones suministradas por estos medios.

            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TerminosYCondicionesScreen;
