import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from 'redux-form';
import { _t, AfeFields } from "../../nclab-react-core";
import * as inversionUtil from "../../util/inversionUtil";
import NodeGroup from "react-move/NodeGroup";
import * as Actions from "../../api/actions";
import store from "../../api/store";
import RangeSlider from 'react-bootstrap-range-slider';

const CustomSlider = ({
  input,
  label,
  placeholder,
  containerStyle = "col-sm",
  type,
  min=0,
  max=100,
  step=1,
  onChange,
  meta: { touched, error, warning }
}) => {
  const classError = (touched && (error || warning)) ? "form-control col-md-12 mb-2 is-invalid" : "form-control col-md-12 mb-2";
  return (
    <div className={containerStyle}>
      {label && <label htmlFor={input.name}>{label}</label>}

      <div className="row">
        <div className="col-md-4">      
          <input {...input} className={classError} placeholder={placeholder} type={type} 
            onChange={changeEvent => { 
              input.onChange(changeEvent.target.value); 
              if (onChange!= null) onChange(changeEvent.target.value); }} />
            {touched &&
              ((error && <div className="text-danger" role="alert">{error}</div>) ||
                (warning && <div className="text-warning" role="alert">{warning}</div>))}
        </div>
  
        <div className="col-md-8">
          <RangeSlider
            value={Number(input.value)} min={min} max={max} tooltip='off' step={step}
            onChange={changeEvent => {
              if (input.onChange !== null)
                input.onChange(changeEvent.target.value); 
              if (onChange!= null) onChange(changeEvent.target.value); }}
          />
        </div>

      </div>  
      
    </div>
  );
};

let index = 1;

let randomNum = () => 20 + Math.floor(80 * Math.random());

function getAppendedData(data) {
  let ret = data.map(d => d);
  ret.push({
    id: "id-" + index,
    value: randomNum(),
    name: "Item " + index
  });
  index++;
  return ret;
}

function getTruncatedData(data) {
  let ret = data.map(d => d).slice(1);
  return ret;
}

let barHeight = 32;
let barPadding = 4;
let widthScale = d => d * 3;

function BarGroup(props) {
  let width = widthScale(props.state.value);
  let yMid = barHeight * 0.5;

  return (
    <g className="bar-group" transform={`translate(0, ${props.state.y})`}>
      <rect
        y={barPadding * 0.5}
        x="20"
        width={width}
        height={barHeight - barPadding}
        style={{ fill: props.data.color, opacity: props.state.opacity }}
      />
      <text
        className="value-label"
        x={30}
        y={yMid}
        alignmentBaseline="middle"
      >
        {props.data.itemLabel}
      </text>
      <text
        className="name-label"
        x="10"
        y={yMid}
        alignmentBaseline="middle"
        style={{ opacity: props.state.opacity }}
      >
        {props.data.name}
      </text>
    </g>
  );
}

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data
    };
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.data !== this.props.data) {
        //this.setState({/*....*/});
        this.handleUpdate();
    }
  }

  handleAdd() {
    this.setState({
      data: getAppendedData(this.state.data)
    });
  }

  handleRemove() {
    this.setState({
      data: getTruncatedData(this.state.data)
    });
  }

  handleUpdate() {
    this.setState({
      data: this.props.data
    });
  }

  startTransition(d, i) {
    return { value: 0, y: i * barHeight, opacity: 0 };
  }

  enterTransition(d) {
    return { value: [d.value], opacity: [1], timing: { duration: 250 } };
  }

  updateTransition(d, i) {
    return { value: [d.value], y: [i * barHeight], timing: { duration: 300 } };
  }

  leaveTransition(d) {
    return { y: [-barHeight], opacity: [0], timing: { duration: 250 } };
  }

  render() {
    return (
      <div>
        <svg width="100%">
          <g className="chart" transform="translate(100,10)">
            <NodeGroup
              data={this.state.data}
              keyAccessor={d => d.name}
              start={this.startTransition}
              enter={this.enterTransition}
              update={this.updateTransition}
              leave={this.leaveTransition}
            >
              {nodes => (
                <g>
                  {nodes.map(({ key, data, state }) => (
                    <BarGroup key={key} data={data} state={state} />
                  ))}
                </g>
              )}
            </NodeGroup>
          </g>
        </svg>
      </div>
    );
  }
}

class CalculadoraInversionScreen extends Component {
  state = {
    resultadoBanca: 0,
    resultadoFondoInversion: 0,
    resultadoKc: 0,
    totalAportado: 0,
    beneficioBrutoKc: 0,
    rentaMensualKc: 0,
    complementoMensualKc: 0,
    capitalInicial: 0,
    esperanzaDeVida: 86,
    data: []
  };



  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getImageStatus = (rentaMensualKc) => {
    const baseUrl = process.env.PUBLIC_URL + "/assets";
    const calcId = this.props.match.params.id;

    if (calcId === 'jubilacion') {
      ;
    } else if (calcId === 'fideicomiso') {
      return baseUrl + "/calculadoras/calculadora-3.jpg";
    } else if (calcId === 'padrinazgo') {
      return baseUrl + "/calculadoras/calculadora-4.jpg";
    } else if (calcId === 'cerocupones') {
      return baseUrl + "/calculadoras/calculadora-6.jpg";
    } else if (calcId === 'emprendedor') {
      return baseUrl + "/calculadoras/calculadora-7.jpg";
    } else if (calcId === 'sostenibilidad') {
      return baseUrl + "/calculadoras/calculadora-5.jpg";
    } else if (calcId === 'personalizado') {
      return baseUrl + "/calculadoras/calculadora-8.jpg";
    }

    if (rentaMensualKc < 1000) 
      return baseUrl + "/images/status-1.jpg";
    if (rentaMensualKc < 2000) 
      return baseUrl + "/images/status-2.jpg";      
    if (rentaMensualKc < 3000) 
      return baseUrl + "/images/status-3.jpg";      
    if (rentaMensualKc < 4000) 
      return baseUrl + "/images/status-4.jpg";      
    if (rentaMensualKc < 5000) 
      return baseUrl + "/images/status-5.jpg";      
    return baseUrl + "/images/status-6.jpg";      
  }

  onSubmit = (formValues) => {
    const esperanzaDeVida = this.state.esperanzaDeVida;
    const periods = (formValues.edadJubilacion - formValues.edadActual);
    const resultadoBanca = inversionUtil.futureValue(formValues.bancoInteres / 100, periods, 1-formValues.aportacionMensual*12, -formValues.capitalInicial, 0);
    const resultadoFondoInversion = inversionUtil.futureValue(formValues.fondoInversionInteres / 100, periods, 1-formValues.aportacionMensual*12, -formValues.capitalInicial, 0);
    const resultadoKc = inversionUtil.futureValue(formValues.kcInteres / 100, periods, 1-formValues.aportacionMensual*12, -formValues.capitalInicial, 0);
    
    const totalAportado = 12*periods*formValues.aportacionMensual + formValues.capitalInicial;
    const priodosPostJubilacion = (esperanzaDeVida - formValues.edadJubilacion);
    const rentaMensualKc = Math.trunc((inversionUtil.futureValue(formValues.kcInteres / 100, periods + 1, 1-formValues.aportacionMensual*12, -formValues.capitalInicial, 0) - resultadoKc)/12);
    const complementoMensualKc = Math.trunc(resultadoKc/priodosPostJubilacion/12);
    const futureMonthlyPayments = Math.trunc(-inversionUtil.futurePeriodPayment(0, formValues.kcInteres / 100, esperanzaDeVida - formValues.edadJubilacion, resultadoKc, 0)/12);

    let data = [
      { id: "id1", color: '#AAA', value: resultadoBanca, name: "Banca" },
      { id: "id2", color: '#AAA', value: resultadoFondoInversion, name: "Fondo de inversión" },
      { id: "id3", color: '#09d3ac', value: resultadoKc, name: "Kiricoin" }
    ];

    const max = resultadoKc;
    let mdata = data.map(d => ({ id: d.id, value: d.value*100/max, name: d.name, 
        itemLabel: inversionUtil.formatMoney(Math.trunc(d.value)), color: d.color }));

    this.props.change('disponibleAlFinal', resultadoKc);

    this.setState( { 
      totalAportado: totalAportado,
      resultadoBanca: Math.trunc(resultadoBanca), 
      resultadoFondoInversion: Math.trunc(resultadoFondoInversion), 
      resultadoKc: Math.trunc(resultadoKc),
      beneficioBrutoKc: Math.trunc(resultadoKc - totalAportado),
      rentaMensualKc: rentaMensualKc,
      complementoMensualKc: complementoMensualKc,
      esperanzaDeVida: esperanzaDeVida,
      rendimientoCuponCero: futureMonthlyPayments,
      data: mdata
    });
  }

  toNumber = (value) => {
    if (value === null || value.length === 0)
      return null;
    return parseFloat(value);
  }

  onChangeEdadActual = (value) => {
    const edadActual = this.toNumber(value);

    const anyoJubilacion = this.props.formValues.edadJubilacion - edadActual + 2020;
    this.props.change('anyoJubilacion', anyoJubilacion);

    const numAnyos = anyoJubilacion - 2020;
    this.props.change('numAnyos', numAnyos);
  }

  onChangeEdadJubilacion = (value) => {
    const anyoJubilacion = this.toNumber(value) - this.props.formValues.edadActual + 2020;
    this.props.change('anyoJubilacion', anyoJubilacion);

    const numAnyos = this.toNumber(value) - this.props.formValues.edadActual;
    this.props.change('numAnyos', numAnyos);
  }

  onChangeAnyoJubilacion = (value) => {
    const edadJubilacion = this.props.formValues.edadActual + this.toNumber(value) - 2020;
    this.props.change('edadJubilacion', edadJubilacion);
    const numAnyos = edadJubilacion - this.props.formValues.edadActual;
    this.props.change('numAnyos', numAnyos);
  }

  onChangeNumAnyos  = (value) => {
    const edadJubilacion = this.props.formValues.edadActual + this.toNumber(value);
    this.props.change('edadJubilacion', edadJubilacion);
    const anyoJubilacion = edadJubilacion - this.props.formValues.edadActual + 2020;
    this.props.change('anyoJubilacion', anyoJubilacion);
  }

  onChangeCapitalInicial  = (value) => {
    const periods = (this.props.formValues.edadJubilacion - this.props.formValues.edadActual);
    const resultadoKc = inversionUtil.futureValue(this.props.formValues.kcInteres / 100.0, periods, 
      1-this.props.formValues.aportacionMensual*12, -this.toNumber(value), 0);

    this.props.change('disponibleAlFinal', resultadoKc);
  }

  onChangeAportacionMensual  = (value) => {
    const periods = (this.props.formValues.edadJubilacion - this.props.formValues.edadActual);
    const resultadoKc = inversionUtil.futureValue(this.props.formValues.kcInteres / 100.0, periods, 1-this.toNumber(value)*12, -0, 0);

    this.props.change('disponibleAlFinal', resultadoKc);
  }

  onChangeDisponibleAlFinal = (value) => {
    const periods = (this.props.formValues.edadJubilacion - this.props.formValues.edadActual);
    
    const aportacionMensual = Math.trunc(-inversionUtil.futurePeriodPayment(this.toNumber(value), this.props.formValues.kcInteres / 100.0, 
      periods, 0, 0)/12.0) + 1;

    this.props.change('aportacionMensual', aportacionMensual);
  }

  onContratar = (e) => {

    let today = new Date();
    let fechaFin = new Date(today);
    fechaFin.setFullYear(today.getFullYear() + this.props.formValues.numAnyos);
    const formValues = Object.assign({ 
      calcId: this.props.match.params.id,
      fechaInicio: today,
      fechaFin: fechaFin
    }, this.props.formValues);

    store.dispatch(Actions.seleccionaProductoInversion(formValues));
    this.props.history.push('/inversor/contratar');
  }

  fijaCapitalInicial = false;
  fijaAportacionMensual = false;

  render() {

    const { handleSubmit, submitting=false, error } = this.props;

    const calcId = this.props.match.params.id;

    let showEdadActual = true;
    let showEdadFinal = true;
    let showAnyoJubilacion = true;
    let showNumAnyos = true;

    //let showCapitalInicial = true;
    let showAportacionMensual = true;
    let showDisponibleAlFinal = true;

    let showKcInteres = false;
    let showFondoInversionInteres = false;

    let titulo = 'Calculadora personalizada';

    let edadActualLabel = "¿Que edad tienes?";
    let edadFinalLabel = "¿A que edad piensas jubilarte?";
    let anyoJubilacionLabel = "¿En que año piensas jubilarte?";
    let numAnyosLabel = "¿Cuantos años faltan para tu jubilación?";

    let capitalInicialLabel = "¿Cual es tu capital inicial?";
    let aportacionMensualLabel = "¿Cuanto dinero puedes aportar mensualmente?";
    let disponibleAlFinalLabel = "¿Cuanto dinero quieres obtener al final?";

    let showRentaMensualRecuperadaHasta85 = true;
    let showRentaMensualVitalicia = true;
    let showRentaMensualHasta85 = true;

    if (calcId === 'jubilacion') {
      titulo = 'Calculadora jubilación digna';
    } else if (calcId === 'fideicomiso') {
      titulo = 'Calculadora estudios/fideicomiso';
      showEdadActual = false;
      showEdadFinal = false;
      anyoJubilacionLabel = "¿En que año comienzas los estudios?";
      numAnyosLabel = "¿Cuantos años faltan para que comiences los estudios?";
      
      showRentaMensualRecuperadaHasta85 = false;
      showRentaMensualVitalicia = false;
      showRentaMensualHasta85 = false;

      this.fijaCapitalInicial = true;
      this.fijaAportacionMensual = false;
    } else if (calcId === 'padrinazgo') {
      titulo = 'Calculadora padrinazgo';

      showEdadActual = false;
      showEdadFinal = false;
      showAportacionMensual = false;
      anyoJubilacionLabel = "¿En que año quieres recibir la rentabilidad?";
      numAnyosLabel = "¿Cuantos años faltan?"; 
      capitalInicialLabel = "¿Cual será tu aportación?";    

      this.fijaCapitalInicial = false;
      this.fijaAportacionMensual = true;

      showRentaMensualRecuperadaHasta85 = false;
      showRentaMensualVitalicia = false;
      showRentaMensualHasta85 = false;
    } else if (calcId === 'cerocupones') {
      titulo = 'Calculadora cero cupones';
    } else if (calcId === 'emprendedor') {
      titulo = 'Calculadora para emprendedor';

      showEdadActual = false;
      showEdadFinal = false;
      anyoJubilacionLabel = "¿En que año quieres comenzar tu proyecto?";
      numAnyosLabel = "¿Cuantos años vas a ahorrar para tu proyecto?";     

      showRentaMensualRecuperadaHasta85 = false;
      showRentaMensualVitalicia = false;
      showRentaMensualHasta85 = false;
    } else if (calcId === 'sostenibilidad') {
      titulo = 'Calculadora sostenibilidad';
    } else if (calcId === 'personalizado') {
      titulo = 'Calculadora personalizada';

      showEdadActual = false;
      showEdadFinal = false;
      showAnyoJubilacion = false;
      numAnyosLabel = "¿Cuantos años vas a ahorrar?";     

      showRentaMensualRecuperadaHasta85 = false;
      showRentaMensualVitalicia = false;
      showRentaMensualHasta85 = false;
    }

    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="h3 card-title">{titulo}</div>
                  {error && <div className="alert alert-danger" role="alert">
                    <ul>{error && error.map(item => { 
                    return <li key={item.field}>{item.field}: {item.message}</li>
                  })}</ul></div>}
                  <form onSubmit={handleSubmit(this.onSubmit)}>
                    
                    <div className="row">
                      <div className="col-md-6">
                        {showEdadActual ? 
                        <Field label={edadActualLabel} name="edadActual" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Edad actual" min={0} max={120}  onChange={ this.onChangeEdadActual } />
                        :null}
                        {showEdadFinal ? 
                        <Field label={edadFinalLabel} name="edadJubilacion" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Edad para jubilarte" min={0} max={120} onChange={ this.onChangeEdadJubilacion } />
                        :null}
                        {showAnyoJubilacion ?
                        <Field label={anyoJubilacionLabel} name="anyoJubilacion" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Año de jubilación" min={2020} max={2055} onChange={ this.onChangeAnyoJubilacion } />
                          :null}
                        {showNumAnyos ?
                        <Field label={numAnyosLabel} name="numAnyos" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Número de años" min={0} max={35} onChange={ this.onChangeNumAnyos }  />
                        :null}
                        <Field label={capitalInicialLabel} name="capitalInicial" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Capital inicial" min={0} max={10000000}  onChange={ this.onChangeCapitalInicial }/>
                        {showAportacionMensual ?
                        <Field label={aportacionMensualLabel} name="aportacionMensual" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Aportación anual" min={0} max={10000} onChange={ this.onChangeAportacionMensual } />
                        :null}
                        {showDisponibleAlFinal ?
                        <Field label={disponibleAlFinalLabel} name="disponibleAlFinal" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Disponible al final" min={0} max={10000000} onChange={ this.onChangeDisponibleAlFinal } />
                        :null}
                      </div>
                      <div className="col-md-6">
                        <Field label="¿Que interés te ofrece tu banco?" name="bancoInteres" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Interés de tu banco"  min={0} max={20} step={0.1}/>
                        {showFondoInversionInteres ?
                        <Field label="¿Que interés te ofrece un fondo de inversión?" name="fondoInversionInteres" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Interés de la bolsa" min={0} max={20} step={0.1} /> 
                        : null}
                        {showKcInteres ?
                        <Field label="¿Que interés te ofrece kiricoin?" name="kcInteres" type="number" parse={value => this.toNumber(value)}
                          component={CustomSlider} placeholder="Interés KiriCoin" min={0} max={20} step={0.1} />
                        : null}  
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <button type="submit" className="btn btn-primary font-default-normal" 
                          disabled={submitting}>Calcular</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            {(this.state.rentaMensualKc > 0) ? <Fragment>
              <div className="row pb-2">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="h3 card-title color-primary">Comparativa general</div>
                      <BarChart data={this.state.data} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                      <div className="card-body">
                        <div className="h3 card-title color-primary">KiriCoin</div>

                        <div className="d-flex flex-row align-items-end divider-1">
                          <div className="mr-auto inversion-label">Total aportado</div>
                          <div className="inversion-value-normal color-normal">{inversionUtil.formatMoney(this.state.totalAportado)}</div>
                        </div>
                        <div className="d-flex flex-row align-items-end divider-1">
                          <div className="mr-auto inversion-label">Beneficio bruto</div>
                          <div className="inversion-value-normal color-normal">{inversionUtil.formatMoney(this.state.beneficioBrutoKc)}</div>
                        </div>
                        <div className="d-flex flex-row align-items-end divider-1">
                          <div className="mr-auto inversion-label">Disponible al final</div>
                          <div className="justify-content-end inversion-value-highlighted color-primary">{inversionUtil.formatMoney(this.state.resultadoKc)}</div>
                        </div>
                        
                        {showRentaMensualRecuperadaHasta85 ?
                        <div className="d-flex flex-row align-items-end divider-1">
                          <div className="mr-auto inversion-label">Renta mensual (con recuperación de capital)</div>
                          <div className="inversion-value-highlighted color-normal color-primary">{inversionUtil.formatMoney(this.state.complementoMensualKc)}</div>
                        </div>
                         : null}
                        {showRentaMensualVitalicia ?
                        <div className="d-flex flex-row align-items-end divider-1">
                          <div className="mr-auto inversion-label">Renta mensual (sin recuperación de capital)</div>
                          <div className="inversion-value-highlighted color-normal color-primary">{inversionUtil.formatMoney(this.state.rentaMensualKc)}</div>
                        </div>
                        : null}
                        {showRentaMensualHasta85 ?
                        <div className="d-flex flex-row align-items-end">
                          <div className="mr-auto inversion-label">Renta mensual hasta agotar el capital</div>
                          <div className="inversion-value-highlighted color-normal color-primary">{inversionUtil.formatMoney(this.state.rendimientoCuponCero)}</div>
                        </div>
                        : null}
                        <div className="d-flex flex-row align-items-end pt-2 divider-1">
                          <img src={this.getImageStatus(this.state.rentaMensualKc)} width="100%" alt="rentabilidad"/>
                        </div>       
                        <div className="d-flex flex-row align-items-end pt-2">
                          <button className="btn btn-primary" onClick={this.onContratar}>Contratar</button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              </Fragment>: null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.edadActual) {
    errors.edadActual = 'Requerido';
  }
  if (!values.edadJubilacion) {
    errors.edadActual = 'Requerido';
  }
  
  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: 'CalculadoraInversionScreen', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(CalculadoraInversionScreen);

const mapStateToProps = (state, ownProps) => {
  //console.log("CalculadoraInversionScreen", state.jobDetail);
  const calcId = ownProps.match.params.id;

  const initialValues = { 
    edadActual: 40, 
    edadJubilacion: 67, 
    numAnyos: 67-40,
    anyoJubilacion: 2047,
    aportacionMensual: 50, 
    capitalInicial: 0,
    bancoInteres: 3,
    fondoInversionInteres: 6,
    kcInteres: 10,
    disponibleAlFinal: 0,
  };
  

  if (calcId === 'padrinazgo') {
    initialValues.capitalInicial = 6000;
    initialValues.aportacionMensual = 0;
  }

  initialValues.disponibleAlFinal = Math.trunc(inversionUtil.futureValue(initialValues.kcInteres / 100, 
    initialValues.numAnyos, 1-initialValues.aportacionMensual*12, -initialValues.capitalInicial, 0));
  
  return {
    initialValues: initialValues, 
    enableReinitialize: true,
    //jobDetail: state.jobDetail,
    formValues: getFormValues('CalculadoraInversionScreen')(state)
  };
};

export default connect(mapStateToProps, {})(form);

