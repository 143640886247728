import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { _t } from "../nclab-react-core";

class Footer extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <footer className="site-footer">
        <div className="container">
          <div className="row pt-3">
            <div className="col-lg-6">
              <h2 className="footer-heading mb-3">Acerca de nosotros</h2>
              <p>
                Somos un equipo de profesionales de varios sectores, varios paises y distintos estamentos económicos, trabajaremos todos juntos para darle la debida seguridad a tu inversión.</p>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 ml-auto">
                  <h2 className="footer-heading mb-4">Quick Links</h2>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/home/terms-and-conditions">{_t("footer.termsAndConditions")}</Link>
                    </li>
                    <li>
                      <Link to="/home/contact-us">Contáctanos</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-12">
              <div className="border-top pt-3">
                <p>
                  Copyright ©
                  <script
                    type="text/javascript"
                    async=""
                    src="https://www.google-analytics.com/analytics.js"
                  ></script>
                  <script type="text/javascript">
                    document.write(new Date().getFullYear());
                  </script>
                  2020 All rights reserved KiriCoin Investments
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      </Fragment>
    );
  }
}

export default Footer;
