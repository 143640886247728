import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class ComercialDashboardScreen extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <div className="site-section first">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div id="main" className="carousel slide" data-ride="carousel">
                  <ul className="carousel-indicators">
                    <li
                      data-target="#main"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li data-target="#main" data-slide-to="1"></li>
                    <li data-target="#main" data-slide-to="2"></li>
                    <li data-target="#main" data-slide-to="3"></li>
                  </ul>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src="/assets/landing.jpg" alt="Los Angeles" className="carousel-image" />
                      <div className="carousel-caption">
                        <div className="h2 text-layer py-2">
                          La forma más segura de hacer crecer tus ahorros.
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img src="/assets/landing-6.png" alt="Una cabra" className="carousel-image" />
                      <div className="carousel-caption">
                        <div className="h2 text-layer py-2">
                          Tangibles y con trazabilidad.
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img src="/assets/landing-1.jpg" alt="Los Angeles1" className="carousel-image" />
                      <div className="carousel-caption">
                        <div className="h2 text-layer py-2">
                          Inversiones aseguradas.
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img src="/assets/landing-7.png" alt="Dos cabras" className="carousel-image" />
                      <div className="carousel-caption">
                        <div className="h2 text-layer py-2">
                          Sostenible y respetuosas con el medio ambiente.
                        </div>
                      </div>
                    </div>
                  </div>

                  <a
                    className="carousel-control-prev"
                    href="#main"
                    data-slide="prev"
                  >
                    <span className="carousel-control-prev-icon"></span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#main"
                    data-slide="next"
                  >
                    <span className="carousel-control-next-icon"></span>
                  </a>
                </div>

                <div className="container pt-3">
                  <div className="d-flex justify-content-center">
                    <Link className="btn btn-primary mr-2" to="/home/info/kiricoin-and-finance">
                      ¿Qué es KiriCoin?
                    </Link>
                    <Link className="btn btn-primary mr-2" to="/home/calculadoras">
                      Planes de ahorro e inversión
                    </Link>
                    <Link className="btn btn-primary mr-2" to="/home/info/question-and-answers">
                      Preguntas frecuentes
                    </Link>                  
                    <Link className="btn btn-primary" to="/home/info/work-with-us">
                      Trabaja con nosotros
                    </Link>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-section">
          <div className="container">
            <div className="col-md-12 pb-1 pl-0">
              <div className="h4">¿Por qué KiriCoin? Aumenta tu capital ayudando al planeta</div>
            </div>

            <div className="row align-items-center">

              <div className="col-md-4 ml-auto mb-2">

                <ul>
                  <li>Rentable</li>
                  <li>Seguro</li>
                  <li>Tangible</li>
                  <li>Descentralizado</li>
                  <li>Estable</li>
                  <li>Con trazabilidad</li>
                  <li>Con liquidez</li>
                  <li>Sustentable</li>
                  <li>Ecológico</li>
                </ul>
                
              </div>

              <div className="col-md-8 mb-1 mb-md-0">
                <img
                  src="/assets/side-image-6.png"
                  alt="side 6"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="site-section">
          <div className="container">
            <div className="col-md-12 pb-1 pl-0">
              <div className="h4">¿Como és KiriCoin respecto a la banca y las criptodivisas?</div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-8 mb-4 mb-md-0">
                <img
                  src="/assets/side-image-2.png"
                  alt="side 2"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-4 ml-auto">

              <form>
                      <fieldset className="form-group">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Estable 100 %
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Garantizada 100 %
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Descentralizada / basada en la blockchain.
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Con una rentabilidad altamente competitiva.
                          </label>
                        </div>
                      </fieldset>
                  </form>

              </div>
            </div>
          </div>
        </div>

        <div className="site-section">
          <div className="container">
            <div className="col-md-12 pb-1 pl-0">
              <div className="h4">Donde es mejor invertir</div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-4 ml-auto">
                <blockquote className="quote-29281">
                  <p>
                    Sabemos que las maneras tradicionales de obtener rentabilidad por tu dinero, pueden ser complejas, de riesgo o improductivas. Compáranos con esas opciones que te están pasando por la cabeza.
                  </p>
                </blockquote>
                  <form>
                      <fieldset className="form-group">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Un banco tradicional
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Un fondo de inversión
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            En casa
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="true"
                              readOnly
                              checked
                            />
                            Otro instrumento financiero
                          </label>
                        </div>
                      </fieldset>
                  </form>
                <p>
                  Toda la información la tienes en nuestra sección de preguntas y respuestas. También puedes ponerte en contacto con un comercial que te atenderá de forma personalizada.
                </p>
                <p>
                  <Link className="btn btn-primary" to="/home/info/question-and-answers">
                    Ver preguntas frecuentes
                  </Link>
                </p>
              </div>
              <div className="col-md-8 mb-1 mb-md-0">
                <img
                  src="/assets/side-image-1.png"
                  alt="side 1"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ComercialDashboardScreen;
