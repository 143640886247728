import { I18n } from "i18n-js";
import memoize from "lodash.memoize";

export const getAppTanslationMap = {
  // lazy requires (metro bundler does not support symlinks)
  es: () => require("../../translations/es.json"), 
  en: () => require("../../translations/en.json"),
  default: () => require("../../translations/es.json"),
};

export const _t = memoize(
  (key, config) => { 
    //console.log("key", key);
    //console.log("config", i18n.locale);
    return  i18n.t(key); // I18n.t(key, config) 
  },
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

const i18n = new I18n();
i18n.locale = "en";
i18n.store({ [i18n.locale]: getAppTanslationMap[i18n.locale]() });

export const setI18nConfig = (locale) => {
  // fallback if no available language fits
  const fallback = { languageTag: locale, isRTL: false };

  locale = "en";

  //console.log("locale", locale)
  //const locales = RNLocalize.getLocales();

  /*I18n.getCurrentLocale();

  console.log("locales", I18n.getCurrentLocale());*/

  const { languageTag } =
    //RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) ||
    fallback;

  //console.log("calculated locale:", fallback);

  // clear translation cache
  _t.cache.clear();
  // update layout direction
  //I18nManager.forceRTL(isRTL);
  // set i18n-js config
  i18n.store({ [locale]: getAppTanslationMap[locale]() });

  //console.log("INSTANCE", i18n);

  i18n.locale = locale;

  //I18n.instance = i18n.t;
  //I18n.translations = { [languageTag]: translationGetters[languageTag]() };
  //I18n.locale = languageTag;

  //console.log("Idioma establecido:", I18n.locale);
};
