import { LOOKUP_GET_COUNTRIES, LOOKUP_GET_USER_TYPES, LOOKUP_GET_PREFERENCES, LOOKUP_GET_CITIES, LOOKUP_GET_COMPANIES } from '../actions/lookup';

export const lookupCountries = (state = [], action) => {
  switch (action.type) {
    case LOOKUP_GET_COUNTRIES: 
      return action.data.map( item => ({ id: item.id, name: item.name }) );
    default:
      return state;
  }
};

export const lookupCities = (state = [], action) => {
  switch (action.type) {
    case LOOKUP_GET_CITIES: 
      return action.data.map( item => ({ id: item.id, name: item.name }) );
    default:
      return state;
  }
};

export const lookupUserTypes = (state = [], action) => {
  switch (action.type) {
    case LOOKUP_GET_USER_TYPES: 
      return action.data.map( item => ({ id: item.id, name: item.name }) );
    default:
      return state;
  }
};

export const lookupPreferences = (state = [], action) => {
  switch (action.type) {
    case LOOKUP_GET_PREFERENCES: 
      return action.data;
    default:
      return state;
  }
};

export const lookupCompanies = (state = [], action) => {
  switch (action.type) {
    case LOOKUP_GET_COMPANIES: 
      return action.data;
    default:
      return state;
  }
};