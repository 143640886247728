import React, { Component, Fragment } from "react";
import SiteSection from "../../components/SiteSection";

class KiriCoinAndCryptoScreen extends Component {
  state = {};
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <Fragment>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pb-1">
                <div className="h2 text-success">KiriCoin y su nacimiento</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pb-1">
                <p>
                  KiriCoin nace de la evaluación de lo que no nos gusta de
                  estas dos opciones
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pb-1">
                <div className="d-flex align-items-stretch">
                  <div className="py-2 pr-2">
                    <div className="h5">
                      Banca tradicional + Moneda fiduciaria
                    </div>
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Sin garantías
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Centralizada
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Perdida de valor ante la inflación
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Pobre rentabilidad
                      </li>
                    </ul>
                  </div>
                  <div className="py-2">
                    <div className="h5">Blockchain + Criptomonedas</div>
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Inestable / volátil
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Sin garantías
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-ban text-danger"></i>
                        </span>
                        Perdida de valor ante la inflación
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SiteSection
          title="KiriCoin es una moneda estable 100%"
          image={process.env.PUBLIC_URL + "/assets/side-image-3.png"}
          layout="text-left"
        >
          En el contexto actual, las criptomonedas de referencia como ‘bitcoin‘
          o ‘ether‘ siguen caracterizadas por una fuerte volatilidad, por lo
          tanto su uso y la inversión en ellas siguen estando relegados a un
          ámbito de elevado riesgo, marcado por la escasez de garantías. En este
          contexto, los instrumentos financieros asociados a ‘blockchain’
          evolucionan constantemente para encontrar soluciones a los retos a los
          que aún se enfrenta la tecnología. <br />
          <br />
          Un ejemplo de los últimos esfuerzos para reducir la volatilidad de las
          monedas virtuales es la creación de las ‘stablecoins’. Este nuevo tipo
          de criptomonedas son ‘tokens’ que están asociados al valor de una
          moneda ‘fiat’ (como el dólar o el euro), a bienes materiales como el
          oro o los inmuebles, o a otra criptomoneda. <br />
          <br />
          También hay ‘stablecoins’ que no están asociadas a ninguna otra moneda
          sino que están controladas mediante algoritmos para mantener un precio
          estable. <br />
          <br />
          La principal motivación para crear una ‘stablecoin’ es tratar de dar
          refugio a los inversores en momentos de volatilidad.
        </SiteSection>

        <SiteSection
          title="KiriCoin es una moneda garantizada 100%"
          image={process.env.PUBLIC_URL + "/assets/side-image-4.png"}
          layout="text-right"
        >
          En el contexto actual, las criptomonedas de referencia como ‘bitcoin‘
          o ‘ether‘ siguen caracterizadas por una fuerte volatilidad, por lo
          tanto su uso y la inversión en ellas siguen estando relegados a un
          ámbito de elevado riesgo, marcado por la escasez de garantías. En este
          contexto, los instrumentos financieros asociados a ‘blockchain’
          evolucionan constantemente para encontrar soluciones a los retos a los
          que aún se enfrenta la tecnología. <br />
          <br />
          Un ejemplo de los últimos esfuerzos para reducir la volatilidad de las
          monedas virtuales es la creación de las ‘stablecoins’. Este nuevo tipo
          de criptomonedas son ‘tokens’ que están asociados al valor de una
          moneda ‘fiat’ (como el dólar o el euro), a bienes materiales como el
          oro o los inmuebles, o a otra criptomoneda. <br />
          <br />
          También hay ‘stablecoins’ que no están asociadas a ninguna otra moneda
          sino que están controladas mediante algoritmos para mantener un precio
          estable. <br />
          <br />
          La principal motivación para crear una ‘stablecoin’ es tratar de dar
          refugio a los inversores en momentos de volatilidad.
        </SiteSection>

        <SiteSection
          title="Descentralizada / basada en la blockchain."
          image={process.env.PUBLIC_URL + "/assets/side-image-5.png"}
          layout="text-right"
        >
          Las criptodivisas permite mantener la resistencia a censura y la
          inmutabilidad de las transacciones, eliminando la necesidad de tener
          que confiar en un ente central. Con la cadena de bloques se distribuye
          la confianza entre muchos entes, posibilitando un dinero
          descentralizado gestionado también de forma descentralizada. Estamos
          orgullosos de poder sentarnos con nuestro cliente o inversor en
          cualquier momento y explicarles, no sólo la evolución del negocio,
          sino también en qué estado se encuentra su inversión, en definitiva,
          se trata de transmitir transparencia.
        </SiteSection>

        <SiteSection
          title="Con una rentabilidad altamente competitiva."
          image={process.env.PUBLIC_URL + "/assets/side-image-6.png"}
          layout="text-left"
        >
          Cualquier inversión espera un determinado retorno. Una relación en la
          que se cuela un tercer factor: el riesgo. Su nivel es directamente
          proporcional al beneficio de la inversión. La premisa es sencilla: a
          más riesgo, más potencial de ganancia pero, ¿hasta qué punto es
          aconsejable asumir riesgos para invertir y obtener la máxima
          rentabilidad?
        </SiteSection>

{/* 
        <SiteSection title="Preguntas y respuestas.">
          <div className="h5">
            ¿Que garantias tengo al estar esta información publica en la cadena de bloques?
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
            tellus sit amet orci finibus vehicula nec sed eros. Proin
            condimentum mauris ac nisi scelerisque tincidunt. Maecenas urna
            erat, venenatis sollicitudin aliquet in, varius eget mauris. Morbi
            id mi ipsum. Morbi tincidunt dapibus neque, eget porttitor mauris
            molestie quis. Aenean eu facilisis elit, ac sodales libero. Nunc non
            leo a ligula convallis tempus at sit amet magna. Curabitur laoreet,
            est scelerisque vehicula ultrices, augue turpis blandit mi, vitae
            rutrum urna enim ut elit.
          </p>
          <div className="h5">
            ¿Tengo que declarar los beneficios obtenidos de las inversiones en criptodivisas?
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
            tellus sit amet orci finibus vehicula nec sed eros. Proin
            condimentum mauris ac nisi scelerisque tincidunt. Maecenas urna
            erat, venenatis sollicitudin aliquet in, varius eget mauris. Morbi
            id mi ipsum. Morbi tincidunt dapibus neque, eget porttitor mauris
            molestie quis. Aenean eu facilisis elit, ac sodales libero. Nunc non
            leo a ligula convallis tempus at sit amet magna. Curabitur laoreet,
            est scelerisque vehicula ultrices, augue turpis blandit mi, vitae
            rutrum urna enim ut elit.
          </p>
          <div className="h5">
            ¿Está amparada mi inversión por alguna legistlación nacional o internacional?
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et
            tellus sit amet orci finibus vehicula nec sed eros. Proin
            condimentum mauris ac nisi scelerisque tincidunt. Maecenas urna
            erat, venenatis sollicitudin aliquet in, varius eget mauris. Morbi
            id mi ipsum. Morbi tincidunt dapibus neque, eget porttitor mauris
            molestie quis. Aenean eu facilisis elit, ac sodales libero. Nunc non
            leo a ligula convallis tempus at sit amet magna. Curabitur laoreet,
            est scelerisque vehicula ultrices, augue turpis blandit mi, vitae
            rutrum urna enim ut elit.
          </p>
        </SiteSection>
        */}
      </Fragment>
    );
  }
}

export default KiriCoinAndCryptoScreen;
