import BackendApi from '../backend-api';

export const LOOKUP_GET_COUNTRIES = 'LOOKUP_GET_COUNTRIES';
export const LOOKUP_GET_USER_TYPES = 'LOOKUP_GET_USER_TYPES';
export const LOOKUP_GET_PREFERENCES = 'LOOKUP_GET_PREFERENCES';
export const LOOKUP_GET_CITIES = 'LOOKUP_GET_CITIES';
export const LOOKUP_GET_COMPANIES = 'LOOKUP_GET_COMPANIES';

export const getCountries = () => {
  return async dispatch => {
    await BackendApi.get("/api/lookup/countries").then((response) => {  
        dispatch({ type: LOOKUP_GET_COUNTRIES, data: response.data});
      }/*, (error) => {
        console.log("getCountries - error", error);
        throw new Error((error.response != null) ? error.response.data.error_description : 'Something went wrong!');
      }*/).catch((error) => {
        console.log("getCountries - catch error", error);
        console.log(error);
        console.log("getCountries - code", error.code);
        console.log("getCountries - message", error.message);
        console.log("getCountries - stack", error.stack);
        
        const errorMessage = (error.response && error.response.data && error.response.data.error_description) ? 
          error.response.data.error_description : "Something went wrong!, " + error.message;

        throw new Error(errorMessage);
    });
  };
};

export const getCities = (countryId) => {
  return async dispatch => {
    if (countryId)
      await BackendApi.get("/api/lookup/cities/" + countryId).then((response) => {  
          dispatch({ type: LOOKUP_GET_CITIES, data: response.data});
        }/*, (error) => {
          console.log("getCountries - error", error);
          throw new Error((error.response != null) ? error.response.data.error_description : 'Something went wrong!');
        }*/).catch((error) => {
          console.log("getCountries - catch error", error);
          console.log(error);
          console.log("getCountries - code", error.code);
          console.log("getCountries - message", error.message);
          console.log("getCountries - stack", error.stack);
          
          const errorMessage = (error.response && error.response.data && error.response.data.error_description) ? 
            error.response.data.error_description : "Something went wrong!, " + error.message;

          throw new Error(errorMessage);
      });
    else {
      dispatch({ type: LOOKUP_GET_CITIES, data: []});
    }
  };
};

export const getUserTypes = () => {
  return async dispatch => {
    await BackendApi.get("/api/lookup/user-types").then((response) => {  
        dispatch({ type: LOOKUP_GET_USER_TYPES, data: response.data});
      }, (error) => {
        console.log("error", error);
        throw new Error((error.response != null) ? error.response.data.error_description : 'Something went wrong!');
      });
  };
};

export const getLookupPreferences = () => {
  return async dispatch => {
    await BackendApi.get("/api/lookup/preferences").then((response) => {  
        console.log(response.data);
        dispatch({ type: LOOKUP_GET_PREFERENCES, data: response.data});
      }, (error) => {
        console.log("error", error.response);
        throw new Error((error.response != null) ? error.response.data.error_description : 'Something went wrong!');
      });
  };
};

export const getCompanies = () => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/lookup/companies");
    return dispatch({ type: LOOKUP_GET_COMPANIES, data: response.data });
  };
};