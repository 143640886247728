import BackendApi from '../backend-api';

export const GET_SALES_COMPANY_GROUPS = 'GET_SALES_COMPANY_GROUPS';
export const GET_SALES_GROUP_TEAMS = 'GET_SALES_GROUP_TEAMS';
export const GET_SALES_TEAM_AGENTS = 'GET_SALES_TEAM_AGENTS';
export const GET_SALES_PENDING_ASSIGNATION_SEARCH = 'GET_SALES_PENDING_ASSIGNATION_SEARCH';
export const GET_SALES_CLIENT_SEARCH = 'GET_SALES_CLIENT_SEARCH';
export const GET_SALES_CLIENT_DETAILS = 'GET_SALES_CLIENT_DETAILS';

export const getCompanyGroups = (companyId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/sales/companies/" + companyId + "/groups");
    return dispatch({ type: GET_SALES_COMPANY_GROUPS, data: response.data });
  };
};

export const getGroupTeams = (groupId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/sales/companies/" + '*' + "/groups/" + groupId + "/teams");
    return dispatch({ type: GET_SALES_GROUP_TEAMS, data: response.data });
  };
};

export const getTeamAgents = (teamId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/sales/companies/" + '*' + "/groups/" + '*' + "/teams/" + teamId);
    return dispatch({ type: GET_SALES_TEAM_AGENTS, data: response.data });
  };
};

export const postClientAssignCompany = (clientId, companyId) => {
  return async (dispatch) => {
    let response = await BackendApi.post(`/api/sales/client/${clientId}/assign-company/${companyId}`, {});
    return response.data;
  };
};

export const postClientAssignGroup = (clientId, groupId) => {
  return async (dispatch) => {
    let response = await BackendApi.post(`/api/sales/client/${clientId}/assign-group/${groupId}`, {});
    return response.data;
  };
};

export const postClientAssignTeam = (clientId, teamId) => {
  return async (dispatch) => {
    let response = await BackendApi.post(`/api/sales/client/${clientId}/assign-team/${teamId}`, {});
    return response.data;
  };
};

export const postClientAssignAgent = (clientId, agentId) => {
  return async (dispatch) => {
    let response = await BackendApi.post(`/api/sales/client/${clientId}/assign-agent/${agentId}`, {});
    return response.data;
  };
};

export const postPendingAssignationSearch = (customerId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/" + customerId + "/pending-assignations/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_SALES_PENDING_ASSIGNATION_SEARCH, data: response.data });
  };
};

export const postClientSearch = (customerId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/" + customerId + "/client/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_SALES_CLIENT_SEARCH, data: response.data });
  };
};

export const getClientDetail = (userId, clientId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/sales/" + userId + "/client/" + clientId + "/details");
    return dispatch({ type: GET_SALES_CLIENT_DETAILS, data: response.data });
  };
};

export const postRequestMoreInformation = (buildingId, form) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/leads/request-more-information/" + buildingId, form);
    return response.data;
  };
};

export const postClientUpdateStatus = (userId, clientId, status) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/" + userId + "/client/" + clientId + "/status/" + status, {});
    return response.data;
  };
};

export const postPrebooking = (buildingId) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/leads/prebooking/" + buildingId);
    return response.data;
  };
};

export const postCancelPrebooking = (buildingId) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/sales/leads/cancel-prebooking/" + buildingId);
    return response.data;
  };
};
