import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, change, stopAsyncValidation } from 'redux-form';
import * as Actions from "../../api/actions";
import store, {isLocalDevelopment} from "../../api/store";
import classNames from "classnames";
import { _t, ErrorManagement, AfeFields, LOADSTATE, SUBMITSTATE } from "../../nclab-react-core";
class WizzardStep extends Component {
  render() {

    const { title, iconFamily, icon, active } = this.props;

    return (
      <div className="step">
        <div className={classNames({
            icon: true,
            active: active
          })}>
          <i className={classNames(iconFamily, icon, {
          })}></i>
        </div>
        <div className={classNames("title", {
            active: active
          })}>
          {title}
        </div>
      </div>
    );
  }
}

class WizzardStepList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: this.props.activeStep
    };
  }

  getActiveTitle = () => {
    return this.props.steps.filter( step => step.id === this.props.activeStep)[0].title;
  }

  render() {

    const { steps, activeStep } = this.props;

    return (
      <div className="wizzard-steps">
      <div className="title">{this.getActiveTitle()}</div>
      {steps.map((step) =>
        <WizzardStep key={step.id} title={step.title} iconFamily={step.iconFamily} 
          icon={step.icon} active={(step.id === activeStep) ? true : false} activeStep={0}/>
      )}
      </div>
    );
  }
}

class RegisterScreen extends Component {
  state = {
    loadState: LOADSTATE.LOADING,
    submitState: SUBMITSTATE.NONE,
    isSubmiting: false,
    isLoading: false,
    sendOk: false,
    activeStep: 0
  };

  steps = [
    {id: 0, title: 'Información personal', iconFamily: 'fas', icon: 'fa-user'},
    {id: 1, title: 'Información de pago', iconFamily: 'fas', icon: 'fa-credit-card'},
    {id: 2, title: 'Datos de facturación', iconFamily: 'fas', icon: 'fa-file'},
    {id: 3, title: 'Confirmación', iconFamily: 'fas', icon: 'fa-trophy'}
  ];

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.props.change('activeStep', this.state.activeStep);

    await this.onLoad();
  }

  onLoad = async () => {
    try {
      this.setState({ loadState: LOADSTATE.LOADING });
      
      await store.dispatch(Actions.getCountries());
      await store.dispatch(Actions.getCities(this.props.initialValues.countryId));
      
      this.setState({ loadState: LOADSTATE.LOADED_OK });
    } catch (error) {
      this.setState({ loadState: LOADSTATE.LOADED_KO });
      store.dispatch(stopAsyncValidation(RegisterScreen.name, { _error: ErrorManagement.getMessage(error) }));
    }
  }

  onChangeTestUser = (value) => {
    const foundElement = this.state.demoUsers.filter( x => x.username === value);
    if (foundElement.length > 0) {
      const selection = foundElement[0];
      console.log("Se selecciono:", selection);
      this.props.dispatch(change('LoginScreen', 'email', selection.username));
      this.props.dispatch(change('LoginScreen', 'password', selection.password));
    }
  }

  onChangeCountry = async (countryId) => {
    try {
      console.log("Recuperando paises");
      await store.dispatch(Actions.getCities(countryId));
    } catch (error) {
    }
  }

  onNext = () => {   
    if (this.state.activeStep !== this.steps.length - 1) {
      this.props.change('activeStep', this.state.activeStep + 1 );
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  onPrev = () => {
    if (this.state.activeStep !== 0) {
      this.props.change('activeStep', this.state.activeStep - 1 );
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  }

  onSubmit = async (formValues) => {

    if (this.state.activeStep !== this.steps.length - 1) {
      this.onNext();
      return;
    }

    try {
      this.setState({ submitState: SUBMITSTATE.SUBMITTING });

      await store.dispatch(Actions.registerInversor(formValues)); 
      
      this.setState({ submitState: SUBMITSTATE.SUBMITTED_OK, activeStep: this.steps.length });

    } catch (error) {
      this.setState({ submitState: SUBMITSTATE.SUBMITTED_KO });
      
      throw new SubmissionError({ _error: ErrorManagement.getMessage(error) });
    }
  }

  render() {

    const { title, handleSubmit, submitting=false, error } = this.props;

    let formContent = null;

    if (this.state.loadState === LOADSTATE.LOADING)
      formContent = (<>
        <div className="h3 card-title">Cargando</div>
        <p>...</p>
      </>);

    if (this.state.loadState === LOADSTATE.LOADED_KO)
      formContent = (<>
        <div className="h3 card-title">Error en la operación</div>
          {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}
          <button type="button" className="btn btn-secondary font-default-normal" 
                onClick={ () => this.onLoad() } >Reintentar</button>
      </>);

    if (this.state.loadState !== LOADSTATE.LOADED_OK)
      return (
        <div className="site-section">
          <div className="container">
            <div className="row mb-2">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {formContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>);

    if (this.state.activeStep === this.steps.length)
      return (
        <div className="site-section">
          <div className="container">
            <div className="row mb-2">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="h3 card-title">La Información de registro ha sido enviada</div>
                      <p>Gracias por confiar en Kiri Coin, en breve nos pondremos en contacto con ud.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>);

    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="h3 card-title">{title}</div>
                  
                  <form onSubmit={handleSubmit(this.onSubmit)}>

                    <div className="row mt-4">
                        <div className="col-md-12">
                          <WizzardStepList steps={this.steps} activeStep={this.state.activeStep}/>
                        </div>
                    </div>

                    {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}

                    {/* PAGE Personal Details */}

                    {this.state.activeStep === 0 &&
                    <div className="py-5">
                      <div className="form-row">
                          <Field label="Correo electrónico" name="email" type="text" 
                            component={AfeFields.Input} placeholder="Correo electrónico" />
                          <Field label="Contraseña" name="password" type="password"
                            component={AfeFields.Input} placeholder="Contraseña" />
                          <Field label="Repita la contraseña" name="repeatPassword" type="password"
                            component={AfeFields.Input} placeholder="Repita la contraseña" />
                      </div>
                      <div className="form-row">
                          <Field label="DNI / Pasaporte" name="dni" type="text"
                            component={AfeFields.Input} placeholder="DNI o pasaporte" />
                          <Field label="Nombre" name="firstName" type="text"
                            component={AfeFields.Input} placeholder="Nombre" />
                          <Field label="Apellidos" name="lastName" type="text"
                            component={AfeFields.Input} placeholder="Apellidos" />
                      </div>
                      <div className="form-row">
                          <Field label="Fecha de nacimiento" name="dateOfBirth" type="date"
                            component={AfeFields.Input} placeholder="" />
                          <Field label="País" name="countryId" type="text" component={AfeFields.Select}
                              onChange={($) =>  this.onChangeCountry($.target.value)} >
                              <option value=""></option>
                              {this.props.countries != null && this.props.countries.map(
                              data => <option key={data.id} value={data.id}>{data.name}</option>)}
                          </Field>
                          <Field label="Ciudad" name="cityId" type="text" component={AfeFields.Select}>
                              <option value=""></option>
                              {this.props.cities != null && this.props.cities.map(
                              data => <option key={data.id} value={data.id}>{data.name}</option>)}
                          </Field>
                      </div>
                      <div className="form-row">
                          <Field label="Código postal" name="postalCode" type="text" containerStyle="col-sm-4"
                            component={AfeFields.Input} placeholder="Código postal" />
                      </div>
                    </div>}

                    {/* PAGE Payment Info */}

                    {this.state.activeStep === 1 &&
                    <div className="pb-5 pt-1">
                      <div className="form-row">
                        <Field name="useCreditCard" component={AfeFields.Checkbox} type="checkbox" >
                          <b>Targeta de crédito</b>
                        </Field>
                      </div>
                      <div className="form-row">
                          <Field label="Número de targeta de crédito" name="creditCard" type="text" 
                            component={AfeFields.Input} placeholder="Número de targeta de crédito" />
                          <Field label="CCV" name="ccv" type="text"
                            component={AfeFields.Input} placeholder="CCV de la targeta, búsquelo en el reverso" />
                          <Field label="Fecha de expiración (mm/yyyy)" name="expirationDate" type="date"
                            component={AfeFields.Input} placeholder="Fecha de expiración" />
                      </div>
                      <div className="form-row pt-4">
                        <Field name="newEthereumWallet" component={AfeFields.Radio} value="false" type="radio">
                          <b>Ya tengo un monedero Ethereum y quiero poder usarlo en KiriCoin</b>
                        </Field>
                      </div>
                      <div className="form-row">
                          <Field label="Número de monedero Ethereum" name="ethereumWallet" type="text" containerStyle="col-sm-4"
                            component={AfeFields.Input} placeholder="Número de monedero Ethereum" />
                      </div>
                      <div className="form-row">
                        <Field name="newEthereumWallet" component={AfeFields.Radio} value="true" type="radio">
                          <b>Quiero crear un monedero Ethereum solo para KiriCoin</b>
                        </Field>
                      </div>

                    </div>}

                    {/* PAGE Billing Info */}

                    {this.state.activeStep === 2 &&
                    <div className="py-5">
                      <div className="form-row">
                          <Field label="Dirección de facturación linea 1" name="billingAddressLine1" type="text" 
                            component={AfeFields.Input} placeholder="Nombre de la calle, avenida y número" />
                          <Field label="Dirección de facturación linea 2" name="billingAddressLine2" type="text"
                            component={AfeFields.Input} placeholder="Número de apartamento, piso, escalera" />
                          <Field label="País" name="billingCountry" type="text"
                            component={AfeFields.Input} placeholder="País" />
                      </div>
                      <div className="form-row">
                          <Field label="Provincia" name="billingState" type="text" 
                            component={AfeFields.Input} placeholder="Provincia" />
                          <Field label="Ciudad" name="billingCity" type="text"
                            component={AfeFields.Input} placeholder="Ciudad" />
                          <Field label="Código postal" name="billingPostalCode" type="text"
                            component={AfeFields.Input} placeholder="Código postal" />
                      </div>
                    </div>}

                    {/* PAGE Confirmation Info */}

                    {this.state.activeStep === 3 &&
                    <div className="py-5">
                      <div className="form-row">
                        <p>Se enviará un correo electrónico de confirmación del registro</p>
                        <p>Se le asignará un asesor financiero que le brindará una atención personalizada y al cual podrá contactar de diferentes maneras y que se le indicará en el correo del registro</p>
                        <p>Revise que nuestro correo no haya ido a su bandeja de correo no deseado. </p>
                        <p>La confirmación del registro habitualmente tarda unas 24 horas</p>
                      </div>
                      <div className="form-row">
                        <p>
                          <strong>Puede continuar explorando nuestros productos </strong>
                          <Link to="/home/calculadoras">aquí</Link>
                        </p>
                      </div>
                    </div>}

                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className={classNames(
                          "btn", {
                            "btn-secondary": this.state.activeStep !== 100
                          }, 
                          "font-default-normal")} 
                          onClick={this.onPrev}
                          disabled={this.state.activeStep === 0 || submitting}>Anterior</button>

                        <button type="submit" className={classNames(
                          "ml-2",
                          "btn", 
                          {
                            "btn-primary": this.state.activeStep !== this.steps.length - 1,
                            "btn-success": this.state.activeStep === this.steps.length - 1
                          }, 
                          "font-default-normal")}
                          disabled={submitting}
                        >{ this.state.activeStep !== this.steps.length - 1 ? "Siguiente" : "Finalizar"}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};

  console.log("validando:", values);

  if (!values.email)
    errors.email = _t("errors.required");
  if (!/^[.a-zA-Z0-9_-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(values.email))
    errors.email = "Invalid email";
  if (!values.password)
    errors.password = _t("errors.required"); 
  if (!values.repeatPassword)
    errors.repeatPassword = _t("errors.required");

  if (!values.dni)
    errors.dni = _t("errors.required");
  if (!values.firstName)
    errors.firstName = _t("errors.required");
  if (!values.lastName)
    errors.lastName = _t("errors.required");
  if (!values.dateOfBirth)
    errors.dateOfBirth = _t("errors.required");
  if (!values.countryId)
    errors.countryId = _t("errors.required");
  if (!values.cityId)
    errors.cityId = _t("errors.required");
  if (!values.postalCode)
    errors.postalCode = _t("errors.required");

  if (!values.creditCard)
    errors.creditCard = _t("errors.required");
  if (!values.ccv)
    errors.ccv = _t("errors.required");
  else if (values.ccv.length !== 3)
    errors.ccv = _t("errors.invalidCCVLength");
  if (!values.expirationDate)
    errors.expirationDate = _t("errors.required");

  if (!values.billingAddressLine1)
    errors.billingAddressLine1 = _t("errors.required");
  if (!values.billingAddressLine2)
    errors.billingAddressLine2 = _t("errors.required");
  if (!values.billingCity)
    errors.billingCity = _t("errors.required");
  if (!values.billingState)
    errors.billingState = _t("errors.required");
  if (!values.billingCountry)
    errors.billingCountry = _t("errors.required");
  if (!values.billingPostalCode)
    errors.billingPostalCode = _t("errors.required");

  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: RegisterScreen.name, // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(RegisterScreen);

const testForm = {
  email: 'noel.carlos@gmail.com',
  password: '123456',
  repeatPassword: '123456',
  dni: '2922912',
  firstName: 'Noel',
  lastName: 'Hernandez',
  dateOfBirth: '1973-04-02',
  countryId: 264, 
  cityId: 3,
  postalCode: '08917',
  creditCard: '2372-1292-1222-12212',
  ccv: '321',
  expirationDate: '2024-01-01',
  ethereumWallet: '0x2b4d87eff06f22798c30dc4407c7d83429aa9abc',
  billingAddressLine1: 'Carrer Tarragona #16',
  billingAddressLine2: 'Atic 2na',
  billingCity: 'Barcelona',
  billingState: 'Barcelona',
  billingCountry: 'España',
  billingPostalCode: '08016',
  newEthereumWallet: "true",
  useCreditCard: true
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: isLocalDevelopment() ? testForm : {},
    countries: state.countries,
    cities: state.cities,
    enableReinitialize: true
  };
};

export default connect(
  mapStateToProps,
  {
    
  }
)(form);
