import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, SubmissionError, stopAsyncValidation } from 'redux-form';
import * as Actions from "../../api/actions"; 
import store from "../../api/store";
import { ErrorManagement, LOADSTATE, SUBMITSTATE } from "../../nclab-react-core";
class ConfirmSignupScreen extends Component {
  state = {
    loadState: LOADSTATE.LOADING,
    submitState: SUBMITSTATE.NONE
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.onLoad();
  }

  onLoad = async() => {
    try {
      this.setState({ loadState: LOADSTATE.LOADING });
      const searchParams = new URLSearchParams(this.props.location.search);
   
      await store.dispatch(Actions.confirmSignUp(searchParams.get('userId'), searchParams.get('token')));
   
      this.setState({ loadState: LOADSTATE.LOADED_OK });
    } catch (error) {
      this.setState({ loadState: LOADSTATE.LOADED_KO });
      store.dispatch(stopAsyncValidation(ConfirmSignupScreen.name, { _error: ErrorManagement.getMessage(error) }));
    }
  }

  onRetry = async () => {
    await this.onLoad();
  }

  onSubmit = async (formValues) => {

    if (this.state.activeStep !== this.steps.length - 1) {
      this.onNext();
      return;
    }

    try {
      this.setState({ isSubmiting: true });

      await store.dispatch(Actions.registerInversor(formValues)); 
      
      this.setState({ isSubmiting: false, activeStep: this.steps.length });

    } catch (error) {
      this.setState({ isSubmiting: false });
      
      throw new SubmissionError({ _error: ErrorManagement.getMessage(error) });
    }
  }

  render() {

    const { handleSubmit, error } = this.props;
    let formContent = null;

    if (this.state.loadState === LOADSTATE.LOADING)
      formContent = (<>
        <div className="h3 card-title">Confirmando la subscripción</div>
        <p>Por favor espere.</p>
      </>);

    if (this.state.loadState === LOADSTATE.LOADED_KO)
      formContent = (<>
        <div className="h3 card-title">Error en la operación</div>
          {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}
          <button type="button" className="btn btn-secondary font-default-normal" 
                onClick={ () => this.onRetry() } >Reintentar</button>
      </>);
    
    if (this.state.loadState === LOADSTATE.LOADED_OK)
      formContent = (<>
        <div className="h3 card-title">La subscripción ha sido activada</div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}

          <p>Gracias por confiar en KiriCoin</p>

          <div className="h4 pt-2">
            <Link to="/home/calculadoras" className="btn btn-primary">Consultar nuestros productos</Link>
          </div>

        </form>
      </>);
      
    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {formContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

const validate = values => {
  const errors = {};

  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: ConfirmSignupScreen.name, // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(ConfirmSignupScreen);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: null,
    enableReinitialize: true
  };
};

export default connect(mapStateToProps, {})(form);
