import React, { Component } from "react";

class ProteccionDeDatosScreen extends Component {
  state = {};
  render() {
    return (
      <div className="site-section">
        <div className="container col-md-12">
          <div className="col-md-12">
            <h1>
              Legislación aplicable a los datos personales del USUARIO
              (Protección de Datos de Carácter Personal).
            </h1>
          </div>
          <div className="col-md-12">
            <p>
              HUBTALENT manifiesta su compromiso de cumplir con la legislación
              vigente en cada momento en materia de protección de datos. Se
              informa al USUARIO que los datos de carácter personal que sean
              facilitados en y a través de la web, serán objeto de tratamiento
              automatizado y pasarán a formar parte de los ficheros titularidad
              de HUBTALENT, siendo HUBTALENT el responsable del tratamiento.
            </p>
            <h4>Información básica sobre Protección de Datos</h4>

            <table className="table">
              <tbody>
                <tr>
                  <td>RESPONSABLE</td>
                  <td>
                    HUBTALENT CONSULTORÍA ESTRATÉGICA Y SOLUCIONES INFORMÁTICAS
                    S.L. - NIF: B86562246
                  </td>
                </tr>
                <tr>
                  <td>FINALIDAD</td>
                  <td>
                    PARTICIPACIÓN EN PROCESOS DE SELECCIÓN DE PERSONAL /
                    CONTACTO CON FINES COMERCIALES
                  </td>
                </tr>
                <tr>
                  <td>LEGITIMACIÓN</td>
                  <td>
                    CUMPLIMIENTO DE LA RELACIÓN CONTRACTUAL, INTERÉS LEGÍTIMO Y
                    CONSENTIMIENTO DEL USUARIO.
                  </td>
                </tr>
                <tr>
                  <td>CESIÓN</td>
                  <td>
                    PREVISIÓN DE CESIÓN Y TRANSFERENCIA DE DATOS A TERCEROS.
                  </td>
                </tr>
                <tr>
                  <td>DERECHOS</td>
                  <td>
                    ACCEDER, RECTIFICAR Y SUPRIMIR LOS DATOS, ASÍ COMO OTROS
                    DERECHOS, COMO SE EXPLICA EN LA INFORMACIÓN ADICIONAL.
                  </td>
                </tr>
                <tr>
                  <td>INFORMACIÓN ADICIONAL</td>
                  <td>
                    PUEDE CONSULTAR LA INFORMACIÓN ADICIONAL Y DETALLADA SOBRE
                    PROTECCIÓN DE DATOS EN LOS APARTADOS SIGUIENTES.
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>¿Quién es el responsable del tratamiento?</h4>
            <p>
              HUBTALENT CONSULTORÍA ESTRATÉGICA Y SOLUCIONES INFORMÁTICAS S.L
              con NIF B86562246 y domicilio fiscal en C/ Bravo Murillo 377, 3C,
              28020 Madrid, es el responsable del tratamiento de toda la
              información personal que el USUARIO nos facilite o se recoja a
              través de www.hubtalent.es, siendo tratada por HUBTALENT como
              responsable del tratamiento, mostrándose a continuación sus datos
              de contacto.
            </p>

            <table className="table">
              <tbody>
                <tr>
                  <td>RESPONSABLE</td>
                  <td>
                    HUBTALENT CONSULTORÍA ESTRATÉGICA Y SOLUCIONES INFORMÁTICAS
                    S.L. - NIF: B86562246
                  </td>
                </tr>
                <tr>
                  <td>DIRECCIÓN</td>
                  <td>C/ BRAVO MURILLO 377, 3C. 28020, MADRID</td>
                </tr>
                <tr>
                  <td>EMAIL</td>
                  <td>
                    <a href="mailto:LOPD@HUBTALENT.ES">LOPD@HUBTALENT.ES</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>Finalidad de uso de los Datos Personales</h4>
            <p>
              Los datos personales que el USUARIO nos facilita a través de
              www.hubtalent.es son recopilados por HUBTALENT con las siguientes
              finalidades:
            </p>
            <ul>
              <li>
                <strong>
                  USUARIOS que facilitan sus datos personales voluntariamente
                  actuando como CANDIDATOS interesados en alguna de las ofertas
                  de empleo publicadas en la web:
                </strong>
                La finalidad del tratamiento de datos personales de estos
                USUARIOS será el de la gestión de los diferentes procesos de
                selección en los que se hayan interesado. Los datos personales
                incluidos en este punto no sólo serán los introducidos en los
                diferentes formularios automáticos de contacto o inscripción en
                ofertas de empleo, si no los incluidos en cualquier documento
                adjunto que el USUARIO facilite a HUBTALENT.
              </li>
              <li>
                <strong>
                  USUARIOS que faciliten sus datos personales voluntariamente
                  actuando como CLIENTES interesados en alguno de los servicios
                  comerciales que presta HUBTALENT:
                </strong>
                La finalidad del tratamiento será el de la gestión de la
                relación comercial entre HUBTALENT y sus CLIENTES o potenciales
                CLIENTES. Los datos personales incluidos en este punto no sólo
                serán los introducidos en los diferentes formularios automáticos
                de contacto o inscripción en ofertas de empleo, si no los
                incluidos en cualquier documento adjunto que el USUARIO facilite
                a HUBTALENT.
              </li>
            </ul>
            <h4>Legitimación para el Tratamiento de Datos Personales</h4>
            <p>
              Al aceptar la política de privacidad general y la específica
              relativa a Datos Personales el USUARIO manifiesta haber leído y
              aceptado expresamente la presente política de privacidad incluida
              dentro de las condiciones legales del USUARIO, y otorga su
              consentimiento inequívoco y expreso al tratamiento de sus datos
              personales conforme a las finalidades informadas y servicios que
              presta HUBTALENT, así como la cesión de sus datos personales a
              terceros para el cumplimiento del servicio (con los condicionantes
              que se especifican en el apartado “Destinatarios a los que se
              comunicarán datos personales”). A estos efectos se entiende por
              Tercero “las Organizaciones que utilizan los servicios selección
              de personal de HUBTALENT”.
            </p>
            <h4>Destinatarios a los que se cederán los Datos Personales</h4>
            <p>
              HUBTALENT tiene un firme compromiso con el cumplimiento de la
              legislación vigente en materia de Protección de Datos personales,
              por eso se cederán datos personales en función de las siguientes
              circunstancias:
            </p>
            <ul>
              <li>
                <strong>
                  USUARIO que facilite sus datos personales voluntariamente
                  actuando como CANDIDATO interesado en alguna de las ofertas de
                  empleo publicadas en la web:
                </strong>
                Los datos se cederán a los diferentes CLIENTES con los que
                HUBTALENT mantiene una relación comercial para el desarrollo del
                servicio contratado y para que el USUARIO pueda participar en
                los diferentes procesos de selección en los que esté interesado.
                Los datos personales incluidos en este punto no sólo serán los
                introducidos en los diferentes formularios automáticos de
                contacto o inscripción en ofertas de empleo, si no los incluidos
                en cualquier documento adjunto que el USUARIO facilite a
                HUBTALENT.
              </li>
              <li>
                <strong>
                  USUARIO que facilite sus datos personales voluntariamente
                  actuando como CLIENTE interesado en alguno de los servicios
                  que HUBTALENT presta como compañía:
                </strong>
                No existirá cesión de datos personales a terceros ajenos a
                HUBTALENT. Los datos personales incluidos en este punto no sólo
                serán los introducidos en los diferentes formularios automáticos
                de contacto o inscripción en ofertas de empleo, si no los
                incluidos en cualquier documento adjunto que el USUARIO facilite
                a HUBTALENT.
              </li>
            </ul>
            <h4>Ejercicio de sus Derechos</h4>
            <p>
              Como USUARIO y titular de derechos usted tiene el control de sus
              datos, y por tanto, en cualquier momento puede ejercitar sus
              derechos enviando un e-mail a la dirección de correo{" "}
              <a href="mailto:LOPD@HUBTALENT.ES">LOPD@HUBTALENT.ES</a>
            </p>
            <p>Los derechos que puede ejercitar son:</p>

            <table className="table">
              <tbody>
                <tr>
                  <td>Derecho de Información</td>
                  <td>
                    El USUARIO tiene derecho a ser informado de forma clara
                    antes de que sus datos sean recogidos, con qué finalidad se
                    tratan, de dónde se consiguen los datos y si se van a
                    comunicar o no a un tercero ajeno a HUBTALENT.
                  </td>
                </tr>
                <tr>
                  <td>Derecho de acceso</td>
                  <td>
                    El USUARIO tiene derecho a conocer qué datos suyos están
                    siendo tratados, con qué finalidad se tratan, dónde se han
                    conseguido los datos y si los van a comunicar o los han
                    comunicado a alguien.
                  </td>
                </tr>
                <tr>
                  <td>Derecho de rectificación</td>
                  <td>
                    El USUARIO podrá modificar aquellos datos suyos inexactos o
                    incompletos.
                  </td>
                </tr>
                <tr>
                  <td>Derecho de cancelación</td>
                  <td>
                    El USUARIO podrá cancelar sus datos inadecuados, excesivos o
                    cualquier otro dato que considere.
                  </td>
                </tr>
                <tr>
                  <td>Derecho de oposición</td>
                  <td>
                    El USUARIO, con el objetivo de evitar que se traten sus
                    datos o que dejen de tratarse, podrá ejercer el derecho de
                    oposición al tratamiento de sus datos personales, aunque
                    sólo en los supuestos que establece la ley.
                  </td>
                </tr>
                <tr>
                  <td>Derecho de limitación del tratamiento</td>
                  <td>
                    El USUARIO podrá solicitar que se suspenda el tratamiento de
                    datos en los supuestos que establece la ley.
                  </td>
                </tr>
                <tr>
                  <td>Derecho a la portabilidad de los datos</td>
                  <td>
                    El USUARIO podrá recibir sus datos facilitados en un formato
                    electrónico estructurado y de uso habitual, para poder
                    transmitirlos a otro responsable.
                  </td>
                </tr>
                <tr>
                  <td>
                    Derecho a no ser objeto de decisiones individualizadas
                  </td>
                  <td>
                    Con la finalidad de que no se tome una decisión sobre el
                    USUARIO que produzca efectos jurídicos o le afecte basada
                    sólo en el tratamiento de sus datos.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>¿Por cuánto tiempo conservamos los datos personales?</p>
            <p>
              Los datos personales recogidos a través de los formularios de la
              web se conservarán durante el tiempo que dure tu relación con
              HUBTALENT: Mientras el USUARIO continúe como USUARIO registrado.
              Una vez el USUARIO deje de ser USUARIO registrado y terminada la
              relación se mantendrán bloqueados (HUBTALENT no los va a tratar)
              con el fin de cumplir las posibles obligaciones legales.
              Posteriormente HUBTALENT procederá a la eliminación de los datos
              de las bases de datos de HUBTALENT
            </p>
            <p>
              En caso de retirar el consentimiento u oponerse al tratamiento,
              los datos se bloquearán y dejarán de tratarse, y se conservarán
              por un plazo de 4 años a efectos de que HUBTALENT pueda reclamar o
              defenderse de posibles reclamaciones.
            </p>
            <p>Derecho del USUARIO a retirar su consentimiento:</p>
            <p>
              En cualquier momento el USUARIO podrá retirar su consentimiento
              para el tratamiento de datos por parte de HUBTALENT. Para su
              retirada bastará con que el USUARIO envíe un email a
              lopd@hubtalent.es . La retirada del consentimiento podrá
              realizarse en cualquier momento por parte del interesado. El
              USUARIO acepta y consiente que la retirada del consentimiento
              implicará que HUBTALENT no pueda prestarle ninguno de los
              servicios que ofrece como compañía.
            </p>
            <p>
              Derechos de acceso, rectificación, supresión, limitación del
              tratamiento, oposición y derecho a la portabilidad de los datos:
            </p>
            <p>
              El derecho de acceso se concibe como el derecho que tiene el
              USUARIO a solicitar a HUBTALENT si trata algún dato suyo, junto
              con la información que se establece en el artículo 15 del
              Reglamento. Según establece el artículo 16 del Reglamento, el
              USUARIO podrá solicitar a HUBTALENT la rectificación de los datos
              presentes en su base de datos si éstos fueran incompletos. El
              derecho de supresión faculta al USUARIO a solicitar a HUBTALENT la
              supresión de sus datos personales en los casos previstos en el
              artículo 17 del Reglamento. Si el USUARIO lo solicitara, en base
              a las condiciones del artículo 18 del Reglamento, HUBTALENT
              limitará el uso de sus datos a lo establecido en ese artículo. EL
              artículo 20 del Reglamento regula el derecho a la portabilidad que
              tienen los USUARIOS, que les permitirá solicitar a HUBTALENT el
              envío de todos los datos que tenga en un formato estructurado y de
              lectura automática, pudiendo solicitar que los transmita a otro
              responsable que el mismo Usuario solicite.
            </p>
            <p>¿Cómo puede ejercitar sus derechos el Usuario?</p>
            <p>
              El USUARIO también tendrá el derecho de oponerse al trato de los
              datos personales que tenga HUBTALENT en su poder conforme se
              establece en el artículo 21 del mismo Reglamento. El ejercicio de
              los mismos es personalísimo, por lo que será necesario que el
              afectado acredite su identidad. El ejercicio de estos derechos
              deberá realizarse por escrito solicitándolo al email{" "}
              <a href="mailto:LOPD@HUBTALENT.ES">LOPD@HUBTALENT.ES</a>{" "}
              incluyendo:
            </p>
            <ul>
              <li>
                Fotocopia del DNI (pasaporte u otro documento de identidad).
              </li>
              <li>
                Contenido de la petición que realiza el USUARIO y si fuera
                necesario, los documentos que la acreditan.
              </li>
              <li>Dirección (a efectos de notificaciones), fecha y firma.</li>
            </ul>
            <p>
              Si el USUARIO ejercita sus derechos por un representante
              voluntario expresamente designado, deberás aportar el documento o
              instrumento electrónico que acredite la representación. En el
              caso de que el titular de los datos fuera menor de edad o
              incapacitado, se ejercitará los derechos por su representante
              legal debidamente acreditado.
            </p>
            <p>¿Cómo protegemos sus datos?</p>
            <p>
              HUBTALENT pone en conocimiento de los USUARIOS que ha adoptado las
              medidas de índole técnica y organizativas reglamentariamente
              establecidas, que garantizan la seguridad de los datos de carácter
              personal y evitan su alteración, pérdida, tratamiento o acceso no
              autorizado, habida cuenta del estado de la tecnología, la
              naturaleza de los datos almacenados y los riesgos a que estén
              expuestos, todo ello de conformidad con lo establecido en el Real
              Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el
              Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de
              diciembre, de protección de datos de carácter personal y otros
              procedimientos de control para la seguridad de los sistemas de
              información.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ProteccionDeDatosScreen;
