import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class Question extends Component {
  state = {
    showAnswer: false
  };

  async componentDidMount() {
    this.setState({'showAnswer': this.props.showAnswer});
  }

  toogleState = () => {
    this.setState({'showAnswer': this.state.showAnswer === true ? false : true});
  }

  render() {
    const { title, children } = this.props;

    return (
      <Fragment>
        <div className="d-flex">
          <div className="justify-content-end">
            <div className="question-title">
              <i className="far fa-question-circle color-primary"></i>
            </div>
          </div>
          <div className="col-md-11 col-11">
            <div className="row">
              <div className="col-md-12 question-title" onClick={ () => this.toogleState() } >{title}</div>
            </div>
            {this.state.showAnswer &&
            <div className="row">
              <div className="col-md-12 question-body">{children}</div>
            </div>}
          </div>
        </div>
      </Fragment>
    );
  }
}

class QuestionItem extends Component {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <div className="question-item">
          <i class="fas fa-circle color-primary question-item-bullet"></i>
          {children}
        </div>
      </Fragment>
    );
  }
}

class PreguntasRespuestasScreen extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="site-section">
        <div className="container">
          <div className="h2 text-primary">Preguntas frecuentes</div>
        </div>
        <div className="container">
          {/*Sobre el funcionamiento de KiriCoin*/}

          <Question title="¿Qué es KiriCoin?" showAnswer="true">
            KiriCoin es una criptomoneda basada en Ethereum, desde la cual se
            gestionan ahorros e inversiones en bienes tangibles.
          </Question>
          <Question title="¿Cuáles son las principales características de KiriCoin?">
            KiriCoin es una criptomoneda que está basada en bienes tangibles y
            además de sostenible con el medioambiente, es estable, garantizada,
            descentralizada, segura y muy rentable.
          </Question>

          <Question title="¿En qué se diferencia KiriCoin con otras monedas?">
            KiriCoin fue creada para corregir los puntos débiles de los dos
            grupos de monedas existentes:
            <QuestionItem>
              Las monedas fiduciarias (Dólar, Euro, Libra, Yen, Yuan, etc.), que
              están centralizadas y politizadas, cuentan con pocas garantías,
              pierden su valor ante la inflación, y proporcionan muy poca o
              ninguna rentabilidad.
            </QuestionItem>
            <QuestionItem>
              Y las monedas virtuales (Bitcoin, Litecoin, Primecoin, Ripple,
              Ether, etc.), que son inestables, sin garantías y muy volátiles.
            </QuestionItem>
            <QuestionItem>
              En KiriCoin hemos logrado, una criptomoneda que está basada en
              bienes tangibles y además de sostenible con el medioambiente es
              estable, garantizada, descentralizada, segura y muy rentable.
            </QuestionItem>
          </Question>

          <Question title="¿Qué tipo de negocio me propone KiriCoin?">
            Invertir en KiriCoin.
          </Question>
          <Question title="¿Qué beneficios obtengo invirtiendo en KiriCoin?">
            Una excelente rentabilidad de tus ahorros o inversiones, y una buena
            planificación financiera para tu futuro.
          </Question>
          <Question title="¿Como obtengo rentabilidad de mis ahorros en KiriCoin?">
            A través de una renta fija.
          </Question>
          <Question title="¿Cómo obtengo la renta fija?">
            A través de los distintos planes de ahorro e inversión. Puedes ver
            las opciones en la{" "}
            <Link to="/home/calculadoras">calculadora KiriCoin.</Link>.
          </Question>
          <Question title="¿En que invierte KiriCoin?">
            <QuestionItem>
              Principalmente en la compra de viviendas para alquilar y también en la siembra y explotación del árbol paulownia
              (kiri).
            </QuestionItem>
            <QuestionItem>
              Complementariamente, invierte en la cantidad justa y necesaria de
              animales de pastoreo, que se convierten en nuestros aliados para
              mantener las plantaciones de árboles abonadas orgánicamente y con
              ello mantenemos el suelo sin necesidad de químicos ni herbicidas.
              Al mismo tiempo, al tener los animales con baja densidad se
              autoalimentan a mínimo coste, de forma natural y ecológica,
              logrando obtener productos finales 100% orgánicos. Podemos
              asegurar, que tener animales en pastoreo con una muy baja
              densidad, en régimen de silvicultura y pastoreo autosustentable,
              ayudan a hacer más rentable nuestra inversión principal, la
              “siembra y explotación del árbol paulownia”.
            </QuestionItem>
          </Question>

          {/*Sobre rentabilidad*/}

          <Question title="¿Como consigue KiriCoin la rentabilidad para cubrir mi plan de ahorro?">
          <QuestionItem>
              KiriCoin invierte comprando viviendas y alquilándolas a jubilados que tengan garantías suficientes para asegurar el pago del alquiler.
            </QuestionItem>
            <QuestionItem>
              KiriCoin también invierte en la siembra de plantaciones de paulownias,
              árbol de muy rápido crecimiento, donde el volumen de su preciada
              madera aumenta anualmente y con ello su valor.
            </QuestionItem>
            <QuestionItem>
              Con parte de estas ganancias, KiriCoin asegura y reasegura tus
              bienes.
            </QuestionItem>
            <QuestionItem>
              Otra parte de las ganancias, KiriCoin la invierte cuidando las
              paulownias sembradas, siempre con métodos ecológicos y
              sostenibles, para obtener la mejor calidad de madera a través una
              poda especializada, recogida de ramas, deshierbado, riego con
              incorporación de nutrientes, etc.
            </QuestionItem>
            <QuestionItem>
              Además, KiriCoin cubre tu plan de ahorro o inversión.
            </QuestionItem>
            <QuestionItem>
              El resto, son beneficios que obtiene KiriCoin, que se destinan
              principalmente a la investigación, desarrollo e innovación (I+D+I)
              para la lucha contra los efectos del cambio climático.
            </QuestionItem>
          </Question>
          <Question title="¿Qué rentabilidad ofrece KiriCoin?">
            Cada plan proporciona una rentabilidad distinta, depende del plan
            que escojas (jubilación, universidad, padrinazgo, cero cupones,
            emprendedor, etc.).{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>

          {/*Sobre planes de ahorro*/}

          <Question title="¿Cómo puedo hacer un Plan de Jubilación digna que contribuya a mejorar mi longevidad con buena salud?">
            Diseña anticipadamente cuál es la jubilación que quieres tener,
            piensa en la posibilidad de hacer realidad algunos sueños que
            quedaron pendientes en tu juventud, calcula los distintos gastos de
            transporte, residencia, alimentación, vacaciones y otros gastos; no
            olvides los imprevistos en salud, ten en cuenta que puedes tener
            costes adicionales para resolver los dos principales problemas en la
            tercera edad (soledad y necesidad de asistencia) y especialmente
            recuerda que la inflación puede demoler tus planes, y una vez tengas
            la cifra final, entra en la calculadora KiriCoin y podrás escoger la
            opción, que más se adapte a tus deseos.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Cómo puedo hacer un Plan de Ahorro en Fidecomiso para la Universidad de mi hijo?">
            Escoge primero donde te gustaría que estudiara, calcula los
            distintos gastos de transporte, residencia, alimentación,
            universidad, vacaciones y otros gastos; no olvides hacer una
            previsión de la inflación y una vez tengas la cifra final, entra en
            la calculadora KiriCoin, donde podrás escoger la opción, que más se
            adapte a tus deseos y posibilidades.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Cómo puedo hacer un Plan de Padrinazgo?">
            Si te hacen padrino de un recién nacido, regálale algo que sirva
            para “asegurar su futuro”, haz una sola inversión en KiriCoin y con
            la renta fija acumulada, veras lo que puede recibir a la edad que tu
            decidas y para el uso que indiques. Define qué cantidad quieres
            aportar, y analiza las opciones que te ofrece KiriCoin.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Cómo puedo hacer un Plan Cero Cupones?">
            Si tu proyecto de vida, pasa por disponer de una cantidad de dinero,
            puedes lograrlo con KiriCoin. Define cual es el monto que quieres
            lograr, la cantidad que puedes aportar, y/o el ahorro mensual que
            puedes hacer, así sabrás que tiempo es necesario para lograr el
            resultado deseado.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Cómo puedo hacer un Plan Emprendedor?">
            Define el proyecto que quieres emprender, haz un plan de empresa
            bien elaborado (ten en cuenta que 8 de cada 10 emprendimientos
            cierran antes de cumplir un año), y solo aquellos que han tenido un
            buen proceso de maduración, el conocimiento suficiente, y los
            recursos para cubrir los costos de apertura + un tiempo prudente de
            déficit de inicio de actividad, logran sobrevivir. No te precipites
            en arrancar, prepárate y espera a tener ahorrada la cantidad
            necesaria para lograr los objetivos propuestos.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Cómo puedo hacer un Plan de Compromiso de Sostenibilidad con el Medioambiente?">
            En el año 2020, en el mundo se producirán 40.000.000.000 de
            toneladas de CO2. Somos 7.625.000.000 habitantes, lo que significa
            que en promedio generamos 5,25 toneladas/habitante/año. Un kiri
            captura cuando es adulto 21,7 kg de CO2 diario y en promedio a lo
            largo de su ciclo de vida (cada 7 años) absorbe anualmente unos 275
            kg/año, por lo que es necesario sembrar 19 árboles paulownia por
            cada miembro de la familia, y con ello se logra una emisión neutra
            de CO2. <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>
          <Question title="¿Pueden ayudarme a hacer un Plan de Ahorro Personalizado?">
            Si, entra en la calculadora KiriCoin y podrás comprobar las
            distintas opciones, escogiendo la que más se adapte a tus
            necesidades y posibilidades. Además, puedes solicitar un plan
            personalizado de acuerdo a tus deseos.{" "}
            <Link to="/home/calculadoras">Calculadora KiriCoin.</Link>
          </Question>

          {/*Sobre donde es mejor tener el dinero*/}

          <Question title="¿Es mejor tener el dinero en KiriCoin que en mi banco o una caja fuerte?">
            Si, porque el dinero no crece ni se reproduce en un banco o caja
            fuerte, con el paso del tiempo y la inflación, cada vez puedes
            comprar menos cosas con ese dinero. En cambio, invertido en
            KiriCoin, y sembrando un árbol paulownia, tu dinero crece muy
            rápidamente, casi al mismo ritmo del árbol, que en pocos años (6 a
            8) pasa de una planta diminuta, a un árbol de alto valor comercial
            de aproximadamente un metro cubico (58% para madera y 42% para
            biomasa), adquiriendo un valor muy significativo.
          </Question>
          <Question title="¿Es mejor tener el dinero en KiriCoin que en un fondo de inversión?">
            Si, porque no asumes riesgos innecesarios y nunca pierdes el control
            de donde y como están invertidos tus ahorros. Además, las
            referencias de rentabilidad del pasado, no son garantía de futuro.
          </Question>
          <Question title="¿Por qué en KiriCoin y no en otro instrumento financiero?">
            En KiriCoin inviertes sobre bienes tangibles y de consumo masivo,
            que puedes ver y tocar, y están bien asegurados y reasegurados. Las
            empresas aseguradoras solo aseguran bienes tangibles. La mejor
            garantía de que un bien existe y tiene un valor tangible, es que una
            buena compañía de seguros, lo asegure. En otros instrumentos
            financieros no es así.
          </Question>
          <Question title="¿Puedo retirar mi dinero y/o mi renta en cualquier momento?">
            Si, KiriCoin dispone de un mercado activo de compradores y
            vendedores, y en el momento que decidas recuperar total o
            parcialmente tu inversión, ingresas en él (funciona igual que una
            bolsa de valores) y vendes tu inversión,{" "}
            <Link to="/market">ver precios de mercado.</Link>
          </Question>
          <Question title="¿Retirar mi dinero anticipadamente, tiene penalizaciones?">
            No.
          </Question>
          <Question title="¿Cómo me afecta la inflación en KiriCoin?">
            No te afecta, los planes de inversión de KiriCoin, son siempre en
            monedas fuertes que mantienen una inflación muy inferior a la
            rentabilidad que ofrecen los distintos planes de ahorro o inversión.
          </Question>

          {/*Sobre impuestos*/}

          <Question title="¿Cómo los impuestos afectan a tu plan de ahorro o inversión?">
            KiriCoin no interviene en tu declaración de impuestos. Con la única
            excepción de los ciudadanos de Estonia, donde la hacienda pública de
            Estonia, puede solicitar acceso a la información fiscal de sus
            ciudadanos y residentes en Estonia.
          </Question>
          <Question title="¿Qué impuestos tengo que pagar?">
            Solo los que te exija tu país de residencia.
          </Question>
          <Question title="¿Bajo qué régimen fiscal esta KiriCoin?">
            El de Estonia, en la Unión Europea.
          </Question>
          <Question title="¿Si ya pago impuestos, qué el gobierno invierte en protegerme contra los efectos del cambio climático, porque debo invertir mi dinero en sembrar árboles?">
            Porque es mucho mejor negocio, y más seguro que tener tu dinero en
            el banco o un fondo de inversión, además tienes el plus de los
            “certificados de carbono” y contribuyes a cuidar nuestro planeta.
          </Question>
          <Question title="¿Mis herederos podrán disfrutar de las inversiones que hago en KiriCoin?">
            Si, es un bien tangible que puedes transmitirlo sin limitaciones,
            con solo comunicarnos tu decisión.
          </Question>

          {/*Sobre ubicacion*/}

          <Question title="¿Qué significa trazabilidad?">
            Se entiende trazabilidad como el conjunto de aquellos procedimientos
            preestablecidos y autosuficientes que permiten conocer el histórico,
            la ubicación y la trayectoria de un producto o lote de productos a
            lo largo de la cadena de suministros en un momento dado, a través de
            unas herramientas determinadas, en este caso Blockchain.
          </Question>
          <Question title="¿En qué países invierte KiriCoin?">
            KiriCoin opera en casi todos los países del mundo, salvo aquellos
            que, por situación política, restricción por sanciones de la ONU,
            USA, CEE o por restricciones administrativas del país en donde se
            quiera operar.
          </Question>
          <Question title="¿Desde donde opera KiriCoin?">
            Operamos en todo el mundo, desde una Blockchain basada en Ethereum,
            bajo la legislación de Estonia en la Unión Europea.
          </Question>
          <Question title="¿Puedo invertir desde mi país, en m2 de viviendas y/o en una plantación de paulownias ubicada en otra parte del mundo?">
            Si.
          </Question>

          {/*Sobre el seguros*/}

          <Question title="¿Por qué es seguro KiriCoin?">
            En KiriCoin se gestionan tus ahorros y se invierten únicamente en
            los bienes tangibles que pasan a ser de tu propiedad. Los cuales
            están asegurados por una aseguradora de solvencia contrastada. La
            presencia de las aseguradoras, en todos los bienes tangibles que se
            gestionan en KiriCoin, es una garantía de que existen y tienen la valoración ajustada al mercado.
          </Question>
          <Question title="¿Qué tan seguro es KiriCoin?">100% seguro.</Question>
          <Question title="¿Cómo lo aseguran?">
            Por riesgos nominados propios de daños de incendios y eventos
            meteorológicos, te facilitaremos una copia de la póliza de seguros,
            la cual quedará en tu poder.
          </Question>
          <Question title="¿Con quién lo aseguran?">
            En cada país es diferente. Siempre será con una compañía aseguradora
            legalmente establecida para operar en el país y de solvencia
            contrastada.
          </Question>
          <Question title="¿La garantía del seguro es nominativa?">
            No, el seguro es global a nombre de KiriCoin por cada inmueble o plantación de
            paulownias, con la excepción, de que el volumen de tu inversión sea
            aceptado por nuestra empresa aseguradora.
          </Question>

          {/*Sobre el arbol*/}

          <Question title="¿Qué diferencia hay entre paulownia y Kiri?">
            Ninguna, uno es el nombre botánico y el otro es el nombre común.
          </Question>
          <Question title="¿Cuál es el proceso de siembra y cosecha del árbol paulownia o Kiri?">
            <QuestionItem>
              Se analizan las zonas donde hay ofertas de tierras, en función de
              su clima (temperaturas máximas y mínimas, vientos y pluviometría).
            </QuestionItem>
            <QuestionItem>
              Se evalúa el subsuelo de cada finca (debe tener un porcentaje bajo
              de arcilla, un nivel freático por debajo de 3 metros de
              profundidad y un PH entre 5,5 y 8).
            </QuestionItem>
            <QuestionItem>
              Se evalúa la posibilidad de riego y el coste del agua.
            </QuestionItem>
            <QuestionItem>
              Se consulta cual es la unidad mínima de cultivo.
            </QuestionItem>
            <QuestionItem>
              Si todos los parámetros son convenientes para la siembra, se
              analiza cual es el hibrido de paulownia más adecuado. También cuantos meses al año la
              temperatura desciende de 12 grados Celsius o 54 grados Fahrenheit.
            </QuestionItem>
            <QuestionItem>
              Se parcela la plantación de paulownias en unidades de manejo
              diferenciadas en función de posibles divisiones a futuro, y se
              hace el proyecto de instalación del riego por goteo.
            </QuestionItem>
            <QuestionItem>
              Se encargan anualmente a un vivero de reproducción celular, el
              número de árboles más un 10% de reposición, como máximo en mayo, 
              para ser sembrados en la primavera siguiente.
            </QuestionItem>
            <QuestionItem>
              Se instala el sistema de riego por goteo, y se siembra al mismo
              tiempo, siempre en primavera, cuando ya no hay riesgo de heladas.{" "}
            </QuestionItem>
            <QuestionItem>
              Se podan los árboles al año de sembrados si es necesario, y se vuelven a podar
              cuando alcanzan la altura de tronco de 6 metros.
            </QuestionItem>
            <QuestionItem>
              Se mantiene la finca limpia de ramas y hierbas.
            </QuestionItem>
            <QuestionItem>
              La primera tala de árboles se hace a los ocho años y las
              siguientes seis talas (sin resembrar) se hace a los 6 o 7 años,
              dependiendo del tamaño del tronco solicitado por el comprador de
              la madera.{" "}
            </QuestionItem>
          </Question>
          <Question title="¿Puede el árbol paulownia ayudar a combatir el cambio climático?">
            <QuestionItem>
              Si por supuesto, ya que crece dos centímetros al día, y absorbe 20
              veces más CO2 que cualquier otra especie.
            </QuestionItem>
            <QuestionItem>
              Este es el árbol que puede ayudar a combatir el cambio climático.
            </QuestionItem>
            <QuestionItem>
              Este árbol tiene unas propiedades espectaculares que nos pueden
              ayudar a salvar el planeta.
            </QuestionItem>
            <QuestionItem>
              Proteger los bosques, es la mejor manera de proteger nuestro
              futuro. Pese a depender de ellos, la humanidad sigue ciega ante la
              alarmante situación del planeta. Se destruyen cerca de 13 millones
              de hectáreas forestales cada año en la tierra, según datos de la
              ONU. Pese a nuestra persistencia en luchar contra ellos, plantas y
              árboles siguen siendo los pulmones de la humanidad, y la única
              manera de mantenernos vivos. Y entre todos los árboles, hay uno
              que es más efectivo para ayudarnos a luchar contra el cambio
              climático, el árbol paulownia.
            </QuestionItem>
            <QuestionItem>
              El árbol paulownia, conocido como árbol emperatriz o kiri, es
              originario de China, puede llegar hasta los 27 m de altura, tiene
              unas hojas de 40 a 80 cm de ancho el primer año. 
              Cultivado o silvestre, crece a altitudes inferiores 1.800 metros.
            </QuestionItem>
          </Question>
          <Question title="¿Por qué el árbol paulownia puede salvar el planeta?">
            <QuestionItem>
              Sus características especiales, hacen del árbol paulownia un gran
              candidato para luchar contra el cambio climático, la contaminación
              y la deforestación, por su capacidad de purificar el suelo poco
              fértil, y su absorción de CO2 veinte veces mayor que cualquier otra
              especie.
            </QuestionItem>
            <QuestionItem>
              Pero no acaba aquí, emite grandes cantidades de oxígeno, y es el
              árbol que más rápido crece del planeta, en solo ocho años puede
              llegar a medir lo mismo que un roble de 40 años, o lo que es lo
              mismo, puede llegar a crecer 2 centímetros diarios en condiciones optimas de cultivo. Al regenerar
              sus raíces y sus vasos de crecimiento, además, resiste mucho mejor
              que otras especies a los incendios.
            </QuestionItem>
            <QuestionItem>
              Es capaz de rebrotar hasta siete veces después de ser cortado (4 veces si destina a la producción maderera).
              También crece en suelos y aguas contaminadas, y al hacerlo,
              purifica la tierra a partir de sus hojas ricas en nitrógeno que
              cuando caen al suelo se descomponen y aportando nutrientes, aunque
              en este tipo de suelos, su crecimiento es bastante más lento, pero
              con abono y algún sistema de riego, es capaz de crecer en suelos
              pobres, contaminados y erosionados.
            </QuestionItem>
          </Question>
          <Question title="¿Cuál es la historia del árbol paulownia o kiri?">
            Kiri significa ‘cortar’ en japonés. Su madera es muy preciada y se
            poda con frecuencia para favorecer su rápido crecimiento. Según las
            creencias chinas, este “árbol de la emperatriz” se tiene que plantar
            cuando nace una niña. Su alta velocidad de crecimiento acompañará a
            la niña, y cuando ella sea elegida en matrimonio, el árbol se
            cortará y se usará su madera para artículos de carpintería de su
            dote.
          </Question>
          <Question title="¿Qué características tiene el árbol paulownia o kiri?">
            <QuestionItem>
              Crecimiento ultrarrápido, de varios metros por año.
            </QuestionItem>
            <QuestionItem>Muy ornamental.</QuestionItem>
            <QuestionItem>Flores llamativas, muy melíferas.</QuestionItem>
            <QuestionItem>Gran tamaño, hasta 27 metros de altura.</QuestionItem>
            <QuestionItem>Buen cortavientos y árbol de sombra.</QuestionItem>
            <QuestionItem>Las hojas se utilizan como forraje.</QuestionItem>
            <QuestionItem>
              Gran aporte de materia orgánica al suelo, enriqueciéndolo.
            </QuestionItem>
            <QuestionItem>
              Apto para sistemas agroforestales. No es un árbol excluyente.
            </QuestionItem>
            <QuestionItem>Madera muy útil, de alta calidad.</QuestionItem>
            <QuestionItem>Se puede talar con 6 u 8 años.</QuestionItem>
            <QuestionItem>
              No es necesario replantar, rebrota de cepa.
            </QuestionItem>
            <QuestionItem>
              Cultivo muy sencillo, especie muy resistente.
            </QuestionItem>
            <QuestionItem>
              Aprovecha aguas depuradas, incluso residuales.
            </QuestionItem>
            <QuestionItem>
              Útil para regeneración de suelos contaminados y lucha contra la
              erosión.
            </QuestionItem>
            <QuestionItem>
              Apto para una amplia variedad de climas y de suelos.
            </QuestionItem>
            <QuestionItem>
              Una vez desarrollado su demanda de agua es significativamente menos que otros arboles.
            </QuestionItem>
          </Question>

          {/*Sobre la madera*/}

          <Question title="¿Qué harán con la madera?">
            El mercado es muy amplio, y la demanda es creciente, además
            actualmente se está incrementando muy considerablemente el uso de la
            madera en la construcción de viviendas, gracias a certificaciones
            como "passivhaus", siendo la madera de paulownia o kiri por sus
            características, la opción ideal (la casa passivhaus tiene un
            consumo muy bajo de energía para calefacción y refrigeración
            comparado con la normativa vigente actual en Alemania, gasta un 80%
            menos de energía para su acondicionamiento térmico). La venderemos a
            aserraderos, fabricantes de tableros contra enchapados, fabricantes
            de tableros CLT, fabricantes de tableros OSB, fabricantes de
            estructuras GLULAM, constructores de viviendas, fábricas de papel,
            centrales térmicas y peletizadoras de biomasa.
          </Question>
          <Question title="¿Qué características tiene la madera de paulownia o kiri?">
            <QuestionItem>
              Carpintería en general, construcción de muebles, armarios,
              puertas, ventanas, paneles, instrumentos musicales, juguetes,
              artesanía, construcción ligera, vigas decorativas, postes, cabañas
              de troncos, molduras, madera contrachapada, cajas, embalajes,
              paletas, molduras, marcos, etc.
            </QuestionItem>
            <QuestionItem>
              Por su resistencia y ligereza la madera de paulownia es muy apta y
              especialmente indicada para mobiliario y revestimiento interior de
              caravanas, aviones y embarcaciones ligeras.
            </QuestionItem>
            <QuestionItem>
              También para la obtención de pulpa de madera, fibra y papel.
            </QuestionItem>
            <QuestionItem>
              La madera, semipreciosa, de color muy claro, muy resistente y
              ultraligera, pesa muy poco y es muy apreciada, alcanzando muy
              buenos precios.
            </QuestionItem>
            <QuestionItem>
              Estableciendo una comparación con los metales se podría considerar
              a la madera de paulownia como el "aluminio" de las maderas de
              utilidad. Un metro cúbico pesa tan sólo unos 250 kg.
            </QuestionItem>
            <QuestionItem>
              De las más estables producidas intensivamente, no se tuerce, ni se
              deforma, ni se agrieta.
            </QuestionItem>
            <QuestionItem>
              La madera es fácil de trabajar, de grano fino y admite una gran
              variedad de acabados. Admite muy bien pinturas, barnices y
              adhesivos.
            </QuestionItem>
            <QuestionItem>De difícil combustión e hidrófuga.</QuestionItem>
            <QuestionItem>Textura y color parecido al fresno.</QuestionItem>
            <QuestionItem>
              Perfecta por sus fácil procesado para la elaboración de planchas y
              contrachapados.
            </QuestionItem>
            <QuestionItem>
              Gran capacidad aislante, del calor y/o frío.
            </QuestionItem>
            <QuestionItem>
              Excelente en la construcción como revestimiento y aislamiento,
              interior o exterior.
            </QuestionItem>
            <QuestionItem>
              Los árboles se pueden cosechar (talar) de seis a diez años para
              producción de madera y de uno o dos años para producción de
              postes.
            </QuestionItem>
            <QuestionItem>
              Fácil de descortezar (un 20% más sencillo que otros árboles).
            </QuestionItem>
            <QuestionItem>
              Tiempo de secado muy corto, 24 - 48 horas en hornos para madera y
              de 30 a 60 días al aire libre. Esto hace innecesario el empleo de
              hornos de secado. Lo cual es muy ventajoso.
            </QuestionItem>
            <QuestionItem>
              La calidad es algo variable, los clones o especies de paulownia de
              crecimiento lento tienen una madera más densa y un poco más
              resistente y pesada. También los árboles de climas más fríos
              crecen un poco más despacio y la madera es de una mayor calidad.
            </QuestionItem>
            <QuestionItem>
              Los clones seleccionados de crecimiento ultrarrápido son los de
              madera más ligera, pero al mismo tiempo son los que presentan una
              mejor forma y los troncos más rectos, largos y uniformes. Desde un
              punto de vista económico los clones de crecimiento rápido son más
              rentables.
            </QuestionItem>
          </Question>

          {/*Sobre medioambiente*/}

          <Question title="¿Por qué en algunos países ser responsables con el medioambiente, representa una carga al erario público y en otros países es un negocio?">
            Porque hay países que han explotado mal la madera, mientras otros
            hacen un buen trabajo de silvicultura tal como hace KiriCoin.
          </Question>
          <Question title="¿Por qué silvicultura pastoril?">
            Una buena poda, y riego con nutrientes, nos ayuda a obtener una
            madera de calidad, sin nudos, de tronco recto y del tamaño rentable
            para la explotación maderera. Y la presencia de animales de pastoreo
            en muy baja densidad, deshierban las tierras, al mismo tiempo que
            incorporan abono orgánico.
          </Question>

          {/*Sobre geolocalizacion, visita y propiedad*/}

          <Question title="¿En caso de ser propietario de una parte de tierra, puedo geo localizarla?">
            Si, te facilitaremos un documento de propiedad con las coordenadas y
            si lo deseas y las leyes país lo permitan (por ser divisible en
            unidades mínimas de cultivo) puedes registrarla en el registro de la
            propiedad que te corresponda.
          </Question>
          <Question title="¿En caso de ser propietarios solo de los árboles, puedo saber cuáles son y dónde están?">
            Si, tendrás las coordenadas de cada árbol.
          </Question>
          <Question title="¿Los puedo visitar?">
            Si, es tu propiedad, nadie te puede limitar el acceso.
          </Question>
          <Question title="¿Si es de mi propiedad, tendré algún documento, titulo, certificado, escritura o algo que lo demuestre?">
            Si, además tendrás acceso a un registro digital.
          </Question>
          <Question title="¿Seré propietario de algo?">
            Si, de acuerdo a tu plan de inversión iras recibiendo los títulos de
            propiedad.
          </Question>
          <Question title="¿La parte proporcional de lo que sea mío, lo puedo vender?">
            Si.
          </Question>

          {/*Sobre incorporarse al proyecto*/}

         
          <Question title="¿Puedo incorporarme al proyecto aportando un terreno urbano?">
            Si, envíanos la ubicación del terreno, siempre y cuando
            cumpla con todos los requisitos siguientes:
            <QuestionItem>
              Debe estar ubicado cerca de una ciudad mayor de 100.000 habitantes, a una distancia de 10 a 60 km. y alejado de zonas industriales.
            </QuestionItem>
            <QuestionItem>
              Y{" "}
              <Link to="/home/contact/finca-ganadera">
                contacta con KiriCoin.
              </Link>
            </QuestionItem>
          </Question>
          <Question title="¿Puedo incorporarme al proyecto aportando una finca ganadera?">
            Si, envíanos la ubicación y tamaño de la finca, siempre y cuando
            cumpla con todos los siguientes requisitos:
            <QuestionItem>
              Temperatura en invierno no inferior a -20 grados Celsius o -4
              grados Fahrenheit.
            </QuestionItem>
            <QuestionItem>
              El nivel freático debe ser inferior a 3 metros.
            </QuestionItem>
            <QuestionItem>
              Suelo con drenaje profundo (no rocoso, ni arcilloso).
            </QuestionItem>
            <QuestionItem>PH entre 5,5 y 8.</QuestionItem>
            <QuestionItem>Áreas libres de huracanes.</QuestionItem>
            <QuestionItem>Acceso a agua para riego.</QuestionItem>
            <QuestionItem>
              Y{" "}
              <Link to="/home/contact/finca-ganadera">
                contacta con KiriCoin.
              </Link>
            </QuestionItem>
          </Question>
          <Question title="¿Puedo incorporarme al proyecto aportando o vendiendo una finca agrícola con ganado, maquinaria y todo lo que comporta?">
            Si, envíanos la ubicación, tamaño de la finca, siempre y cuando
            cumpla con todos los requisitos siguientes:
            <QuestionItem>
              Temperatura en invierno no inferior a -20 grados Celsius o -4
              grados Fahrenheit.
            </QuestionItem>
            <QuestionItem>
              El nivel freático debe ser inferior a 3 metros.
            </QuestionItem>
            <QuestionItem>
              Suelo con drenaje profundo (no rocoso, ni arcilloso).
            </QuestionItem>
            <QuestionItem>PH entre 5,5 y 8.</QuestionItem>
            <QuestionItem>Áreas libres de huracanes.</QuestionItem>
            <QuestionItem>Acceso a agua para riego.</QuestionItem>
            <QuestionItem>
              Y también debes aportarnos la información siguiente:
            </QuestionItem>
            <QuestionItem>Tipo de maquinaria y equipos.</QuestionItem>
            <QuestionItem>Marcas.</QuestionItem>
            <QuestionItem>Modelos.</QuestionItem>
            <QuestionItem>Años.</QuestionItem>
            <QuestionItem>Estado de mantenimiento.</QuestionItem>
            <QuestionItem>Lista de ganado.</QuestionItem>
            <QuestionItem>Descripción de las edificaciones.</QuestionItem>
            <QuestionItem>Otros.</QuestionItem>
            <QuestionItem>
              Y también debes aportarnos tu CV.{" "}
              <Link to="/home/contact/finca-ganadera">
                Contacta con KiriCoin.
              </Link>
            </QuestionItem>
          </Question>
          <Question title="¿Puedo incorporarme al proyecto aportando la finca y ser un operador del proyecto?">
            Si, envíanos la ubicación, tamaño de la finca, siempre y cuando
            cumpla con todos los siguientes requisitos:
            <QuestionItem>
              Temperatura en invierno no inferior a -20 grados Celsius o -4
              grados Fahrenheit.
            </QuestionItem>
            <QuestionItem>
              El nivel freático debe ser inferior a 3 metros.
            </QuestionItem>
            <QuestionItem>
              Suelo con drenaje profundo (no rocoso, ni arcilloso).
            </QuestionItem>
            <QuestionItem>PH entre 5,5 y 8.</QuestionItem>
            <QuestionItem>Áreas libres de huracanes.</QuestionItem>
            <QuestionItem>Acceso a agua para riego.</QuestionItem>
            <QuestionItem>
              Y también debes aportarnos la información siguiente:
            </QuestionItem>
            <QuestionItem>Tipo de maquinaria y equipos.</QuestionItem>
            <QuestionItem>Marcas.</QuestionItem>
            <QuestionItem>Modelos.</QuestionItem>
            <QuestionItem>Años.</QuestionItem>
            <QuestionItem>Estado de mantenimiento.</QuestionItem>
            <QuestionItem>Lista de ganado.</QuestionItem>
            <QuestionItem>Descripción de las edificaciones.</QuestionItem>
            <QuestionItem>Otros.</QuestionItem>
            <QuestionItem>
              Y también debes aportarnos tu CV.{" "}
              <Link to="/home/contact/finca-ganadera">
                Contacta con KiriCoin.
              </Link>
            </QuestionItem>
          </Question>
          <Question title="¿Puedo incorporarme al proyecto trabajando en una plantación de árboles paulownia?">
            <QuestionItem>
              Si, envíanos tu CV.{" "}
              <Link to="/home/contact/finca-ganadera">
                Contacta con KiriCoin.
              </Link>
            </QuestionItem>
            <Question title="¿Puedo incorporarme al proyecto trabajando desde casa?"></Question>
            <QuestionItem>
              Si, envíanos tu CV.{" "}
              <Link to="/home/contact/finca-ganadera">
                Contacta con KiriCoin.
              </Link>
            </QuestionItem>
            <Question title="¿Puedo aportar maquinaria, ya sea cediéndola o trabajando con ella?"></Question>
            <QuestionItem>
              Si, envíanos tu CV.{" "}
              <Link to="/home/contact/finca-ganadera">
                Contacta con KiriCoin.
              </Link>
            </QuestionItem>
            <QuestionItem>
              Y también debes aportarnos la información siguiente:
            </QuestionItem>
            <QuestionItem>Tipo de maquinaria y equipos.</QuestionItem>
            <QuestionItem>Marcas.</QuestionItem>
            <QuestionItem>Modelos.</QuestionItem>
            <QuestionItem>Años.</QuestionItem>
            <QuestionItem>Estado de mantenimiento.</QuestionItem>
            <QuestionItem>Lista de ganado.</QuestionItem>
            <QuestionItem>Descripción de las edificaciones.</QuestionItem>
            <QuestionItem>Otros.</QuestionItem>
          </Question>
          <Question title="¿Puedo incorporar una finca con plantación de árboles?">
            Si, si son arboles paulownia, en el caso de tratarse de otro árbol
            maderero solo si están listos para talar, en ese caso, envíanos la
            ubicación y tamaño de la finca, siempre y cuando cumpla con todos
            los requisitos adjuntos, y{" "}
            <Link to="/home/contact/finca-ganadera">
              contacta con KiriCoin.
            </Link>
            <QuestionItem>
              Temperatura en invierno no inferior a -20 grados Celsius o -4
              grados Fahrenheit.
            </QuestionItem>
            <QuestionItem>
              El nivel freático debe ser inferior a 3 metros.
            </QuestionItem>
            <QuestionItem>
              Suelo con drenaje profundo (no rocoso, ni arcilloso).
            </QuestionItem>
            <QuestionItem>PH entre 5,5 y 8.</QuestionItem>
            <QuestionItem>Áreas libres de huracanes.</QuestionItem>
            <QuestionItem>Acceso a agua para riego.</QuestionItem>
          </Question>

          {/*Sobre biomasa*/}

          <Question title="¿Por qué es mejor generar energía con biomasa, que con gas o carbón?">
            Porque la biomasa es un recurso renovable y que tiene un balance
            positivo de CO2, ya que mientras crece el árbol, absorbe más CO2,
            que el que genera cuando se quema. El gas y el carbón, no son
            renovables, ni absorben CO2.
          </Question>

          {/*Sobre mineros*/}

          <Question title="Soy estudiante, ¿Puedo incorporarme al proyecto?">
            Si, sobre todo si estudias en las áreas relacionadas con KiriCoin,
            igualmente si eres defensor del medioambiente, estas integrado en el
            sector agrícola, forestal o ganadero, envíanos tu CV,{" "}
            <Link to="/home/contact/finca-agricola">
              contacta con KiriCoin.
            </Link>
            .
          </Question>
          <Question title="¿Vivo en una zona rural y trabajo en el sector agrícola, puedo incorporarme a KiriCoin?">
            Si, envíanos tu CV,
            <Link to="/home/contact/finca-agricola">
              {" "}
              contacta con KiriCoin.
            </Link>
          </Question>

          {/*Sobre invertir*/}

          <Question title="¿Cómo puedo invertir en KiriCoin?">
            <QuestionItem>
              Con monedas fiduciarias: Dólar Americano, Euro, Libra Esterlina,
              Yen, Franco Suizo, Dólar Canadiense, Dólar Australiano, Dólar
              Neozelandés, Turquís Lira, Peso Mexicano, Rand Sudafricano, Yuan y
              todas las monedas que tengan cotización en Forex.
            </QuestionItem>
            <QuestionItem>
              Con criptomonedas: Bitcoin, Ether, Litecoin, Primecoin, Ripple,
              Dogecoin, Dash, KiriCoin.
            </QuestionItem>
            <QuestionItem>Con tu trabajo.</QuestionItem>
            <QuestionItem>
              Con tierras para establecer nuevas plantaciones de paulownias (en
              venta, alquiler o asociación).
            </QuestionItem>
            <QuestionItem>
              Con plantaciones de paulownias existentes.
            </QuestionItem>
            <QuestionItem>Con maquinaria agrícola.</QuestionItem>
            <QuestionItem>Con granjas.</QuestionItem>
          </Question>
          <Question title="¿Si invierto en árboles soy también propietario de la tierra?">
            En algunas plantaciones los terrenos son alquiladas, en otros son
            concesiones de la administración pública y en otros son de
            propiedad, en este último caso puedes solicitar que se te transmita
            la propiedad individual, si cumple los requisitos de “unidad mínima
            de cultivo” o en su defecto podrás tener una proporción indivisa de
            la propiedad.
          </Question>
          <Question title="¿Si compro árboles con las tierras, puedo tener una casa?">
            Si, está condicionado a la legislación del lugar escogido, ya que
            normalmente se regula el uso del suelo.
          </Question>
          <Question title="¿Cuándo se obtengan bonos de carbono, me beneficia también a mí?">
            Si, ese ingreso te lo transmitimos íntegramente a ti, ya que es un
            premio complementario a tu plan de ahorro escogido.
          </Question>
          <Question title="¿Si se reciben fondos públicos o subvenciones, me beneficio yo?">
            Si, y especialmente se benefician tus descendientes, ya que esos
            fondos serán reinvertidos para nuevas plantaciones, logrando con
            ello un planeta menos contaminado y una mejor calidad de vida para
            todos.
          </Question>
          <Question title="¿Por qué KiriCoin no tiene volatilidad?">
            Porque invierte es bienes tangibles de consumo masivo, que
            históricamente tiene precios estables.
          </Question>
          <Question title="¿Quién puede invertir en KiriCoin?">
            Cualquier persona física o jurídica, que tenga capacidad de hacer y
            recibir pagos electrónicos (tarjeta crédito / débito, transferencia
            bancaria, criptomonedas, PayPal o Sofort).
          </Question>
          <Question title="¿Tiene costes enviar mi dinero para que lo gestione KiriCoin?">
            No cobramos por ello, aunque según la entidad financiera que
            utilices, en algunas ocasiones puede hacerte un cargo.
          </Question>
          <Question title="¿Cuál es el mínimo para invertir en KiriCoin?">
            No hay mínimos, ni máximos.
          </Question>
          <Question title="¿En qué idiomas me puedo relacionar con KiriCoin?">
            En tu idioma natal o inglés.
          </Question>
          <Question title="¿Cómo invertir en KiriCoin?">
            Sigue los pasos que te indica la{" "}
            <Link to="/home/calculadoras">calculadora KiriCoin.</Link>
          </Question>
        </div>
      </div>
    );
  }
}

export default PreguntasRespuestasScreen;
