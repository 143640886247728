import BackendApi from '../backend-api';

export const GET_CUSTOMER_CONTRACT_SEARCH = 'GET_CUSTOMER_CONTRACT_SEARCH';
export const GET_CUSTOMER_PAYMENT_SEARCH = 'GET_CUSTOMER_PAYMENT_SEARCH';
export const GET_CUSTOMER_INCOME_SEARCH = 'GET_CUSTOMER_INCOME_SEARCH';
export const GET_CUSTOMER_CONTRACT_DETAILS = 'GET_CUSTOMER_CONTRACT_DETAILS';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const GET_CUSTOMER_AGENT_ASSIGNMENT = 'GET_CUSTOMER_AGENT_ASSIGNMENT';
export const GET_CUSTOMER_DOCUMENT_SEARCH = 'GET_CUSTOMER_DOCUMENT_SEARCH';

export const postCustomerContractSearch = (customerId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + customerId + "/contracts/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_CUSTOMER_CONTRACT_SEARCH, data: response.data });
  };
};

export const postCustomerPaymentSearch = (customerId, contractId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + customerId + "/payments/search", {
        filters: filters
      }, {
      params: {
        contractId: contractId,
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_CUSTOMER_PAYMENT_SEARCH, data: response.data });
  };
};

export const postCustomerIncomesSearch = (customerId, contractId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + customerId + "/incomes/search", {
        filters: filters
      }, {
      params: {
        contractId: contractId,
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_CUSTOMER_INCOME_SEARCH, data: response.data });
  };
};

export const postCustomerDocumentsSearch = (customerId, contractId, filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/customers/" + customerId + "/documents/search", {
        filters: filters
      }, {
      params: {
        contractId: contractId,
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: GET_CUSTOMER_DOCUMENT_SEARCH, data: response.data });
  };
};

export const getCustomerContractDetails = (customerId, contractId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/customers/" + customerId + "/contracts/" + contractId);
    return dispatch({ type: GET_CUSTOMER_CONTRACT_DETAILS, data: response.data });
  };
};

export const getCustomerDetails = (customerId) => {
  return async (dispatch) => {
    let response = await BackendApi.get("/api/customers/" + customerId + "/details");
    return dispatch({ type: GET_CUSTOMER_DETAILS, data: response.data });
  };
};

export const getCustomerAgentAssignment = (customerId) => {
  return async (dispatch) => {
    let response = await BackendApi.get(`/api/customers/${customerId}/agent-assignment`);
    dispatch({ type: GET_CUSTOMER_AGENT_ASSIGNMENT, data: response.data });
    return response.data;
  };
};

export const postCustomerDocument = (customerId, contractId, form, file) => {
  return async (dispatch) => {
    const formData = new FormData(); 

    formData.append("form", new Blob([JSON.stringify(form)], {
      type: "application/json"
    }));
    formData.append("file", file);
  
    await BackendApi.post("/api/customers/" + customerId + "/contracts/" + contractId + "/documents/create", formData);
    return dispatch({ type: "NOP" });
  };
};

export const postCustomerDocumentsArchive = (customerId, contractId, documentId) => {
  return async (dispatch) => {
    await BackendApi.post("/api/customers/" + customerId  + "/contracts/" + contractId + "/documents/" + documentId + "/archive");
    return dispatch({ type: "NOP" });
  };
};

export const postCustomerDocumentsRecover = (customerId, contractId, documentId) => {
  return async (dispatch) => {
    await BackendApi.post("/api/customers/" + customerId  + "/contracts/" + contractId + "/documents/" + documentId + "/recover");
    return dispatch({ type: "NOP" });
  };
};