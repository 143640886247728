import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, stopAsyncValidation } from 'redux-form';
import { _t, ErrorManagement, AfeFields, LOADSTATE, SUBMITSTATE } from "../../nclab-react-core";
import * as Actions from "../../api/actions"; 
import store from "../../api/store"; 
class ContratarScreen extends Component {
  state = {
    loadState: LOADSTATE.LOADING,
    submitState: SUBMITSTATE.NONE
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.onLoad();
  }

  onLoad = async() => {
    try {
      this.setState({ loadState: LOADSTATE.LOADING });

      await store.dispatch(Actions.getUserPaymentPreferences());
   
      this.setState({ loadState: LOADSTATE.LOADED_OK });
    } catch (error) {
      this.setState({ loadState: LOADSTATE.LOADED_KO });
      store.dispatch(stopAsyncValidation(ContratarScreen.name, { _error: ErrorManagement.getMessage(error) }));
    }
  }

  getNombreProducto(productoId) {
    switch (productoId) {
      case 'jubilacion': return "Jubilación digna";
      case 'fideicomiso': return "Fideicomiso";
      case 'padrinazgo': return "Padrinazgo";
      case 'cerocupones': return "Cerocupon";
      case 'emprendedor': return "Emprendedor";
      case 'sostenibilidad': return "Sostenibilidad";
      case 'personalizado': return "Personalizado";
      default: return "Nuevo";
    } 
  }

  onRetry = async () => {
    await this.onLoad();
  }

  onSubmit = async (formValues) => {
    try {
      this.setState({ submitState: SUBMITSTATE.SUBMITTING });

      const data = { ...this.props.productoInversion, ...formValues };

      await store.dispatch(Actions.contratar(data));

      this.props.history.push('/inversor/dashboard');

      this.setState({ submitState: SUBMITSTATE.SUBMITTED_OK });
    } catch (error) {
      this.setState({ submitState: SUBMITSTATE.SUBMITTED_KO });   
      throw new SubmissionError({ _error: ErrorManagement.getMessage(error) });
    }
  }

  render() {

    const { handleSubmit, submitting=false, error, productoInversion } = this.props;
    let formContent = null;

    if (this.state.loadState === LOADSTATE.LOADING)
      formContent = (<>
        <div className="h3 card-title">Cargando</div>
        <p>Por favor espere...</p>
      </>);

    if (this.state.loadState === LOADSTATE.LOADED_KO)
      formContent = (<>
        <div className="h3 card-title">Error en la operación</div>
          {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}
          <button type="button" className="btn btn-secondary font-default-normal" 
                onClick={ () => this.onRetry() } >Reintentar</button>
      </>);

    if (this.state.loadState === LOADSTATE.LOADED_OK && !productoInversion.calcId)
      formContent = (<>
        <div className="h3 card-title">Debe de seleccionar un producto</div>
        <div className="h4 pt-2">
          <Link to="/home/calculadoras" className="btn btn-primary">Consultar nuestros productos</Link>
        </div>
      </>);

    else if (this.state.loadState === LOADSTATE.LOADED_OK)
      formContent = (<>
        <div className="h3 card-title">Contratación de producto</div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          {error && <div className="alert alert-danger mt-5" role="alert">{error}</div>}

          <div className="h2">{this.getNombreProducto(productoInversion.calcId)}</div>

          <table className="table table-striped">
            <tbody>
              <tr>
                <td>Aporte inicial</td>
                <td>{productoInversion.capitalInicial}</td>
              </tr>
              <tr>
                <td>Aporte mensual</td>
                <td>{productoInversion.aportacionMensual}</td>
              </tr>
              <tr>
                <td>Fecha de inicio del contrato</td>
                <td>{productoInversion.fechaInicio && productoInversion.fechaInicio.toLocaleDateString('es-ES')}</td>
              </tr>
              <tr>
                <td>Fecha de finalización del contrato</td>
                <td>{productoInversion.fechaFin && productoInversion.fechaFin.toLocaleDateString('es-ES')}</td>
              </tr>
              <tr>
                <td>Forma de pago</td>
                <td>
                  <Field name="formaDePago" type="text" component={AfeFields.Select} >
                    <option value=""></option>
                    {this.props.userPaymentPreferences != null && this.props.userPaymentPreferences.map(
                    data => <option key={data.id} value={data.id}> {data.id} {data.creditCard}</option>)}
                  </Field>
                </td>
              </tr>
            </tbody>
          </table>
          <button type="submit" className="ml-2 btn btn-primary font-default-normal" disabled={submitting}>Contratar</button>

        </form>
      </>);
      
    return (
      <div className="site-section">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {formContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

const validate = values => {
  const errors = {};

  if (!values.formaDePago)
    errors.formaDePago = _t("errors.required");

  return errors;
}

const warn = values => {
  const warnings = {}
  return warnings;
}

const form = reduxForm({
  form: ContratarScreen.name, // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn // <--- warning function given to redux-form
})(ContratarScreen);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: null,
    productoInversion: state.productoInversion,
    userPaymentPreferences: state.userPaymentPreferences,
    enableReinitialize: true
  };
};

export default connect(mapStateToProps, {})(form);
