import axios from "axios";

export default axios.create({
    baseURL: global.window === undefined || global.window.location.hostname.endsWith('thenclab.com') || global.window.location.hostname.endsWith('localhost')? 
        "http://localhost:1001/authorization-server" : 
        "https://www.abigblue.org/authorization-server",
    headers: {
        'company-id': 'abigblue',
        "Accept-Language": "es"
    }
});
