import React, { Component } from "react";

class CookiesScreen extends Component {
  state = {};
  render() {
    return (
      <div className="site-section">
        <div className="container col-md-12">
          <div className="col-md-12">
            <h1>Política de Cookies</h1>
          </div>
          <div className="col-md-12">
            <p>
              Esta página web utiliza cookies. Si continuas trabajando con la
              configuración actual se entiende que consientes su uso, de todas
              formas siempre puedes cambiar la configuración de las cookies en
              el siguiente enlace. Continuar 
            </p>

            <h4>¿Qué son las cookies?</h4>
            <p>
              Una cookie es un pequeño fichero de información que se almacena en
              el disco duro del ordenador y que registra sus datos de navegación
              de un sitio web, de manera que, cuando lo visite de nuevo, pueda
              ofrecerle opciones personalizadas a partir de la información
              almacenada sobre usted en la última visita. Asimismo, se pueden
              utilizar para analizar el tráfico y con fines publicitarios y de
              marketing.
            </p>
            <p>
              Casi todos los sitios web las utilizan y no son dañinas para su
              sistema. Por lo general, si desea comprobar o modificar el tipo de
              cookies que acepta, puede hacerlo desde la configuración de su
              navegador. Puede consultar más información sobre esta cuestión en
              nuestra página dedicada a las preferencias de marketing.
            </p>
            <h4>¿Cómo utilizamos las cookies?</h4>
            <p>Utilizamos las cookies con dos propósitos:</p>
            <ul>
              <li>
                realizar un seguimiento de cómo usa nuestro sitio web, lo que
                nos permite conocer cómo lo utiliza y estudiar las pautas que se
                aprecian, ya sea a título individual o en grupos más grandes. A
                su vez, eso nos permite diseñar y mejorar nuestro sitio web y
                nuestros servicios en respuesta a lo que quiere y necesita el
                usuario.
              </li>

              <li>
                seleccionar las ofertas de trabajo que creemos que le pueden
                interesar. Con suerte, deberá dedicar menos tiempo a bucear en
                un sinfín de páginas y encontrará antes ese trabajo que tanto
                anhela.
              </li>
            </ul>
            <p>Las cookies pueden ser:</p>
            <ul>
              <li>
                Cookies de sesión: solamente se almacenan en el ordenador
                durante la sesión web y se eliminan automáticamente al cerrar el
                navegador. Por lo general, almacenan una ID anónima de sesión
                que le permite navegar a través de una página web sin tener que
                registrarse en cada página, pero no recopilan información del
                ordenador.
              </li>

              <li>
                Cookies permanentes: se almacenan como un fichero en el
                ordenador, en el que permanece después de cerrar su navegador.
                El sitio web que la ha creado puede leerla en su próxima visita.
                Las utilizamos para Google Analytics y con fines de
                personalización (véase más adelante).
              </li>
            </ul>
            <p>También se pueden dividir en las siguientes categorías:</p>
            <ul>
              <li>
                Cookies estrictamente necesarias: Estas cookies son
                fundamentales para permitirle utilizar el sitio eficazmente,
                como para presentarse a una oferta de trabajo y, por tanto, no
                pueden desactivarse. Sin ellas, no podemos prestarle los
                servicios que ofrecemos a través de nuestro sitio web. No
                recogen información sobre el interesado que pueda utilizarse con
                fines de marketing o para recordar sus visitas de Internet.
              </li>

              <li>
                Cookies de rendimiento: Estas cookies nos permiten controlar y
                mejorar los resultados de nuestro sitio. Por ejemplo, gracias a
                ellas podemos contar las visitas, identificar las fuentes de
                tráfico y ver qué partes del sitio despiertan más interés.
              </li>

              <li>
                Cookies de funcionalidad: Estas cookies permiten a nuestro sitio
                web recordar sus preferencias (como el nombre de usuario, el
                idioma o la región) y ofrecerle funciones avanzadas. Por
                ejemplo, nos permiten presentarle noticias o actualizaciones
                relacionadas con los servicios que utilice. Asimismo, pueden
                utilizarse para recordar los cambios que haya realizado en el
                tamaño y la fuente del texto, así como en otras partes
                personalizables de la página web. También permiten prestar los
                servicios que solicite, tales como ver un vídeo o comentar un
                blog. La información que se recoge con estas cookies suele
                despojarse de elementos identificativos.
              </li>

              <li>
                Cookies de personalización: Estas cookies nos permiten publicar
                los datos de las ofertas laborales que consideramos
                interesantes. Se trata de cookies permanentes (mientras siga
                dado de alta); esto significa que, cuando inicie sesión o
                regrese al sitio web, podrá ver ofertas laborales similares a
                las que haya consultado previamente.
              </li>
            </ul>

            <h4>LISTA DE COOKIES</h4>

            <table class="table">
              <thead>
                <tr>
                  <th>Nombre de la cookie</th>
                  <th>Finalidad</th>
                  <th>Datos recogidos</th>
                  <th>Intercambio de datos</th>
                  <th>Duración de la cookie</th>
                  <th>Política de privacidad del proveedor de cookies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Analytics</td>
                  <td>
                    Google Analytics es un servicio de análisis web prestado por
                    Google, Inc. que utiliza cookies para ayudarnos a analizar
                    de qué manera utilizan los usuarios nuestro sitio. Google
                    utiliza esta información para evaluar cómo utiliza el sitio
                    web, elaborar informes sobre la actividad del sitio
                    destinados a nosotros y prestarnos otros servicios en
                    relación con la actividad del sitio web y el uso de
                    Internet.
                  </td>
                  <td>
                    Anónimos: Visualización de anuncios, análisis, información
                    del navegador, datos de cookies, fecha/hora, datos
                    demográficos, tipo de hardware/software, proveedor de
                    servicios de Internet, datos de interacción, visitas a la
                    página, dominios de entrega. Datos personales: Dirección de
                    IP, historial de búsquedas, datos basados en la ubicación,
                    ID de dispositivo, nombre, dirección, número de teléfono,
                    dirección de correo electrónico, credenciales de acceso,
                    dirección de IP, ID de dispositivo único.
                  </td>
                  <td>
                    Los datos agregados se comparten con terceros. Los datos
                    anónimos se comparten con terceros. Los datos personales se
                    comparten con terceros.
                  </td>
                  <td>
                    Esta cookie permanente expira a los dos años. Con cada nueva
                    visita, el plazo comienza de nuevo
                  </td>
                  <td>
                    <a href="http://www.google.com/intl/en/policies/privacy/">
                      http://www.google.com/intl/en/policies/privacy/
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>Google Tag Manager</td>
                  <td>
                    Esta cookie se asocia a aquellos sitios que utilizan Google
                    Tag Manager para cargar otros scripts y códigos a las
                    páginas. Nos ayuda, además, a clasificar la información de
                    nuestro sitio web y a etiquetarla, de manera que sea más
                    fácil encontrarla en búsquedas futuras.
                  </td>
                  <td>
                    Anónimos: Visualización de anuncios, análisis, información
                    del navegador, datos de cookies, fecha/hora, datos
                    demográficos, tipo de hardware/software, proveedor de
                    servicios de Internet, datos de interacción, visitas a la
                    página, dominios de entrega. Datos personales: Dirección de
                    IP, historial de búsquedas, datos basados en la ubicación,
                    ID de dispositivo, nombre, dirección, número de teléfono,
                    dirección de correo electrónico, credenciales de acceso,
                    dirección de IP, ID de dispositivo único.
                  </td>
                  <td>
                    Los datos agregados se comparten con terceros. Los datos
                    anónimos se comparten con terceros. Los datos personales se
                    comparten con terceros.
                  </td>
                  <td>Duración de la sesión de navegación.</td>
                  <td>
                    <a href="http://www.google.com/intl/en/policies/privacy/">
                      http://www.google.com/intl/en/policies/privacy/
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default CookiesScreen;
