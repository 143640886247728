import React, { Component, Fragment } from "react";

class SiteSection extends Component {
  state = {};
  render() {
    const { title, image, layout } = this.props;

    return (
      <Fragment>
        <div className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pb-1">
                <div className="h2 text-success">{title}</div>
              </div>
            </div>
            <div className="row">
              {layout === 'left' ? (
                <Fragment>
                  <div className="d-flex align-items-stretch">
                    <div className="col-md-8">
                      <img src={image} alt="left" className="img-fluid" />
                    </div>
                    <div className="col-md-4">{this.props.children}</div>
                  </div>
                </Fragment>
              ) : layout === 'right' ? (
                <Fragment>
                  <div className="d-flex align-items-stretch">
                    <div className="col-md-4">{this.props.children}</div>
                    <div className="col-md-8">
                      <img src={image} alt="right" className="img-fluid" />
                    </div>
                  </div>
                </Fragment>
              ) : layout === 'text-left' ?  (
                <Fragment>
                  <div className="col-md-12">
                      <img src={image} alt="text left" 
                        className="img-fluid float-left float-sm-right float-md-left float-lg-right float-xl-left pr-2">
                      </img>
                    {this.props.children}
                  </div>
                </Fragment>
              ) : layout === 'text-right' ? (
                <Fragment>
                  <div className="col-md-12">
                      <img src={image} alt="text right" 
                        className="img-fluid float-right float-sm-left float-md-right float-lg-left float-xl-right pl-2">
                      </img>
                    {this.props.children}
                  </div>
                </Fragment>
              ) :  (
                <Fragment>
                  <div className="col-md-12">
                    {this.props.children}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SiteSection;
