import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class CalculadoraCard extends Component {
  render() {
    const { title, children, link, image } = this.props;

    return (
      <Fragment>
        <div className="col-md-4">
          <div className="card my-4 flex-fill" style={{minHeight: 760}}>
            <div className="card-header border-0">
              <img src={process.env.PUBLIC_URL + "/assets/calculadoras/" + image} alt="" />
            </div>
            <div className="card-block m-4">
              <h4 className="card-title">{title}</h4>
              <p className="card-text">{children}</p>
              <Link className="btn btn-primary" to={link}>
                Calculadora
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

class CalculadorasScreen extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="site-section">
        <div className="container">
          {/*Sobre planes de ahorro*/}
          <div className="d-inline-flex flex-wrap">
            <CalculadoraCard
              title="Jubilación digna"
              link="/home/calculadoras/jubilacion"
              image="calculadora-2.jpg"
            >
              Diseña anticipadamente cuál es la jubilación que quieres tener, piensa en la posibilidad de hacer realidad algunos sueños que quedaron pendientes en tu juventud, calcula los distintos gastos de transporte, residencia, alimentación, vacaciones y otros gastos; no olvides los imprevistos en salud, ten en cuenta que puedes tener costes adicionales para resolver los dos principales problemas en la tercera edad (soledad y necesidad de asistencia) y especialmente recuerda que la inflación puede demoler tus planes, y una vez tengas la cifra final, entra en la calculadora KiriCoin y podrás escoger la opción, que más se adapte a tus deseos.
            </CalculadoraCard>
            <CalculadoraCard
              title="Ahorro en fidecomiso para la Universidad"
              link="/home/calculadoras/fideicomiso"
              image="calculadora-3.jpg"
            >
              Escoge primero donde te gustaría que estudiara, calcula los distintos gastos de transporte, residencia, alimentación, universidad, vacaciones y otros gastos; no olvides hacer una previsión de la inflación y una vez tengas la cifra final, entra en la calculadora KiriCoin, y podrás escoger la opción, que más se adapte a tus deseos y posibilidades.
            </CalculadoraCard>
            <CalculadoraCard
              title="Plan de padrinazgo"
              link="/home/calculadoras/padrinazgo"
              image="calculadora-4.jpg"
            >
              Si te hacen padrino de un recién nacido, regálale algo que sirva para “asegurar su futuro”, haz una sola inversión en KiriCoin y con la renta fija acumulada, veras lo que puede recibir a la edad que tu decidas y para el uso que indiques. Define que cantidad quieres aportar, y analiza las opciones que te ofrece KiriCoin.
            </CalculadoraCard>
            <CalculadoraCard
              title="Plan cero cupones"
              link="/home/calculadoras/cerocupones"
              image="calculadora-6.jpg"
            >
              Si tu proyecto de vida, pasa por disponer de una cantidad de dinero, puedes lograrlo con KiriCoin. Define cual es el monto que quieres lograr, la cantidad que puedes aportar, y/o el ahorro mensual que puedes hacer, así sabrás que tiempo es necesario para lograr el resultado deseado.
            </CalculadoraCard>
            <CalculadoraCard
              title="Plan emprendedor"
              link="/home/calculadoras/emprendedor"
              image="calculadora-7.jpg"
            >
              Define el proyecto que quieres emprender, haz un plan de empresa bien elaborado (recuerda que 9 de cada 10 emprendimientos cierran antes de cumplir un año), y solo aquellos que han tenido un buen proceso de maduración, el conocimiento suficiente, y los recursos para cubrir los costos de apertura + un tiempo prudente de déficit de inicio de actividad, logran sobrevivir. No te precipites en arrancar, prepárate y espera a tener ahorrada la cantidad necesaria para lograr los objetivos propuestos.
            </CalculadoraCard>
            <CalculadoraCard
              title="Plan compromiso sostenibilidad"
              link="/home/calculadoras/sostenibilidad"
              image="calculadora-5.jpg"
            >
              En el año 2020, en el mundo se producirán 40.000.000.000 de toneladas de CO2. Somos 7.625.000.000 habitantes, lo que significa que en promedio generamos 5,25 toneladas/habitante/año. Un kiri captura cuando es adulto 21,7 kg de CO2 diario y en promedio a lo largo de su ciclo de vida (cada 7 años) absorbe anualmente unos 275 kg/año, por lo que es necesario sembrar 19 árboles paulownia por cada miembro de la familia, y con ello se logra una emisión neutra de CO2.
            </CalculadoraCard>
            <CalculadoraCard
              title="Plan de ahorro personalizado"
              link="/home/calculadoras/personalizado"
              image="calculadora-1.jpg"
            >
              Entra en la calculadora de plan de ahorro y podrás comprobar
              las distintas opciones, escogiendo la que más se adapte a tus
              deseos y posibilidades. Además, puedes solicitar un plan
              personalizado de acuerdo a tus deseos.
            </CalculadoraCard>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculadorasScreen;
