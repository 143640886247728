import React, { Component } from "react";

class Account extends Component {
  state = {};
  render() {
    return (
      <div className="site-section pt-aftermenu">
        <div className="container">
          <div className="col-md-12">
            <h1>Account</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
