import BackendApi from '../backend-api';

export const POST_PROJECT_APARTMENT_SEARCH = 'POST_PROJECT_APARTMENT_SEARCH';
export const PROJECT_GET_APARTMENT_DETAIL = 'PROJECT_GET_APARTMENT_DETAIL';

export const postApartmentSearch = (filters, page, size, sort) => {
  return async (dispatch) => {
    let response = await BackendApi.post("/api/projects/apartments/search", {
        filters: filters
      }, {
      params: {
        page: page,
        size: size,
        sort: (sort !== null && sort !== undefined) ? sort.map((paramName, index) => paramName).join('&') : null
      }
    });
    return dispatch({ type: POST_PROJECT_APARTMENT_SEARCH, data: response.data });
  };
};

export const getApartmentDetail = (id) => {
  return async dispatch => {
    const response = await BackendApi.get("/api/projects/apartments/" + id + "/detail");    
    return dispatch({ type: PROJECT_GET_APARTMENT_DETAIL, data: response.data});
  };
};