import React, { Component } from "react";

class PoliticaDePrivacidadScreen extends Component {
  state = {};
  render() {
    return (
      <div className="site-section">
        <div className="container col-md-12">
          <div className="col-md-12">
            <h1>Política de Privacidad</h1>
          </div>
          <div className="col-md-12">
            <p>
              Desde KiriCoin CONSULTORÍA ESTRATÉGICA Y SOLUCIONES INFORMÁTICAS
              S.L., en adelante KiriCoin, estamos comprometidos con la
              privacidad de nuestros clientes, usuarios, contactos y candidatos,
              en adelante USUARIO, así como el personal que presta servicios con
              nosotros. Parte de nuestro compromiso es el de ser transparentes
              en esta materia, por ello publicamos esta información, con el fin
              de informar debidamente de:
            </p>
            <ul>
              <li>
                Los derechos del USUARIO y del uso de esta web antes de
                facilitar los datos.
              </li>
              <li>
                Las medidas que garantizan la salvaguarda e integridad de los
                datos.
              </li>
              <li>
                Que el consentimiento previo al envío de datos por tu parte
                esté debidamente fundamentado.
              </li>
            </ul>
            <p>
              Cumplimos con las obligaciones legalmente establecidas en la Ley
              Orgánica 15/1999, de 13 de diciembre, de Protección de Datos
              (LOPD), en su Reglamento de Desarrollo 1720/2007, de 21 de
              diciembre, y en el Reglamento Europeo de Protección de Datos (RGPD
              2017/679), así como toda la normativa española y europea aplicable
              en esta materia.
            </p>
            <p>
              KiriCoin conforme a la legislación vigente en materia de
              Protección de Datos de Carácter Personal, informa al USUARIO de su
              página web, acerca de la Política de Privacidad y Protección de
              Datos que aplicará en el tratamiento de los datos personales que
              el USUARIO facilite voluntariamente al acceder al sitio
              www.KiriCoin.es
            </p>
            <p>
              El USUARIO, al proporcionar a KiriCoin sus datos de carácter
              personal a través de los formularios electrónicos de la web,
              consiente expresamente que KiriCoin pueda tratar esos datos en
              los términos de esta cláusula de Política de Privacidad y
              Protección de Datos y para los fines aquí expresados.
            </p>
            <p>
              Antes de proporcionar datos a KiriCoin, el USUARIO debe leer la
              presente Política de Privacidad y de Protección de Datos. Al
              marcar que aceptan la Política de Privacidad y Protección de
              Datos, el USUARIO afirma que ha leído y que consiente expresamente
              la presente Política de Privacidad de Datos.
            </p>
            <p>
              Al rellenar el formulario electrónico de contacto o inscribirse en
              una oferta de empleo, el USUARIO deberá proporcionar algunos datos
              como el nombre y apellidos, correo electrónico y número de
              teléfono.
            </p>
            <p>
              El USUARIO se compromete a introducir datos reales y veraces.
              Asimismo, será el único responsable de los datos y perjuicios que
              KiriCoin o terceros puedan sufrir como consecuencia de la falta
              de veracidad, inexactitud, falta de vigencia y autenticidad de los
              datos facilitados.
            </p>
            <p>
              Los datos recabados por KiriCoin, serán exclusivamente utilizados
              para la consecución del objeto definido en los Términos y
              Condiciones de Uso de la web que pueden ser consultados aquí.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PoliticaDePrivacidadScreen;
