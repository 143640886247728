import * as Actions from '../actions/project';

export const apartmentList = (state = [], action) => {
  if (action.type === Actions.POST_PROJECT_APARTMENT_SEARCH)
      return action.data;
  return state;
};

export const apartmentDetail = (state = null, action) => {
  if (action.type === Actions.PROJECT_GET_APARTMENT_DETAIL)
      return action.data;
  return state;
};

