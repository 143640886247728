import BackendApi from '../backend-api';

export const PRODUCT_GET_PRODUCTS = 'PRODUCT_GET_PRODUCTS';
export const PRODUCT_GET_PRODUCT_DETAIL = 'PRODUCT_GET_PRODUCT_DETAIL';

export const getProducts = () => {
  return async dispatch => {
    const response = await BackendApi.get("/api/products/list");    
    return dispatch({ type: PRODUCT_GET_PRODUCTS, data: response.data});
  };
};

export const getProductDetail = (id) => {
  return async dispatch => {
    const response = await BackendApi.get("/api/products/" + id + "/detail");    
    return dispatch({ type: PRODUCT_GET_PRODUCT_DETAIL, data: response.data});
  };
};
