import { combineReducers } from "redux";
import { reducer } from 'redux-form';

import * as lookup from './lookup';
import * as product from './product';
import * as project from './project';
import * as user from './user';
import * as customer from './customer';
import * as sales from './sales';

export default combineReducers({
    form: reducer,
    
    countries: lookup.lookupCountries,
    cities: lookup.lookupCities,
    companies: lookup.lookupCompanies,

    // Users
    userPaymentPreferences: user.userPaymentPreferences,
    userContracts: user.userContracts,
    userDashboard:  user.userDashboard,
    identity: user.userIdentity,
    userSearch: user.userSearch,
    userDetail: user.userDetail,
    userNotifications: user.userNotifications,

    // Products
    productList: product.productList,
    productDetail: product.productDetail,
    productoInversion: user.productoInversion,

    // Projects
    apartmentList: project.apartmentList,
    apartmentDetail: project.apartmentDetail,

    // Customers
    customerContracts: customer.customerContracts,
    customerPayments: customer.customerPayments,
    customerIncomes: customer.customerIncomes,
    customerDocuments: customer.customerDocuments,
    customerDetails: customer.customerDetails,
    customerContractDetails: customer.customerContractDetails,
    customerAgentAssignment: customer.customerAgentAssignment,
    
    // Sales
    salesPendingAssignments: sales.salesPendingAssignments,
    salesClients: sales.salesClients,
    salesClientDetails: sales.salesClientDetails,
    salesCompanyGroups: sales.salesCompanyGroups,
    salesGroupTeams: sales.salesGroupTeams,
    salesTeamAgents: sales.salesTeamAgents,
});

