import * as ActionType from '../actions/user';

export const productoInversion = (state = {}, action) => {
  switch (action.type) {
    case ActionType.PRODUCTO_INVERSION: 
      return action.data;
    default:
      return state;
  }
};

export const userPaymentPreferences = (state = [], action) => {
  switch (action.type) {
    case ActionType.USER_PAYMENT_PREFERENCES: 
      return action.data;
    default:
      return state;
  }
};

export const userContracts = (state = [], action) => {
  switch (action.type) {
    case ActionType.GET_USER_CONTRACTS: 
      return action.data;
    default:
      return state;
  }
};

export const userSearch = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_SEARCH: 
      return action.data;
    default:
      return state;
  }
};

export const userDetail = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_DETAIL:
      return action.data;
    default:
      return state;
  }
};

export const userDashboard = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_DASHBOARD: 
      return action.data;
    default:
      return state;
  }
};
 
export const userIdentity = (state = {}, action) => {
  switch (action.type) {
    case ActionType.AUTHENTICATE: 
      return action.payload.identity;
    default:
      return state;
  }
};

export const userNotifications = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_NOTIFICATIONS: 
      return action.data;
    default:
      return state;
  }
};