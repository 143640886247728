import React, { Component, Fragment } from "react";

class Question extends Component {
  render() {
    const { title, children } = this.props;

    return <Fragment>
      <div className="d-flex">
        <div className="justify-content-end">
          <div className="question-title"><i className="far fa-question-circle color-primary"></i></div>
        </div>
        <div className="col-md-11 col-11">
          <div className="row">  
            <div className="col-md-12 question-title">
              {title}
            </div>  
          </div>
          <div className="row">  
            <div className="col-md-12 question-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </Fragment>;
  }
}

class QuestionItem extends Component {
  render() {
    const { children } = this.props;

    return <Fragment>
      <div className="question-item">
        <i class="fas fa-circle color-primary question-item-bullet"></i>{children}
      </div>
    </Fragment>;
  }
}

class InfoComercialesScreen extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <div className="site-section">
        <div className="container">
          <div className="h2 text-success">Preguntas frecuentes para comerciales</div>
        </div>
        <div className="container">
          <p>KiriCoin está basado en el concepto de respeto al medioambiente, y en una gestión honrada y humanizada de las finanzas.</p>
          <p>En KiriCoin, queremos que todos los miembros de la familia KiriCoin ganemos dinero mientras hacemos que el planeta sea un mundo más humano y MEJOR.</p>
        </div>
        <div className="container">
          {/*Sobre el funcionamiento de KiriCoin*/}

          <Question title="¿Cómo producimos dinero?">
            En KiriCoin, generamos con muy poco gasto y sin riesgos, una diminuta planta de rápido crecimiento, que se convierte en un árbol de 1 metro cubico de madera, la mitad se destina a biomasa y la otra mitad a carpintería, muebles y construcción, lo que nos permite obtener importantes ganancias.
          </Question>
          
          <Question title="¿Como contribuimos a hacer un mundo mejor?">
            En KiriCoin, cuidamos del medio ambiente, capturando CO2 y generando oxígeno, proveniente de nuestras plantaciones, contrarrestando los daños que genera el efecto invernadero. 
          </Question>
          
          <Question title="¿Puedo ser miembro de KiriCoin? ">
            Si de 3 formas: 
            <QuestionItem>Con un plan de ahorro (link a planes de ahorro)</QuestionItem> 
            <QuestionItem>Aportando una finca agrícola (link a departamento técnico).</QuestionItem>
            <QuestionItem>Trabajando con nosotros (link a trabaja con nosotros)</QuestionItem>
          </Question>
        </div>

        <div className="container pt-2">
          <div className="h3 text-success">Te aclaramos que, al trabajar con nosotros, te convierte de inmediato en AHORRISTA¡¡¡. ¿Por qué?</div>

            <p>Te ofrecemos la posibilidad de hacer el curso de CONSULTOR FINANCIERO DE KiriCoin. Con este curso, te conviertes en propietario de tu “cartera de ahorristas” que además de la venta inicial, te genera unos ingresos complementarios, por la vía de “cartera de clientes”. </p>
            <p>Cualquier venta que hagas, incorporas un miembro de tu “cartera de clientes” y por ello tu percibirás un ingreso complementario (aunque el cliente invierta por otra vía que no seas tu). Nuestra experiencia nos dice que casi la totalidad de los clientes, repiten en otras operaciones, y lo hacen “on line” que, al estar registrado en tu cartera de clientes, percibes tu comisión.</p>
            <p>Si lo deseas cuando estas en proceso de venta puedes registrarlo con anticipación a la operación, ello te garantiza que el cliente es parte de tu cartera de clientes, aunque no hayas formalizado la venta.</p>
            <p>Un CONSULTOR FINANCIERO DE KiriCoin, trabajando a tiempo completo, puede generar unos ingresos que oscilan entre 2.000 a 20.000 euros mensuales, y puede llegar a acumular una cifra de entre 100 a 1.000 euros de jubilación mensual por cada año de trabajo como asesor en KiriCoin, es decir con 20 años de trabajo puedes lograr de entre 2.000 a 20.000 euros mensuales vitalicios. Que además puedes capitalizar a tu voluntad.</p>

        </div>

        <div className="container pt-2">
          <div className="h3 text-success">Conviertete en consultor financiero de KiriCoin</div>
        </div>
        <div className="container pt-2">
          <div className="h3">PASO 1.- Crea tu “cartera de ahorristas”</div>
          <p>El primer paso es registrar en KiriCoin, a tus potenciales clientes, envíanos un listado de clientes al correo a admin@kiri-coin.com, antes de que lo haga otro consultor financiero.</p>
          <p>En el correo aporta los datos de que dispongas, que nos permitan identificar al ahorrista contigo “nombre y apellidos” en el caso de que el cliente tenga nombre y apellidos muy comunes es bueno que aportes además del nombre algún otro dato (fecha de nacimiento, correo, teléfono, número de identificación nacional o pasaporte, un solo dato es suficiente, solo para que nos permita identificarlo como cliente tuyo).</p>
          <p>Ese aporte te garantiza que este potencial cliente es parte de tu cartera, durante un primer plazo de 6 meses, si en ese plazo no lograste materializar ninguna operación con ese cliente, puedes prolongar otros 6 meses, mostrando que estas en contacto con ese cliente.</p>
          <p>No es ningún secreto que te diga que, para hacer crecer tus ingresos, es básico conseguir clientes.</p>
          <p className="pt-2">¿Cómo se hace?</p>
          <p>1.	Empieza con tus contactos en el teléfono, WhatsApp, correo, etc.</p>
          <p>2.	Si haces una buena posventa, tus contactos, te traerán a más contactos. (siempre podrás ir incorporando nuevas listas de potenciales clientes).</p>
          <p>3.	Desarrolla tus relaciones en las redes sociales, no trates de vender KiriCoin en las redes sociales. En su lugar, trata de desarrollar tus relaciones. El primer objetivo, antes de la venta es que te vean como alguien de confianza. La venta es mucho más fácil cuando se vende a personas que sienten que te conocen.</p>
          <p>Consigue más contactos online. Hoy en día, una estrategia de ventas no debe separarse en online y offline. Debes reunir todos los canales que se encuentran en el mercado. Centrando tu atención en estar presente, donde tu cliente está informándose, entreteniéndose o buscando soluciones u opiniones. ¡Esos son los buenos canales!</p>
          <p>Tienes que tener presencia a título personal en las redes sociales. Esto te ayudará a tener más contactos a quienes ofrecer KiriCoin, pero debes centrarte en aquellas donde esté tu público objetivo. No hay que estar en todas las redes y menos de cualquier manera.</p>
          <p>Facebook, Linkedin, Twitter, Instagram, utiliza una mezcla de todas, crea grupos y únete a grupos de tus amigos (Google, Telegram, etc.) donde conocerás a amigos y familiares de tus amigos.</p>
          <p>Al registrar tu “cartera de clientes” paralelamente, estas creando una base de datos con: nombre, apellidos, email y teléfono.</p>      
        </div>
        <div className="container pt-2">
          <div className="h3">PASO 2.- Prepara tu “argumentario de ventas”</div>
          <p>KiriCoin, es un gran producto de ahorro, pero tu potencial cliente aun no lo sabe. Este será tu trabajo, asesorarlo para que conozca las bondades de KiriCoin, y con ello se convierta en miembro de KiriCoin.</p>
          <p>El argumentario de ventas, es una tarea muy fácil, ya que KiriCoin, es un producto muy bien pensado, atractivo y orientado a la acción, ya que es:</p>
          <p>1.	Rentable</p>
          <p>2.	Estable</p>
          <p>3.	Tangible</p>
          <p>4.	Esta asegurado</p>

          <p>También KiriCoin, tiene un gran aporte al medioambiente, contribuye a facilitar tu argumentario de ventas, que debe ser adaptado a cada potencial cliente.</p>
          <p>Debes tener en cuenta las características de cada potencial cliente, y para no depender de tu inspiración o estado de ánimo, es bueno que hagas un profundo estudio de las preguntas y respuestas frecuentes, que encontraras en la página web www.kiri-coin.com</p>
          <p><strong>NO VENDAS “ASESORA Y AYUDA”</strong></p> 
          <p>Antes de empezar, no vendas. Por lo menos vender de la forma tradicional. La mayoría de las personas, se sienten incómodas cuando alguien se acerca a venderles algo. Esto se debe a la cantidad de impactos que recibimos cada día: emails, llamadas, puerta fría.</p>
          <p>Una vez hecha la prospección de clientes y de tener preparados la base de datos y el argumentario de ventas, ha llegado el momento de tener claros varios puntos antes de definir nuestra estrategia de ventas.</p>
          <p>Solo haces falta tú para que seas el vinculo de entrada que de pie a que tu cartera de ahorristas, puedan escucharte y comprobar que KiriCoin es un gran producto.</p>
          <p>Vender ya no funciona.  y lo sabes.</p>
          <p><strong>NO VENDAS “CREA RELACIONES”</strong></p>
          <p>La relación es la base de todo, trasmite confianza. Una de las principales razones por las que, ante igualdad de producto de inversión, un cliente se decidirá por KiriCoin, es la confianza que le generes. Hay que entender que los clientes son personas como tú y yo, quieren que cumplas lo que prometes. Quieren tener una relación profesional contigo.</p>
          <p>Olvídate de vender por vender y céntrate en generar una relación.</p>
          <p><strong>NO VENDAS “AYUDA A TU CLIENTE”</strong></p>
          <p>KiriCoin cubre las necesidades de un cliente. Eso significa que le estás ayudando. Céntrate en eso, en ayudar. En aportar valor. En ser exactamente la solución a un problema.</p>
          <p>Esta es la mejor manera de vender que existe. No vendas, ayuda.</p>
          <p>Cuanto más ayudes, más venderás.</p>
          <p><strong>NO VENDAS “SÉ HONESTO”</strong></p>
          <p>Si no puedes ayudar al cliente, díselo.</p>
          <p>Cada cliente tiene unas necesidades concretas, y algunas de ellas posiblemente se escapan de tus conocimientos, si tú no eres la mejor persona para ayudarle, cuando te encuentres con un cliente así, que necesita un asesoramiento que este fuera de tus posibilidades, siempre debes ser totalmente honesto y dile: “No soy lo que estás buscando, pero déjame investigar a quien te puedo recomendar”.</p>
          <p>Si miras a corto plazo puede parecer que has perdido un cliente, pero si lo haces a largo plazo, has ganado la confianza de un futuro cliente. Cuando necesite algo sobre finanzas, muy probablemente te llamará.</p>
          <p>Que no cierres la venta en este momento, no significa que no la puedas hacer en un futuro.</p>
        </div>

        <div className="container pt-2">
          <div className="h3">PASO 3.- Empieza a Vender. La secuencia es la siguiente:</div>
          <QuestionItem>Envía un email o un WhatsApp personalizado</QuestionItem>
          <QuestionItem>Espera contestación</QuestionItem>
          <QuestionItem>Si no contesta al día siguiente llámale solo para ver si ha recibido tu mensaje.</QuestionItem>
        </div>

        <div className="container pt-4 pb-2">
          <div className="h2 text-success">TÉCNICAS DE VENTAS QUE FUNCIONAN</div>
        </div>
        <div className="container">
          <div className="h3">1.   DESAFÍA AL STATUS QUO</div>
          La mayoría de los vendedores creen que el proceso de venta tiene solo dos finales:
          <QuestionItem>a)	El cliente acaba comprándote el producto o servicio.</QuestionItem>
          <QuestionItem>b)	El cliente acaba comprando a la competencia.</QuestionItem>
          <p>Lo cierto es que hay una tercera opción que solo los buenos vendedores contemplan:</p>
          <p>El cliente no compra de momento a nadie.</p>
          <p>¿Por qué el cliente no compra? Es fácil, sencillamente ninguna opción ha conseguido solucionarle el problema. Si el cliente no siente la confianza suficiente en ninguno de los productos o servicios que le han ofrecido, no comprará. Pero en un porcentaje significativo de clientes, si no te pones pesado y dejas claro los valores de KiriCoin, probablemente te llame después de un tiempo.</p>
          <div className="h3">2.   DEJA CLARO TU VALOR</div>
          <p>¿Cuánto valor aportas tú en comparación a tu competencia?</p>
          <p>La mayoría de los vendedores creen que su producto es mejor que el de la competencia, pero no por mucho. Así que la mejor técnica de venta es centrarse en lo que tu empresa puede hacer por el cliente, que es diferente a lo que la competencia puede hacer. ¡Esa es tu propuesta de valor!</p>
          <p>Tu valor debe ser único, importante y enfocado al cliente.</p>
          <div className="h3">3.   CUENTA UNA HISTORIA DIFERENTE</div>
          <p>A estas alturas, estoy seguro que ya sabes que, si eres como el resto de las empresas, no vas a vender nada. Y si vendes, será barato.</p>
          <p>Para vender hay que crear una historia atractiva, real y diferente. Algo que el cliente note valor y genere confianza e ilusión.</p>
          <p>Un consejo: no tengas miedo de vincular los datos con la emoción. Trata de dar datos objetivos, y reales, pero cuenta la historia, el problema y la solución. Haz que el cliente se sienta partícipe de la historia e incluso reflejado.</p>
        </div>

        <div className="container pt-4 pb-2">
          <div className="h2 text-success">TÉCNICAS DE VENTAS QUE NO FUNCIONAN</div>
        </div>
        <div className="container">
          <div className="h3">1. VENDER VENTAJAS</div>
        
          <p>Todo el mundo sabe que, necesita vender ventajas y no características, ¿verdad? Pues no. </p>
          <p>Si inicias tu conversación con beneficios, estarás haciendo lo que hacen todos:</p>
          <p>Mira qué bueno, guapo e inteligente soy.</p>
          <p>No empieces así, y empieza enterándote de cual es el problema, que puedes solucionarle.</p>
          <p>Deja claro tu valor</p>

          <div className="h3">2. CERRAR VENTAS AGRESIVAMENTE</div>
          <p>Es posible que consigas cerrar la primera venta a través de esta técnica tan anticuada, pero lo cierto es que será un trabajo a corto plazo.</p>
          <p>Se amante del largo plazo, el corto plazo no es interesante.</p>
          <p>Ser agresivo e insistente, solo lograrás romper la relación con el cliente ,y mostrar síntomas de necesidad por vender. </p>
          <p>¡Nadie quiere comprarle a alguien necesitado!</p>
        </div>

        <div className="container pt-4 pb-2">
          <div className="h2 text-success">¿CÓMO HACER SEGUIMIENTO A UN CLIENTE SIN SER UN PESADO?</div>
        </div>
        <div className="container">
          <p>Vamos a empezar por el último punto y para mí, el más importante:</p>
        
          <p>Si el cliente no está interesado en tu producto o servicio, NO LO LLAMES MÁS.</p>
          <p>Hay una cantidad de “gurús” y “expertos”, que dicen que tienes que tener preparadas mil y unas respuestas automáticas, para cuando un cliente no está interesado en lo que vendes. Si alguien no está interesado en lo que estás ofreciendo, no debes seguir insistiendo.</p>
          <p>Puedes y debes aprender para mejorar tu presentación del producto, pero ¿insistir? ¡NO!</p>

          <div className="h3">1.- PREGUNTA AL CLIENTE CUANDO QUIERE QUE LE VUELVAS A CONTACTAR.</div>
          <p>Una de las técnicas que más me funcionan, cuando un cliente no está interesado en comprar mi producto o servicio, es preguntarle cuándo puedo volver a contactarle. Indicarle que estamos interesados en preparar una propuesta más interesante para él o ella, y que no queremos ser pesados, por lo que si nos puede indicar qué fechas son las mejores para contactarle, así lo haremos.</p>
          <div className="h3">2.- PREGUNTARLE DE QUÉ FORMA PREFIERE QUE LE CONTACTEMOS.</div>
          <p>Después de estrellarme un montón de veces, he conseguido aprender, que muchos clientes tienen métodos de contacto preferidos: visitas, llamadas, email.</p>
          <p>Identificar cual es el canal adecuado para contactar con un cliente, es un gran avance en el seguimiento de clientes. Créeme hay clientes que nunca se ponen al teléfono, pero en cambio, siempre contestan un email.</p>
          <div className="h3">3.- ASEGÚRATE DE TERMINAR UNA REUNIÓN CON UNA TAREA PENDIENTE.</div>
          <p>Cuando vayas a terminar una reunión o contacto comercial, intenta que el cliente potencial espere que te vuelvas a poner en contacto con él o ella. Ya sea para enviarle un dossier, para ver qué le parece lo que le has dejado, o para volver a llamarlo para explicarte un siguiente paso.</p>
          <p>El objetivo del seguimiento de clientes es el de crear una relación. Puesto que, ante igualdad de condiciones, siempre elegiremos a la persona con la que tenemos más confianza.</p>

        </div>

        <div className="container pt-4 pb-2">
          <div className="h2 text-success">MEDICIÓN DE RESULTADOS DE VENTAS</div>
        </div>
        <div className="container">
            <p>Parte del éxito o del fracaso de tu asesoría financiera, se basa en tu capacidad para corregir. Es necesario, que dediques el tiempo necesario, para hacer la medición de resultados para detectar los posibles errores. Medir los datos de ventas ayuda a mejorar tu rendimiento.</p>
            <p>Te recomendamos encarecidamente que te crees una buena exposición de ventas.</p>
            <QuestionItem>Mide mensualmente el crecimiento de ventas de tu empresa. <b>IMPORTANTE</b> en unidades de ventas, no en productos ni facturación. Lo que tratamos de lograr con esta técnica, es ver cuantas ventas realizamos.</QuestionItem>
            <QuestionItem>Obviamente debes medir tu facturación mensual.</QuestionItem>
            <QuestionItem>Realiza un seguimiento de tus ventas trimestrales.</QuestionItem>
        </div>

        <div className="container pt-4 pb-2">
          <div className="h2 text-success">TRABAJAR DURO</div>
        </div>
        <div className="container">
            <p>Trabajar duro, no existe otro secreto. Trabajando duro es como consiguen resultados importantes.</p>
            <p>Nada es un regalo, todo debe ser construido con grandes dosis de trabajo y esfuerzo.</p>
            <p>Si no están vendiendo lo que necesitas vender, o si no estás obteniendo los resultados que quieres, se debe a dos causas:</p>
            <QuestionItem>Lo estás haciendo mal.</QuestionItem>
            <QuestionItem>No estás trabajando lo suficientemente, ni duro, ni rápido.</QuestionItem>
            <p>Estás ocupando cada parte de tu día con trabajo y cuando duermes, duermes. Pero cuando estás despierto, debes estar ALL IN.</p>
            <p>Trabajar duro es lo único que produce resultados, pero, sobre todo, es de las cosas que puedes controlar. Quizás no puedas controlar, si un cliente te va a contratar o no, pero si puedes decidir llamar a 100 en lugar de a 50. ¡Eso es trabajar duro!</p>
        </div>

      </div>
    );
  }
}

export default InfoComercialesScreen;
