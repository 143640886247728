export const formatMoney = (value, decimals = 0, symbol = "€") => {
  var nf = new Intl.NumberFormat();
  return nf.format(value) + " " + symbol;
}

/**
 * @param rate is the interest rate per period. For example, use 6%/4 for quarterly payments at 6% APR.
 * @param nper is the total number of payment periods in the investment.
 * @param pmt is the payment made each period; it cannot change over the life of the investment.
 * @param pv is the present value, or the lump sum amount that a series of future payments is worth now. If omitted, Pv = 0.
 * @param type is a value representing the timing of payment: payment at the beginning of the period = 1; payment at the end of the period = 0 or omitted.
 */
export const futureValue = (rate, nper, pmt, pv, type) => {
  let pow = Math.pow(1 + rate, nper);
  let fv;

  if (rate) {
    fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv.toFixed(2);
}

export const futurePeriodPayment = (fv, rate, nper, pv, type) => {
  let pow = Math.pow(1 + rate, nper);
  let pmt;

  if (rate) {
    pmt = ((fv + pv*pow)*rate)/((1.0+rate*type)*(1.0-pow));
  } else {
    pmt = (-1*fv -pv)/nper;
  }
  return pmt.toFixed(2);
}


